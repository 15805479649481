import React, { Component, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Nav, Navbar, NavLink } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ReactSession } from "react-client-session";
import { Link } from "react-router-dom";
import { ThingsContext } from "../components/thingsContext";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faTimes } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/images/hella-lit-logo.png";
import $ from "jquery";
import io from "socket.io-client";
import TimeAgo from './timeago'; // Adjust the import path as needed

const NavigationBar = () => {
  const [userLoc, setUserLoc] = useState(ReactSession.get("userlocation"));
  const [locationvisibleflag, setLocationvisibleflag] = useState(false);
  const [profilevisibleflag, setProfilevisibleflag] = useState(false);
  const [notifyvisibleflag, setNotifyvisibleflag] = useState(false);
  const [cartlist, setCartList] = useState("")
  const [showClose, setShowClose] = useState(false);
  const [user_id, setUser_id] = useState(
    window.localStorage.getItem("user_id")
      ? window.localStorage.getItem("user_id")
      : ""
  );
  const [randomString, setRandomString] = useState(
    window.localStorage.getItem("randomString")
      ? window.localStorage.getItem("randomString")
      : ""
  );
  const [socket, setSocket] = useState(null);
  const [receivedData, setReceivedData] = useState(null);
  const [notification, setNotification] = useState([]);
  let dispensariesIsActive = "nav-link";
  let deliveriesIsActive = "nav-link";
  let brandsIsActive = "nav-link";
  let mapIsActive = "nav-link";
  let dealsIsActive = "nav-link";
  let blogIsActive = "nav-link";
  let productsIsActive = "nav-link";
  function testFunc() {
    setShowClose((prev) => !prev);
  }
  function locationvisible() {
    console.log("test", locationvisibleflag)
    if (locationvisibleflag === false) {
      setLocationvisibleflag(true);
    } else {
      setLocationvisibleflag(false);
    }

  }
  function profilevisible() {
    console.log("test", locationvisibleflag)
    if (profilevisibleflag === false) {
      setProfilevisibleflag(true);
    } else {
      setProfilevisibleflag(false);
    }

  }
  function notifyvisible() {
    console.log("test", locationvisibleflag)
    if (notifyvisibleflag === false) {
      setNotifyvisibleflag(true);
    } else {
      setNotifyvisibleflag(false);
    }

  }
  if (window.location.pathname.includes("dispensaries"))
    dispensariesIsActive += " active";
  else if (window.location.pathname.includes("deliveries"))
    deliveriesIsActive += " active";
  else if (window.location.pathname.includes("brands"))
    brandsIsActive += " active";
  else if (window.location.pathname.includes("deals"))
    dealsIsActive += " active";
  else if (window.location.pathname.includes("blog"))
    blogIsActive += " active";
  else if (window.location.pathname.includes("products"))
    productsIsActive += " active";
  else if (window.location.pathname.includes("map")) mapIsActive += " active";
  // user loaction


  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const api = "AIzaSyCSYGvIgsNeJhQxgWta0NzNIsUUrw_9LTs";
  const [autoc, setAutoC] = React.useState();
  function loadAuto(autocomplete) {
    // console.log('autocomplete: ', autocomplete)
    setAutoC(autocomplete);
  }

  function compIsType(t, s) {
    for (let z = 0; z < t.length; ++z) if (t[z] === s) return true;

    return false;
  }

  function onPlaceChangedAuto() {
    let showKey = "";
    let removeTextFlag = "";

    if (autoc !== null) {
      console.log(autoc);

      const place = autoc.getPlace();
      //console.log(" from google auto complete place===>",place);
      let lat,
        lng,
        addrSel,
        placeName,
        placeId = "";
      let country,
        state,
        city = null;
      if (place.geometry !== undefined) {
        const plcGeom = place.geometry;
        if (plcGeom.location !== undefined) {
          lat = plcGeom.location ? plcGeom.location.lat() : "";
          lng = plcGeom.location ? plcGeom.location.lng() : "";
        }
      }

      addrSel =
        place.formatted_address !== undefined ? place.formatted_address : "";
      placeName = place.name !== undefined ? place.name : "";
      placeId = place.place_id !== undefined ? place.place_id : "";

      if (place.address_components !== undefined) {
        let addrComp = place.address_components;
        for (let i = 0; i < addrComp.length; ++i) {
          var typ = addrComp[i].types;
          if (compIsType(typ, "administrative_area_level_1"))
            state = addrComp[i].long_name; //store the state
          else if (compIsType(typ, "locality"))
            city = addrComp[i].long_name; //store the city
          else if (compIsType(typ, "country")) country = addrComp[i].long_name; //store the country

          //we can break early if we find all three data
          if (state != null && city != null && country != null) break;
        }
      }

      let nameData = "";
      if (showKey !== "") {
        if (showKey === "CITY") {
          nameData = city;
        } else if (showKey === "STATE") {
          nameData = state;
        } else if (showKey === "COUNTRY") {
          nameData = country;
        } else if (showKey === "PLACE_NAME") {
          nameData = country;
        } else if (showKey === "FORMATTED_ADDRESS") {
          nameData = addrSel;
        } else if (showKey === "PLACE_ID") {
          nameData = placeId;
        } else {
          nameData = addrSel;
        }
      } else {
        nameData = addrSel;
      }

      if (removeTextFlag !== "" && removeTextFlag === "YES") {
        nameData = "";
      }

      let stateResp = {
        lat: lat,
        lng: lng,
        formattedAddress: addrSel,
        placeName: placeName,
        placeId: placeId,
        city: city,
        state: state,
        country: country,
        textboxtext: nameData,
      };
      ReactSession.set("userCurrentLocationFlag", false);
      ReactSession.set("searchLocationBetween", false);
      ReactSession.set("userlocation", autoc.getPlace().formatted_address);
      ReactSession.set(
        "userlocationLat",
        autoc.getPlace().geometry.location.lat()
      );
      ReactSession.set(
        "userlocationLng",
        autoc.getPlace().geometry.location.lng()
      );
      ReactSession.set("userlocationVP", autoc.getPlace().geometry.viewport);
      ReactSession.set("userlocationDetails", stateResp);
      console.log(autoc.getPlace());
      window.location.reload(false);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  }
  function getProductscartList() {
    var url;
    if (user_id) {
      url = process.env.REACT_APP_API_URL + "products/cart/list?user_id=" + window.localStorage.getItem("user_id");
    } else {
      url = process.env.REACT_APP_API_URL + "products/cart/list?random_string=" + window.localStorage.getItem("randomString");
    } axios
      .get(url)
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess", res.data.result.totresult[0].total_cart);
          console.log("suceess", res.data.result.totresult[0]);
          setCartList(res.data.result.totresult[0].total_cart);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function logout() {
    localStorage.removeItem('user_id');
    localStorage.removeItem('email_address');
    localStorage.removeItem('randomString');
    console.log('loggedout');
    window.location.reload(true);
    // this.props.history.push('/signin')
  }

  useEffect(() => {
    getProductscartList();
    setRandomString(window.localStorage.getItem("randomString")
      ? window.localStorage.getItem("randomString")
      : "")
  }, []);

  //socket
  useEffect(() => {
    const socketInstance = io("https://development-api.hellalit.org:2022", {
      transports: ["websocket"],
    }); // Replace with your server's address
    setSocket(socketInstance);
    console.log("socket", socket);
    socketInstance.on("addtocart", handleSocketMessagecart);

    return () => {
      // Clean up the socket connection when the component unmounts
      socketInstance.off("addtocart", handleSocketMessagecart);
      socketInstance.close();
    };
  }, []);
  const handleSocketMessagecart = (data) => {
    // Handle the emitted data from the server
    console.log("Received data from server:1", data);
    setReceivedData(data);

    getProductscartList();
    // You can update your component's state or perform any other action here
  };
  function getNotification() {
    // if (user_id.length === 5) {
    //   navigate("/login");
    // } else {
    var url;
    if (user_id) {
      url =
        process.env.REACT_APP_API_URL +
        "notification/list?user_id=" +
        window.localStorage.getItem("user_id");
    }
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess notify", res.data.result);
          setNotification(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  }

  useEffect(() => {
    getNotification();
  }, []);
  return (
    // <Navbar collapseOnSelect expand="lg" className="navbar-light">
    //   <div className="container-fluid">
    //     <a className="navbar-brand" href="/">
    //       <img className="img-fluid" src={logo} />
    //     </a>

    //     <Navbar.Toggle
    //       area-controls="navbarScroll"
    //       className="navbar-toggler btn border-0"
    //       data-bs-target="#navbarScroll"
    //       onClick={testFunc}
    //     >
    //       {/* <span className="navbar-toggler-icon"></span> */}
    //       {showClose == false ? (
    //         <span className="navbar-toggler-icon"></span>
    //       ) : (
    //         <span className="navbar-close-icon">
    //           <FontAwesomeIcon icon={faTimes} size="2xl"></FontAwesomeIcon>
    //         </span>
    //       )}
    //     </Navbar.Toggle>

    //     <Navbar.Collapse id="navbarScroll" className="collapse navbar-collapse">
    //       <Nav className="navbar-nav me-auto mb-2 mb-lg-0 main-nav">
    //         <li className="nav-item">
    //           <a className={dispensariesIsActive} href="/dispensaries">
    //             Dispensaries
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <a className={deliveriesIsActive} href="/deliveries">
    //             Deliveries
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <a className={brandsIsActive} href="/brands">
    //             Brands
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <a href="/deals" className={dealsIsActive}>
    //             Deals
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <a href="/blog" className={blogIsActive}>
    //             Blog
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <a href="/products" className={productsIsActive}>
    //             Products
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <a className={mapIsActive} href="/map">
    //             Map
    //           </a>
    //         </li>
    //       </Nav>
    //     </Navbar.Collapse>

    //     <GeoLocation userLoc={userLoc} />
    //   </div>
    // </Navbar>
    <nav className="navbar navbar-expand-xl fixed-top">
      <div className="container-fluid">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <a className="navbar-brand" href="/home">
          <img src={logo} border={0} className="desktop_logo" />
          {/* <img src="assets/img/hellalitlogo_sm.png" border="0" class="mobile_logo"/> */}
        </a>
        <div className="collapse navbar-collapse" id="navbarToggler">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className={dispensariesIsActive} href="/dispensaries">
                Dispensaries
              </a>
            </li>
            <li className="nav-item">
              <a className={deliveriesIsActive} href="/deliveries">
                Deliveries
              </a>
            </li>
            <li className="nav-item">
              <a className={brandsIsActive} href="/brands">
                Brands
              </a>
            </li>
            <li className="nav-item">
              <a href="/deals" className={dealsIsActive}>
                Deals
              </a>
            </li>
            <li className="nav-item">
              <a href="/blog" className={blogIsActive}>
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a href="/products" className={productsIsActive}>
                Products
              </a>
            </li>
            <li className="nav-item">
              <a className={mapIsActive} href="/map">
                Map
              </a>
            </li>
          </ul>

        </div>
        <div className="header_icons header_location_div">
          <span className="header_all_icon crsr_pntr"><i className="fa-solid fa-location-dot header_location_icon" onClick={locationvisible} title="Location" /></span>
          <div className="header_locdiv" style={{ display: locationvisibleflag ? 'block' : 'none' }}>
            <ThingsContext.Provider value={userLoc}>
              <div className="header_location_search">
                <form className="search_cus d-flex  header_location" onSubmit={handleSubmit}>
                  <div className="d-flex header-location" onSubmit={handleSubmit}>
                    <LoadScript googleMapsApiKey={api} libraries={["places"]}>
                      <Autocomplete onLoad={loadAuto} onPlaceChanged={onPlaceChangedAuto}>
                        <input
                          id="userLocation"
                          className="form-control locationSearchBox shadow-none input-group"
                          type="text"
                          placeholder="Search location"
                          defaultValue={userLoc}
                        />
                      </Autocomplete>
                    </LoadScript>
                    {/* <div className="header_icons header_location_div"><span className="header_all_icon crsr_pntr"> */}
                    <button
                      title="Get My Current Location"
                      onClick={getCurrentLocation}
                      className="btn border-0"
                      type="button"
                    >
                      {/* <FontAwesomeIcon icon={faLocationDot}></FontAwesomeIcon> */}

                    </button>
                    {/* <i className="fa-solid fa-location-dot header_location_icon"></i></span>   */}
                    {/* </div> */}
                  </div>
                </form>
              </div>
            </ThingsContext.Provider>
            {/* <GeoLocation userLoc={userLoc} /> */}
            {/* <div className="header_location_search">
              <form className="d-flex header_location" role="search">
                <div className="input-group">
                  <input type="text" className="form-control" defaultValue="Oakland, CA, USA" aria-label="Username" aria-describedby="basic-addon1" />
                  
                </div>
              </form>
            </div> */}
          </div>
        </div>
        <div className="header_icons header_myprofile_div">
          <span className="header_all_icon crsr_pntr"><i className="fa-solid fa-circle-user header_myprofile_icon" title="Profile" onClick={profilevisible} /></span>
          <div className="header_myprofdiv" style={{ display: profilevisibleflag ? 'block' : 'none' }}>
            <div className="headerprofile_div">
              <ul>
                {!user_id ? <li>
                  <a href="/login">Login</a>
                </li> : ""}
                {!user_id ? <li>
                  <a href="/signup">Signup</a>
                </li> : ""}
                {user_id ? <li>
                  <a href="/profile">My Profile</a>
                </li> : ""}
                <li>
                  <a href="/coupon">Coupon(s)</a>
                </li>
                <li>
                  <a href="/checkout">Checkout</a>
                </li>
                <li>
                  <a href="/orderHistory">Your Order(s)</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
                {user_id ? <li>
                  <a onClick={() => {
                    logout()
                  }}>Logout</a>
                </li> : ""}
              </ul>
            </div>
          </div>
        </div>

        <div className="header_icons header_wishlist_div">
          <span className="header_all_icon crsr_pntr"><a href="/wishlist"><i className="fa-solid fa-heart" title="Wishlist" /></a></span>
        </div>

        <div class="header_icons header_shopping_div">
          <span class="header_all_icon active crsr_pntr"><a href="/cartlist"><i class="fa-solid fa-cart-shopping" title="Cart"></i></a></span>
          <span class="tp-header-action-badge">{cartlist}</span>
        </div>
        <div class="header_icons header_notify_div">
          <span class="header_all_icon crsr_pntr"><i class="fa-solid fa-bell header_notify_icon" title="Notification" onClick={notifyvisible}></i></span>
          <div class="header_notifydiv" style={{ display: notifyvisibleflag ? 'block' : 'none' }}>
            <div class="headernotify_div">
              <ul>
                {notification.map((row) => {
                  return (
                    <li>
                      <a href="#">
                        <div class="notify_list_item">
                          <div class="notify_img">
                            <img src="assets/img/product_logo.png" />
                          </div>
                          <div class="notify_txt">
                            <div class="notify_title">{row.type}</div>
                            <div>{row.type === "payment" ? "Payment received. Thank you!" : ""}{row.type === "wishlist" ? "New wishlist added." : ""}{row.type === "products_create" ? "New Products arrived." : ""}</div>
                            <div class="text-muted notify_time"><i class="far fa-clock me-1"></i> <TimeAgo targetDate={row.updated_date} /></div>
                          </div>
                        </div>
                      </a>
                    </li>

                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="header_icons header_notify_div">
          <span className="header_all_icon crsr_pntr"><i className="fa-solid fa-bell header_myprofile_icon" title="Profile" onClick={notifyvisible} /></span>
          <div className="header_myprofdiv" style={{ display: notifyvisibleflag ? 'block' : 'none' }}>
            <div className="headerprofile_div" style={{ width: '310px' }}>
              <ul >
                {notification.map((row) => {
                  return (
                    <li style={{ listStyleType: 'disc' }}>
                      {row.message}
                    </li>
                  )
                })}

              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </nav>

  );
};
function GeoLocation(data) {
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const api = "AIzaSyCSYGvIgsNeJhQxgWta0NzNIsUUrw_9LTs";
  const [autoc, setAutoC] = React.useState();
  function loadAuto(autocomplete) {
    // console.log('autocomplete: ', autocomplete)
    setAutoC(autocomplete);
  }

  function compIsType(t, s) {
    for (let z = 0; z < t.length; ++z) if (t[z] === s) return true;

    return false;
  }

  function onPlaceChangedAuto() {
    let showKey = "";
    let removeTextFlag = "";

    if (autoc !== null) {
      console.log(autoc);

      const place = autoc.getPlace();
      //console.log(" from google auto complete place===>",place);
      let lat,
        lng,
        addrSel,
        placeName,
        placeId = "";
      let country,
        state,
        city = null;
      if (place.geometry !== undefined) {
        const plcGeom = place.geometry;
        if (plcGeom.location !== undefined) {
          lat = plcGeom.location ? plcGeom.location.lat() : "";
          lng = plcGeom.location ? plcGeom.location.lng() : "";
        }
      }

      addrSel =
        place.formatted_address !== undefined ? place.formatted_address : "";
      placeName = place.name !== undefined ? place.name : "";
      placeId = place.place_id !== undefined ? place.place_id : "";

      if (place.address_components !== undefined) {
        let addrComp = place.address_components;
        for (let i = 0; i < addrComp.length; ++i) {
          var typ = addrComp[i].types;
          if (compIsType(typ, "administrative_area_level_1"))
            state = addrComp[i].long_name; //store the state
          else if (compIsType(typ, "locality"))
            city = addrComp[i].long_name; //store the city
          else if (compIsType(typ, "country")) country = addrComp[i].long_name; //store the country

          //we can break early if we find all three data
          if (state != null && city != null && country != null) break;
        }
      }

      let nameData = "";
      if (showKey !== "") {
        if (showKey === "CITY") {
          nameData = city;
        } else if (showKey === "STATE") {
          nameData = state;
        } else if (showKey === "COUNTRY") {
          nameData = country;
        } else if (showKey === "PLACE_NAME") {
          nameData = country;
        } else if (showKey === "FORMATTED_ADDRESS") {
          nameData = addrSel;
        } else if (showKey === "PLACE_ID") {
          nameData = placeId;
        } else {
          nameData = addrSel;
        }
      } else {
        nameData = addrSel;
      }

      if (removeTextFlag !== "" && removeTextFlag === "YES") {
        nameData = "";
      }

      let stateResp = {
        lat: lat,
        lng: lng,
        formattedAddress: addrSel,
        placeName: placeName,
        placeId: placeId,
        city: city,
        state: state,
        country: country,
        textboxtext: nameData,
      };
      ReactSession.set("userCurrentLocationFlag", false);
      ReactSession.set("searchLocationBetween", false);
      ReactSession.set("userlocation", autoc.getPlace().formatted_address);
      ReactSession.set(
        "userlocationLat",
        autoc.getPlace().geometry.location.lat()
      );
      ReactSession.set(
        "userlocationLng",
        autoc.getPlace().geometry.location.lng()
      );
      ReactSession.set("userlocationVP", autoc.getPlace().geometry.viewport);
      ReactSession.set("userlocationDetails", stateResp);
      console.log(autoc.getPlace());
      window.location.reload(false);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  }

  return (
    <ThingsContext.Provider value={data.userLoc}>
      <form className="search_cus" onSubmit={handleSubmit}>
        <div className="d-flex header-location" onSubmit={handleSubmit}>
          <LoadScript googleMapsApiKey={api} libraries={["places"]}>
            <Autocomplete onLoad={loadAuto} onPlaceChanged={onPlaceChangedAuto}>
              <input
                id="userLocation"
                className="form-control locationSearchBox shadow-none"
                type="text"
                placeholder="Search location"
                defaultValue={data.userLoc}
              />
            </Autocomplete>
          </LoadScript>
          {/* <div className="header_icons header_location_div"><span className="header_all_icon crsr_pntr"> */}
          <button
            title="Get My Current Location"
            onClick={getCurrentLocation}
            className="btn border-0"
            type="button"
          >
            {/* <FontAwesomeIcon icon={faLocationDot}></FontAwesomeIcon> */}

          </button>
          {/* <i className="fa-solid fa-location-dot header_location_icon"></i></span>   */}
          {/* </div> */}
        </div>
      </form>
    </ThingsContext.Provider>
  );
}
function getCurrentLocation() {
  navigator.geolocation.getCurrentPosition(function (position) {
    console.log("Latitude is :", position.coords.latitude);
    console.log("Longitude is :", position.coords.longitude);
    fetch(
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      position.coords.longitude +
      "," +
      position.coords.latitude +
      ".json?access_token=pk.eyJ1IjoiaGVsbGEtbGl0IiwiYSI6ImNsYTNod25vbTBybTYzeG8zZzk2NGVuZW0ifQ.5W4GWKSi-Vv9ZU9YXgOUPg"
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        ReactSession.set("userlocation", data.features[3].text);
        ReactSession.set("userCurrentLocationFlag", true);
        ReactSession.set("searchLocationBetween", false);
        ReactSession.set("userlocationLat", data.query[1]);
        ReactSession.set("userlocationLng", data.query[0]);
        ReactSession.set("userlocationDetails", parseReverseGeo(data));
        window.location.reload(false);
      });
  });
}

function parseReverseGeo(geoData) {
  let city = "",
    state = "",
    country = "";
  geoData.features.map((context, i) => {
    if (context.place_type[0] === "place") city = context.text;
    if (context.place_type[0] === "region") state = context.text;
    if (context.place_type[0] === "country") country = context.text;
    return [];
  });

  return JSON.stringify({ city: city, state: state, country: country });
}

export default NavigationBar;

import React from "react";
import { Button } from "bootstrap";
import { useNavigate } from "react-router-dom";
import axios, { isCancel, AxiosError } from "axios";
// import {
//   DispensaryCarsoulList,
//   DeliveryCarsoulList,
//   BrandsCarsoulList,
//   DispensaryAPI,
//   DeliveryAPI,
//   BrandAPI,
// } from "./commonComp";
import {
  BrandsCarsoulList,
  DispensaryAPI,
  DeliveryAPI,
  BrandAPI,
  // BlogAPI,
} from "./commonComp";

import { SpotLightDeals } from "./deals";
import { SpotLightBlogs } from "./blog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactSession } from "react-client-session";

import "../assets/Apron-font/stylesheet.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./additionalCSS.css";
const DispensaryCarsoulList = React.lazy(() =>
  import("./commonComp").then((module) => ({
    default: module.DispensaryCarsoulList,
  }))
);
const DeliveryCarsoulList = React.lazy(() =>
  import("./commonComp").then((module) => ({
    default: module.DeliveryCarsoulList,
  }))
);
const DealsCarsoulList = React.lazy(() =>
  import("./commonComp").then((module) => ({
    default: module.DeliveryCarsoulList,
  }))
);
function isImageExtension(extension) {
  var imageExtensions = [".png", ".jpg", ".jpeg", ".jfif", ".jpe"];
  return imageExtensions.includes(extension.toLowerCase());
}

// const imagechanges = (data) => {
//   // var split_url = data.split("?");
//   // var object_key = split_url[1].split("=")[1];

//   // return "https://d3o4at03oqlv0t.cloudfront.net/" + object_key;
//   var split_url = data.split("?");
//   var object_key = split_url[1].split("=")[1];
//   var converted_url =
//     "https://d3o4at03oqlv0t.cloudfront.net/" +
//     object_key.replace(".jpg", ".webp");
//   return converted_url;
// };

const imagechanges = (data) => {
  var split_url = data.split("?");
  var object_key = split_url[1].split("=")[1];

  var fileExtension = object_key.substring(object_key.lastIndexOf(".")); // Extract the file extension

  var isImage = isImageExtension(fileExtension);

  if (isImage) {
    var converted_url =
      "https://d3o4at03oqlv0t.cloudfront.net/" +
      object_key.replace(fileExtension, ".webp");
    return converted_url;
  } else {
    // Handle the case when the file extension is not supported
    return "Invalid file extension";
  }
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Home() {
  let dispensariesList;
  let deliveriesList;
  let brandsList;
  let blogList;
  // if(window.location.href.includes('localhost')){
  //     dispensariesList = require('./dispensariesList.json').result;
  //     deliveriesList = require('./deliveriesList.json').result;
  //     brandsList = require('./brandsList.json').result;
  // }else{
  dispensariesList = []; // require('./dispensariesList.json').result;
  deliveriesList = []; // require('./deliveriesList.json').result;
  brandsList = []; // require('./brandsList.json').result;
  blogList = []; // require('./brandsList.json').result;
  // }
  const navigate = useNavigate();
  const [dynamicDispensaries, setDynamicDispensaries] = React.useState(
    <Dispensaries
      sts={process.env.REACT_APP_STATUS_LDN}
      lists={dispensariesList}
    />
  );
  const [dynamicDeliveries, setDynamicDeliveries] = React.useState(
    <Deliveries sts={process.env.REACT_APP_STATUS_LDN} lists={deliveriesList} />
  );
  const [dynamicBrands, setDynamicBrands] = React.useState(
    <Brands sts={process.env.REACT_APP_STATUS_LDN} lists={brandsList} />
  );

  const [dynamicDeals, setDynamicDeals] = React.useState(
    <Deals sts={process.env.REACT_APP_STATUS_LDN} lists={dispensariesList} />
  );
  // const [dynamicBlogs, setDynamicBlogs] = React.useState(
  //   <Blogs sts={process.env.REACT_APP_STATUS_LDN} lists={dispensariesList} />
  // );
  React.useEffect(() => {
    const currentURL = window.location.href;

    const urlObject = new URL(currentURL);

    // Extract the base URL without query parameters
    const baseURL = urlObject.origin + urlObject.pathname;

    // Define the target URL
    const targetURL = "orderHistory";

    // Check if the base URL matches the redirect URL
    if (
      baseURL === "https://staging.hellalit.org/" &&
      urlObject.search.includes("type=products&status=success")
    ) {
      navigate(targetURL);
    }
  }, []);
  React.useEffect(() => {
    if (dispensariesList.length == 0) {
      axios
        .get(
          DispensaryAPI(
            ReactSession.get("userCurrentLocationFlag"),
            ReactSession.get("userlocationLat"),
            ReactSession.get("userlocationLng"),
            ReactSession.get("userlocationDetails").city,
            ReactSession.get("userlocationDetails").state,
            ReactSession.get("userlocationDetails").country
          )
        )
        .then((response) => {
          if (response.data.status == "success") {
            setDynamicDispensaries(
              <Dispensaries
                sts={process.env.REACT_APP_STATUS_SCS}
                lists={response.data.result}
              />
            );

            setDynamicDeals(
              //for deals
              <Deals
                sts={process.env.REACT_APP_STATUS_SCS}
                lists={response.data.result}
              />
            );
          } else {
            setDynamicDispensaries(
              <Dispensaries sts={process.env.REACT_APP_STATUS_ND} lists={[]} />
            );
          }
        })
        .catch((error) => {
          setDynamicDispensaries(
            <Dispensaries sts={process.env.REACT_APP_STATUS_FLR} lists={[]} />
          );
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }

    if (deliveriesList.length == 0) {
      axios
        .get(
          DeliveryAPI(
            ReactSession.get("userCurrentLocationFlag"),
            ReactSession.get("userlocationLat"),
            ReactSession.get("userlocationLng"),
            ReactSession.get("userlocationDetails").city,
            ReactSession.get("userlocationDetails").state,
            ReactSession.get("userlocationDetails").country
          )
        )
        .then((response) => {
          if (response.data.status == "success") {
            setDynamicDeliveries(
              <Deliveries
                sts={process.env.REACT_APP_STATUS_SCS}
                lists={response.data.result}
              />
            );
          } else {
            setDynamicDeliveries(
              <Deliveries sts={process.env.REACT_APP_STATUS_ND} lists={[]} />
            );
          }
        })
        .catch((error) => {
          setDynamicDeliveries(
            <Deliveries sts={process.env.REACT_APP_STATUS_FLR} lists={[]} />
          );
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }

    if (brandsList.length == 0) {
      axios
        .get(
          BrandAPI(
            ReactSession.get("userCurrentLocationFlag"),
            ReactSession.get("userlocationLat"),
            ReactSession.get("userlocationLng"),
            ReactSession.get("userlocationDetails").city,
            ReactSession.get("userlocationDetails").state,
            ReactSession.get("userlocationDetails").country
          )
        )
        .then((response) => {
          if (response.data.status == "success") {
            setDynamicBrands(
              <Brands
                sts={process.env.REACT_APP_STATUS_SCS}
                lists={response.data.result}
              />
            );
          } else {
            setDynamicBrands(
              <Brands sts={process.env.REACT_APP_STATUS_ND} lists={[]} />
            );
          }
        })
        .catch((error) => {
          setDynamicBrands(
            <Brands sts={process.env.REACT_APP_STATUS_FLR} lists={[]} />
          );
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
    // if (blogList.length == 0) {
    //   axios
    //     .get(
    //       BlogAPI(
    //         ReactSession.get("userCurrentLocationFlag"),
    //         ReactSession.get("userlocationLat"),
    //         ReactSession.get("userlocationLng"),
    //         ReactSession.get("userlocationDetails").city,
    //         ReactSession.get("userlocationDetails").state,
    //         ReactSession.get("userlocationDetails").country
    //       )
    //     )
    //     .then((response) => {
    //       if (response.data.status == "success") {
    //         setDynamicBrands(
    //           <Brands
    //             sts={process.env.REACT_APP_STATUS_SCS}
    //             lists={response.data.result}
    //           />
    //         );
    //       } else {
    //         setDynamicBrands(
    //           <Brands sts={process.env.REACT_APP_STATUS_ND} lists={[]} />
    //         );
    //       }
    //     })
    //     .catch((error) => {
    //       setDynamicBrands(
    //         <Brands sts={process.env.REACT_APP_STATUS_FLR} lists={[]} />
    //       );
    //       // Error
    //       if (error.response) {
    //         // The request was made and the server responded with a status code
    //         // that falls out of the range of 2xx
    //         // console.log(error.response.data);
    //         // console.log(error.response.status);
    //         // console.log(error.response.headers);
    //       } else if (error.request) {
    //         // The request was made but no response was received
    //         // `error.request` is an instance of XMLHttpRequest in the
    //         // browser and an instance of
    //         // http.ClientRequest in node.js
    //         console.log(error.request);
    //       } else {
    //         // Something happened in setting up the request that triggered an Error
    //         console.log("Error", error.message);
    //       }
    //       console.log(error.config);
    //     });
    // }
  }, []);
  return (
    <div class="abc banner_mrg_top">
      <Galary />
      {dynamicDispensaries}
      {dynamicDeliveries}
      {dynamicBrands}
      {dynamicDeals}
      {/* {dynamicBlogs} */}
      <HomeFooterDiv />
    </div>
  );
}
class Galary extends React.Component {
  render() {
    return (
      // <Carousel responsive={responsive} infinite={true}>
      //   {/* <Carousel.Item id="galary_cars_1"> */}

      //   <img width="100%" src={require("../assets/images_/hellalit_brands_product.jpg")} />
      //   <img width="100%" src={require("../assets/images_/hellalit_green_banner_img.png")} />

      //   {/* <img width="100%" src={require("../assets/images_/p1.png")} /> */}
      //   {/* </Carousel.Item> */}
      //   {/* <Carousel.Item id="galary_cars_2"> */}
      //   {/* <img width="100%" src={require("../assets/images_/p2.png")} /> */}
      //   {/* </Carousel.Item> */}
      //   {/* <Carousel.Item id="galary_cars_3"> */}
      //   <img width="100%" src={require("../assets/images_/p3.png")} />
      //   {/* </Carousel.Item>       */}
      // </Carousel>
      <img
        width="100%"
        className="img-fluid"
        src={require("../assets/images_/p5.png")}
      />
    );
  }
}

function Brands({ sts, lists }) {
  const navigate = useNavigate();
  return (
    <div class="container p-0">
      <div class="">
        <div class="header d-flex justify-content-between">
          <h4 className="mb-0 txt_head ">Brands</h4>
          <h5
            class="text-right txt_head"
            role="button"
            onClick={() => navigate("/brands")}
          >
            <span className="txt-view-all">View All</span>
            <svg
              width="12"
              height="12"
              fill="none"
              viewBox="0 0 12 12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.43934 5.25L5.46967 1.28033C5.17678 0.987437 5.17678 0.512564 5.46967 0.21967C5.76256 -0.0732231 6.23744 -0.0732231 6.53033 0.21967L11.7803 5.46967C12.0732 5.76256 12.0732 6.23744 11.7803 6.53033L6.53033 11.7803C6.23744 12.0732 5.76256 12.0732 5.46967 11.7803C5.17678 11.4874 5.17678 11.0126 5.46967 10.7197L9.43934 6.75L0.75 6.75C0.335787 6.75 0 6.41421 0 6C0 5.58579 0.335787 5.25 0.75 5.25L9.43934 5.25Z"
                fill="#252935"
              ></path>
            </svg>{" "}
          </h5>
        </div>
        <BrandsCarsoulList sts={sts} list={lists} />
      </div>
    </div>
  );
}

function Deliveries({ sts, lists }) {
  const navigate = useNavigate();
  return (
    <div class="container p-0">
      <div class="header d-flex justify-content-between">
        <h4 className="mb-0 txt_head ">Deliveries</h4>
        <h5
          class="text-right txt_head"
          role="button"
          onClick={() => navigate("/deliveries")}
        >
          <span className="txt-view-all">View All</span>
          <svg
            width="12"
            height="12"
            fill="none"
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.43934 5.25L5.46967 1.28033C5.17678 0.987437 5.17678 0.512564 5.46967 0.21967C5.76256 -0.0732231 6.23744 -0.0732231 6.53033 0.21967L11.7803 5.46967C12.0732 5.76256 12.0732 6.23744 11.7803 6.53033L6.53033 11.7803C6.23744 12.0732 5.76256 12.0732 5.46967 11.7803C5.17678 11.4874 5.17678 11.0126 5.46967 10.7197L9.43934 6.75L0.75 6.75C0.335787 6.75 0 6.41421 0 6C0 5.58579 0.335787 5.25 0.75 5.25L9.43934 5.25Z"
              fill="#252935"
            ></path>
          </svg>
        </h5>
      </div>
      <React.Suspense fallback={<div>Loading..</div>}>
        <DeliveryCarsoulList sts={sts} list={lists} />
      </React.Suspense>
    </div>
  );
}

function Dispensaries({ sts, lists }) {
  const navigate = useNavigate();
  return (
    <div class="container p-0 ptrem">
      <div class="header d-flex justify-content-between">
        <h4 className="mb-0 txt_head ">Dispensaries</h4>
        <h5
          class="text-right txt_head mr-1"
          role="button"
          onClick={() => navigate("/dispensaries")}
        >
          <span className="txt-view-all">View All</span>
          <svg
            width="12"
            height="12"
            fill="none"
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.43934 5.25L5.46967 1.28033C5.17678 0.987437 5.17678 0.512564 5.46967 0.21967C5.76256 -0.0732231 6.23744 -0.0732231 6.53033 0.21967L11.7803 5.46967C12.0732 5.76256 12.0732 6.23744 11.7803 6.53033L6.53033 11.7803C6.23744 12.0732 5.76256 12.0732 5.46967 11.7803C5.17678 11.4874 5.17678 11.0126 5.46967 10.7197L9.43934 6.75L0.75 6.75C0.335787 6.75 0 6.41421 0 6C0 5.58579 0.335787 5.25 0.75 5.25L9.43934 5.25Z"
              fill="#252935"
            ></path>
          </svg>
        </h5>
      </div>

      <React.Suspense fallback={<div>Loading...</div>}>
        <DispensaryCarsoulList sts={sts} list={lists} />
      </React.Suspense>
    </div>
  );
}

function Deals({ sts, lists }) {
  const navigate = useNavigate();
  return (
    <div class="container p-0 ptrem slider-container">
      <div class="header d-flex justify-content-between">
        <h4 className="mb-0 txt_head ">Deals</h4>
        <h5
          class="text-right txt_head mr-1"
          role="button"
          onClick={() => navigate("/deals")}
        >
          <span className="txt-view-all">View All</span>
          <svg
            width="12"
            height="12"
            fill="none"
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.43934 5.25L5.46967 1.28033C5.17678 0.987437 5.17678 0.512564 5.46967 0.21967C5.76256 -0.0732231 6.23744 -0.0732231 6.53033 0.21967L11.7803 5.46967C12.0732 5.76256 12.0732 6.23744 11.7803 6.53033L6.53033 11.7803C6.23744 12.0732 5.76256 12.0732 5.46967 11.7803C5.17678 11.4874 5.17678 11.0126 5.46967 10.7197L9.43934 6.75L0.75 6.75C0.335787 6.75 0 6.41421 0 6C0 5.58579 0.335787 5.25 0.75 5.25L9.43934 5.25Z"
              fill="#252935"
            ></path>
          </svg>
        </h5>
      </div>

      <React.Suspense fallback={<div>Loading...</div>}>
        <SpotLightDeals></SpotLightDeals>
      </React.Suspense>
    </div>
  );
}
function Blogs({ sts, lists }) {
  const navigate = useNavigate();
  return (
    <div class="container p-0 ptrem slider-container">
      <div class="header d-flex justify-content-between">
        <h4 className="mb-0 txt_head ">Blogs</h4>
        <h5
          class="text-right txt_head mr-1"
          role="button"
          onClick={() => navigate("/blog")}
        >
          <span className="txt-view-all">View All</span>
          <svg
            width="12"
            height="12"
            fill="none"
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.43934 5.25L5.46967 1.28033C5.17678 0.987437 5.17678 0.512564 5.46967 0.21967C5.76256 -0.0732231 6.23744 -0.0732231 6.53033 0.21967L11.7803 5.46967C12.0732 5.76256 12.0732 6.23744 11.7803 6.53033L6.53033 11.7803C6.23744 12.0732 5.76256 12.0732 5.46967 11.7803C5.17678 11.4874 5.17678 11.0126 5.46967 10.7197L9.43934 6.75L0.75 6.75C0.335787 6.75 0 6.41421 0 6C0 5.58579 0.335787 5.25 0.75 5.25L9.43934 5.25Z"
              fill="#252935"
            ></path>
          </svg>
        </h5>
      </div>

      <React.Suspense fallback={<div>Loading...</div>}>
        <SpotLightBlogs></SpotLightBlogs>
      </React.Suspense>
    </div>
  );
}
function HomeFooterDiv() {
  return (
    <div class=" home-footer-div">
      <div class="container p-3 ">
        <div class="row pdng_top_btm">
          {/* <div class="col-md-6 col-12">
            <img
              class="img-fluid"
              src={require("../assets/images_/hellalit_green_banner_box_img.png")}
            />
          </div> */}
          <div className="col-md-12 col-12 p-3 p-md-3 p-lg-5 bg-white d-flex align-items-center justify-content-center ">
            <h2 className="font-weight-bold">
              Your New Favorite Cannabis Directory
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

function UpdateWeb() {
  const dispensariesList = require("./dispensariesList.json").result;
  // const deliveriesList = require('./deliveriesList.json').result;
  // const brandsList = []; // require('./brandsList.json').result;

  // const dispensariesList = require('./dispensariesList.json').result;
  // const deliveriesList = require('./deliveriesList.json').result;
  // const brandsList = require('./brandsList.json').result;

  //   const [dynamicDispensaries, setDynamicDispensaries] = React.useState();
  //   const [dynamicDeliveries, setDynamicDeliveries] = React.useState(<Deliveries sts={process.env.REACT_APP_STATUS_LDN} lists={deliveriesList}/>);
  //   const [dynamicBrands, setDynamicBrands] = React.useState(<Brands sts={process.env.REACT_APP_STATUS_LDN} lists={brandsList}/>);

  React.useEffect(() => {
    // if(dispensariesList.length == 0 ){
    dispensariesList.map((row) => {
      // console.log(row.city.city_name);
      if (row.original_web_url != "") {
        axios
          .get(
            "https://development-api.hellalit.org:2022/api/dispensaries/update?id=" +
              row._id +
              "&original_web_url=" +
              row.original_web_url
          )
          .then((response) => {
            if (response.data.status == "success") {
              console.log("updated - " + response.data.result.dispensaries_id);
            }
          });
      }
    });

    // deliveriesList.map((row) => {
    //     // console.log(row.city.city_name);
    //     if(row.original_web_url != ""){
    //         axios.get("https://development-api.hellalit.org:2022/api/deliveries/update?id="+row._id+"&original_web_url="+row.original_web_url)
    //     .then((response) => {
    //         if(response.data.status == 'success'){
    //             console.log('updated - '+response.data.result.deliveries_id)
    //         }
    //     });
    // }
    // });

    // }

    // fetch('https://weedmaps.com/dispensaries/strains-perris-ca/about')
    //   .then((response) => response.json())
    //   .then((response) => {
    //     console.log(response)
    // // now fetch the text
    // fetch(response.url)
    //   .then(response2 => response2.text())
    //   .then(response2 => {
    //     setDynamicDispensaries(response2)
    //   })
    //   })
  }, []);

  //   console.log(dynamicDispensaries);
}

export { Home, UpdateWeb };

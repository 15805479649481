import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import bannerImg from "../assets/images_/p5.png";
import Carousel from "react-multi-carousel";
// import Carousel from "better-react-carousel";
import "react-multi-carousel/lib/styles.css";
import "./additionalCSS.css";
import { ReactSession } from "react-client-session";
import { ShowDetail } from "./commonComp";
function Deals() {
  return (
    <>
      <HeadBanner
        headerContent="Deals in your area"
        imgUrl={bannerImg}
      ></HeadBanner>
      <div className="container p-0 ptrem slider-container">
        <SpotLightDeals></SpotLightDeals>
      </div>
    </>
  );
}
function isImageExtension(extension) {
  var imageExtensions = [".png", ".jpg", ".jpeg", ".jfif", ".jpe"];
  return imageExtensions.includes(extension.toLowerCase());
}

// const imagechanges = (data) => {
//   // var split_url = data.split("?");
//   // var object_key = split_url[1].split("=")[1];

//   // return "https://d3o4at03oqlv0t.cloudfront.net/" + object_key;
//   var split_url = data.split("?");
//   var object_key = split_url[1].split("=")[1];
//   var converted_url =
//     "https://d3o4at03oqlv0t.cloudfront.net/" +
//     object_key.replace(".jpg", ".webp");
//   return converted_url;
// };

const imagechanges = (data) => {
  var split_url = data.split("?");
  var object_key = split_url[1].split("=")[1];

  var fileExtension = object_key.substring(object_key.lastIndexOf(".")); // Extract the file extension
  
  var isImage = isImageExtension(fileExtension);

  if (isImage) {
    var converted_url =
      "https://d3o4at03oqlv0t.cloudfront.net/" +
      object_key.replace(fileExtension, ".webp");
    return converted_url;
  } else {
    // Handle the case when the file extension is not supported
    return "Invalid file extension";
  }
};
function HeadBanner(props) {
  return (
    <div className="banner_mrg_top">
      <img width="100%" src={props.imgUrl} className="img-fluid" />
      <div className="m-4">
        <h2 className="text-center pt-4 pb-3">{props.headerContent}</h2>
      </div>
    </div>
  );
}

function SpotLightDeals() {
  const navigate = useNavigate();

  const [deals, setDeals] = useState([]);

  function getDeals() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "deals/list?city=" +
          ReactSession.get("userlocationDetails").city
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          console.log("suceess");
          setDeals(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getDeals();
  }, []);

  useEffect(() => {
    console.log({ deals });
  }, [deals]);

  return (
    <>
      {/* <div className="row row-cols-3 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 ms-2 me-2 mt-3 flex-nowrap"> */}
      <div className="row ms-2 me-2 mt-3 flex-nowrap deals_row">
        {console.log("deals:", deals)}
        {deals.map((item) => {
          {
            console.log("item:", item.logo);
          }
          return (
            <div
              className="col mb-4"
              onClick={() =>
                navigate("/deals/claim?data=" + btoa(JSON.stringify(item._id)))
              }
            >
              {/* <div className="card h-100"> */}
              <div className="card">
                <img
                  src={imagechanges(item.logo)}
                  className="car-image_deals"
                  alt={item.name}
                />
                {/* <div className="card-body">
                  <h5 className="card-title">{item.name}</h5>
                  <div class="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 ggxWNr hzdsvF">
                    <div class="styles__IconWrap-sc-708jqu-1 hNUGUg pb-1">
                      <svg
                        class="wm-icon-delivery"
                        width="16px"
                        height="16px"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17.5 4c.7 0 1.2.4 1.4 1l2.1 6v8c0 .6-.4 1-1 1h-1a1 1 0 0 1-1-1v-1H6v1c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1v-8l2-6c.3-.6.8-1 1.5-1h11ZM6.9 6 5.4 9.9h13.2l-1.4-4H6.9Zm9.1 7.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0ZM6.5 15a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
                        ></path>
                      </svg>
                      <span
                        data-testid="card-listing"
                        class="text__Text-sc-fif1uk-0 base-card__Helper-sc-1fhygl1-7 hcsRvV iZFKNF"
                      >
                        Delivery from Mission Organic...
                      </span>
                    </div>
                  </div>
                  <p className="card-text">
                    Claim deal and present at purchase
                  </p>
                </div> */}
              </div>
            </div>
          );
        })}
        {/* <div className="col mb-4" onClick={() => navigate("/deals/claim")}>
          <div className="card h-100">
            <img
              src="https://images.weedmaps.com/pictures/listings/448/803/430/410184759_blob?w=352&h=198&dpr=1&auto=format&fit=crop"
              className="card-img-top"
              alt="..."
            />

            <div className="card-body">
              <h5 className="card-title">TOPSHELF - 3X FOR</h5>
              <div class="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 ggxWNr hzdsvF">
                <div class="styles__IconWrap-sc-708jqu-1 hNUGUg pb-1">
                  <svg width="16px" height="16px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="m20.88 7.63.02.02c.1.21.16.44 0 .67 0 .88-.72 1.57-1.6 1.57-.59 0-1.1-.3-1.39-.8-.03-.05-.13-.05-.15 0a1.62 1.62 0 0 1-2.8 0c-.02-.05-.12-.05-.15 0a1.62 1.62 0 0 1-2.78 0c-.03-.05-.13-.05-.16 0a1.62 1.62 0 0 1-2.78 0c-.03-.05-.13-.05-.16 0a1.62 1.62 0 0 1-2.78 0c-.03-.05-.13-.05-.16 0-.26.47-.8.8-1.4.8a1.58 1.58 0 0 1-1.46-2.21v-.03c.02-.07.08-.15.13-.23l1.18-1.95.03-.03V3.1c0-.05.05-.1.1-.1h15.09c.06 0 .1.05.1.1v2.5l1.06 1.95.03.04.03.04Zm-1.42 3.47c-.52 0-1-.15-1.42-.44-.03-.02-.08-.02-.13 0a2.5 2.5 0 0 1-2.84 0c-.02-.02-.07-.02-.13 0a2.5 2.5 0 0 1-2.83 0c-.03-.02-.08-.02-.13 0a2.5 2.5 0 0 1-2.84 0c-.03-.02-.08-.02-.13 0a2.5 2.5 0 0 1-2.84 0c-.02-.02-.07-.02-.13 0-.4.29-.9.44-1.41.44-.16 0-.31-.03-.47-.05-.08-.03-.13.02-.13.1v9.75c-.02.05 0 .1.08.1h1.75c.05 0 .1-.05.1-.1v-6.5c0-.06.06-.11.11-.11h4.18c.05 0 .1.05.1.1v6.5c0 .06.05.11.1.11h9.5c.05 0 .1-.05.1-.1v-9.75c0-.08-.05-.13-.13-.1-.15.02-.3.05-.46.05Zm-1.47 6.87h-4.65c-.05 0-.1-.06-.1-.1v-3.14c0-.06.05-.1.1-.1H18c.05 0 .1.04.1.1v3.13c0 .05-.05.1-.1.1Z"
                    ></path>
                  </svg>

                  <span
                    data-testid="card-listing"
                    class="text__Text-sc-fif1uk-0 base-card__Helper-sc-1fhygl1-7 hcsRvV iZFKNF"
                  >
                    Pickup at Ohana Cannabis
                  </span>
                </div>
              </div>

              <p className="card-text">Claim deal and present at purchase</p>
            </div>
          </div>
        </div>

        <div className="col mb-4" onClick={() => navigate("/deals/claim")}>
          <div className="card h-100">
            <img
              src="https://images.weedmaps.com/pictures/listings/772/736/753/426779483_blob?w=352&h=198&dpr=1&auto=format&fit=crop"
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title">TOPSHELF - 3X FOR</h5>
              <div class="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 ggxWNr hzdsvF">
                <div class="styles__IconWrap-sc-708jqu-1 hNUGUg pb-1">
                  <svg width="16px" height="16px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="m20.88 7.63.02.02c.1.21.16.44 0 .67 0 .88-.72 1.57-1.6 1.57-.59 0-1.1-.3-1.39-.8-.03-.05-.13-.05-.15 0a1.62 1.62 0 0 1-2.8 0c-.02-.05-.12-.05-.15 0a1.62 1.62 0 0 1-2.78 0c-.03-.05-.13-.05-.16 0a1.62 1.62 0 0 1-2.78 0c-.03-.05-.13-.05-.16 0a1.62 1.62 0 0 1-2.78 0c-.03-.05-.13-.05-.16 0-.26.47-.8.8-1.4.8a1.58 1.58 0 0 1-1.46-2.21v-.03c.02-.07.08-.15.13-.23l1.18-1.95.03-.03V3.1c0-.05.05-.1.1-.1h15.09c.06 0 .1.05.1.1v2.5l1.06 1.95.03.04.03.04Zm-1.42 3.47c-.52 0-1-.15-1.42-.44-.03-.02-.08-.02-.13 0a2.5 2.5 0 0 1-2.84 0c-.02-.02-.07-.02-.13 0a2.5 2.5 0 0 1-2.83 0c-.03-.02-.08-.02-.13 0a2.5 2.5 0 0 1-2.84 0c-.03-.02-.08-.02-.13 0a2.5 2.5 0 0 1-2.84 0c-.02-.02-.07-.02-.13 0-.4.29-.9.44-1.41.44-.16 0-.31-.03-.47-.05-.08-.03-.13.02-.13.1v9.75c-.02.05 0 .1.08.1h1.75c.05 0 .1-.05.1-.1v-6.5c0-.06.06-.11.11-.11h4.18c.05 0 .1.05.1.1v6.5c0 .06.05.11.1.11h9.5c.05 0 .1-.05.1-.1v-9.75c0-.08-.05-.13-.13-.1-.15.02-.3.05-.46.05Zm-1.47 6.87h-4.65c-.05 0-.1-.06-.1-.1v-3.14c0-.06.05-.1.1-.1H18c.05 0 .1.04.1.1v3.13c0 .05-.05.1-.1.1Z"
                    ></path>
                  </svg>
                  <span
                    data-testid="card-listing"
                    class="text__Text-sc-fif1uk-0 base-card__Helper-sc-1fhygl1-7 hcsRvV iZFKNF"
                  >
                    Pickup at STIIIZY Mission
                  </span>
                </div>
              </div>
              <p className="card-text">Claim deal and present at purchase.</p>
            </div>
          </div>
        </div>

        <div className="col mb-4" onClick={() => navigate("/deals/claim")}>
          <div className="card h-100">
            <img
              src="https://images.weedmaps.com/pictures/listings/411/162/462/426583902_blob?w=352&h=198&dpr=1&auto=format&fit=crop"
              className="card-img-top"
              alt="..."
            />

            <div className="card-body">
              <h5 className="card-title">TOPSHELF - 3X FOR</h5>

              <div class="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 ggxWNr hzdsvF">
                <div class="styles__IconWrap-sc-708jqu-1 hNUGUg pb-1">
                  <svg width="16px" height="16px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="m20.88 7.63.02.02c.1.21.16.44 0 .67 0 .88-.72 1.57-1.6 1.57-.59 0-1.1-.3-1.39-.8-.03-.05-.13-.05-.15 0a1.62 1.62 0 0 1-2.8 0c-.02-.05-.12-.05-.15 0a1.62 1.62 0 0 1-2.78 0c-.03-.05-.13-.05-.16 0a1.62 1.62 0 0 1-2.78 0c-.03-.05-.13-.05-.16 0a1.62 1.62 0 0 1-2.78 0c-.03-.05-.13-.05-.16 0-.26.47-.8.8-1.4.8a1.58 1.58 0 0 1-1.46-2.21v-.03c.02-.07.08-.15.13-.23l1.18-1.95.03-.03V3.1c0-.05.05-.1.1-.1h15.09c.06 0 .1.05.1.1v2.5l1.06 1.95.03.04.03.04Zm-1.42 3.47c-.52 0-1-.15-1.42-.44-.03-.02-.08-.02-.13 0a2.5 2.5 0 0 1-2.84 0c-.02-.02-.07-.02-.13 0a2.5 2.5 0 0 1-2.83 0c-.03-.02-.08-.02-.13 0a2.5 2.5 0 0 1-2.84 0c-.03-.02-.08-.02-.13 0a2.5 2.5 0 0 1-2.84 0c-.02-.02-.07-.02-.13 0-.4.29-.9.44-1.41.44-.16 0-.31-.03-.47-.05-.08-.03-.13.02-.13.1v9.75c-.02.05 0 .1.08.1h1.75c.05 0 .1-.05.1-.1v-6.5c0-.06.06-.11.11-.11h4.18c.05 0 .1.05.1.1v6.5c0 .06.05.11.1.11h9.5c.05 0 .1-.05.1-.1v-9.75c0-.08-.05-.13-.13-.1-.15.02-.3.05-.46.05Zm-1.47 6.87h-4.65c-.05 0-.1-.06-.1-.1v-3.14c0-.06.05-.1.1-.1H18c.05 0 .1.04.1.1v3.13c0 .05-.05.1-.1.1Z"
                    ></path>
                  </svg>
                  <span
                    data-testid="card-listing"
                    class="text__Text-sc-fif1uk-0 base-card__Helper-sc-1fhygl1-7 hcsRvV iZFKNF"
                  >
                    Pickup at Authentic 415
                  </span>
                </div>
              </div>

              <p className="card-text">Claim deal and present at purchase.</p>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

// function Claim() {
//   let id = JSON.parse(
//     atob(new URLSearchParams(window.location.search).get("data"))
//   );
//   console.log({ id });
//   const [dealsinfo, setDealsinfo] = useState([]);
//   useEffect(() => {
//     getDealsInfo();
//   }, []);
//   function getDealsInfo() {
//     axios
//       .get(process.env.REACT_APP_API_URL + "deals/list?deals_id=" + id)
//       .then((res) => {
//         console.log(res);
//         if (res.data.status == "success") {
//           console.log("suceess");
//           setDealsinfo(res.data.result);
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }

//   return (
//     <>
//       {dealsinfo ? (
//         <div className="container-fluid bg-white banner_mrg_top">
//           <div className="row container-fluid pe-5">
//             <div className="col-lg-4 m-4 mt-5">
//               <div className="row-8">
//                 <img src={dealsinfo.logo} className="card-img-top" alt="..." />
//               </div>
//               <div className="row-4 mt-4 mb-4">
//                 <div className="mb-3">
//                   <p>Deal offered by</p>
//                 </div>

//                 <div className="row border border-secondary pt-2 pb-2 ms-0 me-0">
//                   <div className="col-2 ml-2 mr-2">
//                     <img
//                       src="https://images.weedmaps.com/deliveries/000/226/832/avatar/1635875526-img_1462.jpg"
//                       className="card-img-top"
//                       alt="..."
//                     />
//                   </div>

//                   <div className="col-10 ">
//                     <div className="row">
//                       <h5 className="mb-1">
//                         Mission Organic Delivery - San Francisco
//                       </h5>
//                       <p className="mb-1">
//                         4.7 <span> - 729</span>
//                       </p>
//                       <p className="mb-1">Delivery</p>
//                       <p className="mb-1">San Francisco, California</p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-5 ms-2 mt-4">
//               <div className="row">
//                 <div className="m-3">
//                   <h2>$7 Eighths - Mission Organic</h2>
//                   <p className="mt-4 mb-2">
//                     Mission Organic Farms Desert Lime 1/8 Only $7
//                   </p>
//                 </div>
//                 <div className="m-3">
//                   <div>
//                     <button
//                       className="btn btn-info rounded-pill w-100"
//                       type="button"
//                     >
//                       Claim deal
//                     </button>
//                   </div>
//                   {/* <p className='mt-2 mb-2 text-center'>Expires in 21:21:59</p> */}
//                 </div>
//               </div>
//               <hr></hr>

//               <div className="row">
//                 <div className="m-3">
//                   <h3 className="mt-2 mb-2">Discount details</h3>
//                   <p className="mt-4">Applies to:</p>
//                 </div>
//                 <div className="m-3">
//                   <h3 className="mt-2 mb-2">How to Claim Deal</h3>
//                   <p className="mt-4">
//                     Press the 'Claim Deal' button. You may print out a copy or
//                     just show it to the budtender from your mobile screen.
//                   </p>
//                 </div>
//               </div>
//               <hr></hr>

//               <div className="row">
//                 <div className="m-3">
//                   <section className="mt-2 mb-2">
//                     Deals are valid for a limited time only. Weedmaps reserves
//                     the right to modify or cancel deals at any time. The deal
//                     applies only to qualifying items displaying the deal offer
//                     on the item detail page. The offer will not be valid until
//                     it is applied to the qualifying item. The promotion is
//                     limited to one deal per customer. If you return any of the
//                     items ordered with a deal, the deal discount or value may be
//                     subtracted from the return credit. Offer good while supplies
//                     last. Void where prohibited. Weedmaps has no obligation for
//                     payment of any tax in conjunction with the distribution or
//                     use of any deal. Consumer is required to pay any applicable
//                     sales tax related to the use of the deal. Deals are void if
//                     restricted or prohibited by law.
//                   </section>
//                   <p className="mb-2">Report this tool</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         ""
//       )}
//     </>
//   );
// }
function Claim(props) {
  let id = JSON.parse(
    atob(new URLSearchParams(window.location.search).get("data"))
  );
  const [detail, setDetail] = React.useState([]);
  const [iframestatus, setIframestatus] = React.useState(2);
  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "deals/list?deals_id=" + id)
      .then((response) => {
        if (response.data.status == "success") {
          setDetail(response.data.result);
        }
      });
  }, []);

  return <ShowDetail data={detail} iframe_status={iframestatus} type="deals" />;
}
export { Deals, Claim, SpotLightDeals };

import React, { useContext } from "react";
import { ReactSession } from "react-client-session";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTint, faPenSquare } from "@fortawesome/fontawesome-free-solid";
import {
  faCookie,
  faCannabis,
  faJarWheat,
  faHeartCircleBolt,
  faHeartCircleExclamation,
  faHeartCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  BrandsCarsoulList,
  fontIcons,
  BrandAPI,
  ShowDetail,
} from "./commonComp";
import axios, { isCancel, AxiosError } from "axios";
import { right } from "@popperjs/core";
import "./additionalCSS.css";
import bannerImg from "../assets/images_/brand_banner_new_01.jpg";
function isImageExtension(extension) {
  var imageExtensions = [".png", ".jpg", ".jpeg", ".jfif", ".jpe"];
  return imageExtensions.includes(extension.toLowerCase());
}

// const imagechanges = (data) => {
//   // var split_url = data.split("?");
//   // var object_key = split_url[1].split("=")[1];

//   // return "https://d3o4at03oqlv0t.cloudfront.net/" + object_key;
//   var split_url = data.split("?");
//   var object_key = split_url[1].split("=")[1];
//   var converted_url =
//     "https://d3o4at03oqlv0t.cloudfront.net/" +
//     object_key.replace(".jpg", ".webp");
//   return converted_url;
// };

const imagechanges = (data) => {
  var split_url = data.split("?");
  var object_key = split_url[1].split("=")[1];

  var fileExtension = object_key.substring(object_key.lastIndexOf(".")); // Extract the file extension

  var isImage = isImageExtension(fileExtension);

  if (isImage) {
    var converted_url =
      "https://d3o4at03oqlv0t.cloudfront.net/" +
      object_key.replace(fileExtension, ".webp");
    return converted_url;
  } else {
    // Handle the case when the file extension is not supported
    return "Invalid file extension";
  }
};
function Brands() {
  let brandsList;
  // if(window.location.href.includes('localhost')){
  //     brandsList = require('./brandsList.json').result;
  // }else{
  brandsList = [];
  // }

  const [dynamicBrandCarsoulList, setDynamicBrandCarsoulList] = React.useState(
    <BrandsCarsoulList
      sts={process.env.REACT_APP_STATUS_LDN}
      list={brandsList}
    />
  );
  const [dynamicDetailList, setDynamicDetailList] = React.useState(
    <DetailList list={brandsList} />
  );

  React.useEffect(() => {
    if (brandsList.length == 0) {
      axios
        .get(
          BrandAPI(
            ReactSession.get("userCurrentLocationFlag"),
            ReactSession.get("userlocationLat"),
            ReactSession.get("userlocationLng"),
            ReactSession.get("userlocationDetails").city,
            ReactSession.get("userlocationDetails").state,
            ReactSession.get("userlocationDetails").country
          )
        )
        .then((response) => {
          if (response.data.status == "success") {
            setDynamicBrandCarsoulList(
              <BrandsCarsoulList
                sts={process.env.REACT_APP_STATUS_SCS}
                list={response.data.result}
              />
            );
            setDynamicDetailList(<DetailList list={response.data.result} />);
          } else {
            setDynamicBrandCarsoulList(
              <BrandsCarsoulList
                sts={process.env.REACT_APP_STATUS_ND}
                list={[]}
              />
            );
          }
        })
        .catch((error) => {
          setDynamicBrandCarsoulList(
            <BrandsCarsoulList
              sts={process.env.REACT_APP_STATUS_FLR}
              list={[]}
            />
          );
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
  }, []);

  return (
    <>
      <HeadBanner />
      <div className="container">
        {dynamicBrandCarsoulList}
        {dynamicDetailList}
      </div>
    </>
  );
}

class HeadBanner extends React.Component {
  render() {
    return (
      <div className="banner_mrg_top">
        <img width="100%" src={bannerImg} className="img-fluid" />
        <h2 className="text-center pt-4 pb-3">Featured</h2>
      </div>
    );
  }
}

function DetailList({ list }) {
  const things = ReactSession.get("userlocation");
  const navigate = useNavigate();

  console.log(list);
  //  return;
  let listresult = [];
  let i = 0;
  list.map((innerList) => {
    innerList.map((row) => {
      listresult[i] = row;
      i++;
    });
  });
  list = listresult;

  // const contextType = userLocationContext;
  if (list.length > 0) {
    return (
      <div className="container">
        <div className="list-header d-none">
          <div>
            <span>
              Sorted By Top Brands for{" "}
              <span className="black-text">{things}</span>
            </span>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-4"> bdjhdjfkj </div>
          <div className="col-4"> bdjhdjf </div>
          <div className="col-4"> bdjhdjf </div>
        </div> */}
        <div className="row brands-list">
          {list.map((row) => {
            return (
              <div className="col-lg-3 col-md-4 col-sm-12">
                <div
                  className="brand-card px-2"
                  onClick={() =>
                    navigate(
                      "/brands/detail?data=" + btoa(JSON.stringify(row._id))
                    )
                  }
                >
                  <div className="brand-card d-block pe-none brand_img_hw">
                    <img
                      alt={row.name}
                      src={imagechanges(row.logo)}
                      className="img-fluid mt-3"
                    />

                    <div className="company-name mt-4 pb-4">
                      <h5 className="mt-2">{row.name}</h5>
                    </div>
                  </div>

                  <div className="d-flex row  justify-content-between m-0 p-2 brand-bottom">
                    <div class="col-md-6 col-6 p-0 text-left">
                      <FontAwesomeIcon icon="fa-solid fa-star" />{" "}
                      {Math.round(row.rating)}
                    </div>
                    <div class="col-md-6 col-6 p-0  ">
                      <div
                        className="w-100 text-right"
                        style={{ textAlign: right }}
                      >
                        <FontAwesomeIcon icon={faHeartCircleCheck} />{" "}
                        {kFormatter(row.favorites_count)}
                      </div>
                    </div>
                  </div>

                  {/* <div className="type-of-services">Medicinal - Recreational - CBD</div> */}
                  {/* <div className="pt-3">{row.city+', '+row.state}</div> */}
                  <div className="menu-breakdown d-none">
                    <h5>
                      Menu Breakdown{" "}
                      <span className="items-qty d-none">(0 items)</span>
                    </h5>
                    <div className="menu-category">
                      <div className="item">
                        <FontAwesomeIcon
                          icon={fontIcons.concentrates}
                        ></FontAwesomeIcon>
                        <h6>Concentrates</h6>
                        <p>(0 items)</p>
                      </div>
                      <div className="item">
                        <FontAwesomeIcon icon={faCookie}></FontAwesomeIcon>
                        <h6>Edibles</h6>
                        <p>(0 items)</p>
                      </div>
                      <div className="item">
                        <FontAwesomeIcon icon={faCannabis}></FontAwesomeIcon>
                        <h6>Flower</h6>
                        <p>(0 items)</p>
                      </div>
                      <div className="item">
                        <FontAwesomeIcon icon={faPenSquare}></FontAwesomeIcon>
                        <h6>Vape pens</h6>
                        <p>(0 items)</p>
                      </div>
                      <div className="item">
                        <FontAwesomeIcon icon={faJarWheat}></FontAwesomeIcon>
                        <h6>Other</h6>
                        <p>(0 items)</p>
                      </div>
                    </div>
                  </div>
                  <div className="menu-genetics d-none">
                    <h5>Menu genetics</h5>
                    <ul>
                      <li>
                        Hybrid <span className="item-qty">(0 items)</span>
                      </li>
                      <li>
                        Indica <span className="item-qty">(0 items)</span>
                      </li>
                      <li>
                        Sativa <span className="item-qty">(0 items)</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
function BrandsDetail(props) {
  let id = JSON.parse(
    atob(new URLSearchParams(window.location.search).get("data"))
  );
  const [detail, setDetail] = React.useState([]);
  const [iframestatus, setIframestatus] = React.useState(2);
  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "brands/info?id=" + id)
      .then((response) => {
        if (response.data.status == "success") {
          console.log("response.data.result", response.data.result);
          setDetail(response.data.result);
        }
      });
  }, []);

  return (
    <ShowDetail data={detail} iframe_status={iframestatus} type="brands" />
  );
}
// function BrandsDetail(props) {
//   let params = useSearchParams();
//   let data = JSON.parse(
//     new URLSearchParams(window.location.search).get("data")
//   );
//   // data = getDetail(data);

//   return (
//     <div className="company-page-container">
//       <div className="page-header">
//         <div className="image-name-box">
//           <div className="image-box">
//             <img src={data.logo} className="img-fluid" alt="" />
//           </div>
//         </div>
//         <h4>{data.name}</h4>
//         <span className="grey-text">
//           <span>{data.city},</span> {data.state}
//         </span>
//       </div>
//       <iframe
//         src={data.web}
//         title={data.name}
//         height="1000"
//         width="100%"
//       ></iframe>
//     </div>
//   );
// }

// function getDetail(id){
//     let result = [];
//     brandsList.map((row) => {
//         if(row.id == id){
//             result = row;
//         }
//     });
//     return result;
// }

function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}
export { Brands, BrandsDetail };

import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DispensariesDetail } from "./dispensaries";
import { ReactSession } from "react-client-session";
import {
  faTint,
  faPenSquare,
  faMapMarkerAlt,
  faArrowAltCircleUp,
} from "@fortawesome/fontawesome-free-solid";
import {
  faCookie,
  faCannabis,
  faJarWheat,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import Carousel from "react-multi-carousel";
// import Carousel from "better-react-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./additionalCSS.css";
import phoneFormatter from "phone-formatter";
import samImg1 from "../assets/images_/products/default_blog_image.png";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 2000 },
    items: 1,
    // slidesToSlide: 6
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 1,
    // slidesToSlide: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 750 },
    items: 1,
    // slidesToSlide: 4
  },
  largeMobile: {
    breakpoint: { max: 750, min: 464 },
    items: 1,
    // slidesToSlide: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    // slidesToSlide: 2
  },
};

const fontIcons = {
  concentrates: faTint,
  edibles: faCookie,
  flower: faCannabis,
  "vape-pens": faPenSquare,
};
function isImageExtension(extension) {
  var imageExtensions = [".png", ".jpg", ".jpeg", ".jfif", ".jpe"];
  return imageExtensions.includes(extension.toLowerCase());
}

// const imagechanges = (data) => {
//   // var split_url = data.split("?");
//   // var object_key = split_url[1].split("=")[1];

//   // return "https://d3o4at03oqlv0t.cloudfront.net/" + object_key;
//   var split_url = data.split("?");
//   var object_key = split_url[1].split("=")[1];
//   var converted_url =
//     "https://d3o4at03oqlv0t.cloudfront.net/" +
//     object_key.replace(".jpg", ".webp");
//   return converted_url;
// };

const imagechanges = (data) => {
  var split_url = data.split("?");
  var object_key = split_url[1].split("=")[1];

  var fileExtension = object_key.substring(object_key.lastIndexOf(".")); // Extract the file extension

  var isImage = isImageExtension(fileExtension);

  if (isImage) {
    var converted_url =
      "https://d3o4at03oqlv0t.cloudfront.net/" +
      object_key.replace(fileExtension, ".webp");
    return converted_url;
  } else {
    // Handle the case when the file extension is not supported
    return "Invalid file extension";
  }
};
function DispensaryAPI(
  currentLocationFlag,
  lat,
  lng,
  city = "",
  state = "",
  country = "",
  meters = 10000
) {
  let DispensaryAPI = process.env.REACT_APP_API_URL + "dispensaries/list?";

  if (
    ReactSession.get("searchLocationBetween") &&
    window.location.pathname.includes("map")
  ) {
    if (window.location.href.includes("localhost")) {
      DispensaryAPI += "&limit=10&";
    }
    DispensaryAPI +=
      "boundries=" +
      JSON.stringify(ReactSession.get("searchLocationBetweenVP"));
    return DispensaryAPI;
  }
  if (currentLocationFlag)
    DispensaryAPI +=
      "latitude=" + lat + "&longitude=" + lng + "&meters=" + meters;
  else
    DispensaryAPI +=
      "boundries=" + JSON.stringify(ReactSession.get("userlocationVP"));

  if (window.location.href.includes("localhost")) {
    DispensaryAPI += "&limit=10&";
  } else {
    if (window.location.pathname.includes("dispensaries")) {
    } else {
      DispensaryAPI += "&limit=100";
    }
  }
  return DispensaryAPI;
}

function DeliveryAPI(
  currentLocationFlag,
  lat,
  lng,
  city = "",
  state = "",
  country = "",
  meters = 10000
) {
  let DeliveryAPI = process.env.REACT_APP_API_URL + "deliveries/list?";

  if (currentLocationFlag)
    DeliveryAPI +=
      "latitude=" + lat + "&longitude=" + lng + "&meters=" + meters;
  else
    DeliveryAPI += "city=" + city + "&state=" + state + "&country=" + country;

  if (window.location.href.includes("localhost")) {
    DeliveryAPI += "&limit=10&";
  } else {
    if (window.location.pathname.includes("deliveries")) {
    } else {
      DeliveryAPI += "&limit=100";
    }
  }

  return DeliveryAPI;
}

function BrandAPI(
  currentLocationFlag,
  lat,
  lng,
  city = "",
  state = "",
  country = "",
  meters = 10000
) {
  let BrandAPI = process.env.REACT_APP_API_URL + "brands/list?";

  if (window.location.href.includes("localhost")) {
    BrandAPI += "&limit=10&";
  } else {
    BrandAPI += "&limit=50&";
  }

  return BrandAPI;
}

function getStatusText(sts) {
  let txt = "";
  if (sts != "scs") {
    if (sts == process.env.REACT_APP_STATUS_LDN) {
      txt = process.env.REACT_APP_STATUS_LDN_TEXT;
    }
    if (sts == process.env.REACT_APP_STATUS_FLR) {
      txt = process.env.REACT_APP_STATUS_FLR_TEXT;
    }

    if (sts == process.env.REACT_APP_STATUS_ND) {
      txt = process.env.REACT_APP_STATUS_ND_TEXT;
    }
  }
  return txt;
}

function DispensaryCarsoulList(data) {
  return (
    <div className="container pt-1">
      {data.list.length ? (
        <CarouselListSet list={data.list} type="dispensaries" />
      ) : (
        <span className="statusTxt">{getStatusText(data.sts)}</span>
      )}
    </div>
  );
}

function DeliveryCarsoulList(data) {
  return (
    <div className="container pt-1">
      {data.list.length ? (
        <CarouselListSet list={data.list} type="deliveries" />
      ) : (
        <span className="statusTxt">{getStatusText(data.sts)}</span>
      )}
    </div>
  );
}

function BrandsCarsoulList(data) {
  return (
    <div className="container pt-1">
      {data.list.length ? (
        <CarouselListSet list={data.list} type="brands" />
      ) : (
        <span className="statusTxt">{getStatusText(data.sts)}</span>
      )}
    </div>
  );
}

function CarouselListSet({ list, type }) {
  //   const MyDot = ({ isActive }) => (
  //     <span
  //       style={{
  //         display: "inline-block",
  //         height: isActive ? "8px" : "5px",
  //         width: isActive ? "8px" : "5px",
  //         background: isActive ? "#dce4e8" : "#ccc",
  //         borderRadius: "50%",
  //       }}
  //     ></span>
  //   );

  //   const arrowV = () => (
  //     <span
  //       style={{
  //         display: "block",
  //         background: "black",
  //         borderRadius: "50%",
  //       }}
  //     ></span>
  //   );

  //   const carouselLayout = [
  //     {
  //       breakpoint: 800,
  //       cols: 5,
  //       rows: 1,
  //       gap: 10,
  //       loop: true,
  //       autoplay: 1000,
  //     },
  //   ];
  const navigate = useNavigate();
  const maxSlideLength = 700;
  let i = 0;
  //   let additionalDiv;

  //   if (
  //     list.length > maxSlideLength &&
  //     window.location.pathname.includes(type) == false
  //   )
  // additionalDiv = <AdditionalSliderDiv link={type} name={type} />;
  let myShows = [3000, 4000, 5000];

  return (
    <div className="row slider-container">
      {list.map((innerList) => {
        i++;
        return (
          <div className="text-center car-div bg-white1 car-item pt-2 col">
            {/* <Carousel responsive={responsive} infinite={true} reversed={false} hideArrow={true} cols={1} rows={1} gap={15} loop autoplay={(i*1000)+3000}> */}

            <Carousel
              responsive={responsive}
              swipeable={true}
              infinite={true}
              arrows={false}
              cols={1}
              rows={1}
              transitionDuration={500}
              autoPlay={true}
              autoPlaySpeed={
                myShows[Math.floor(Math.random() * myShows.length)]
              }
            >
              {innerList.map((item) => {
                console.log(item);

                i++;
                if (i > maxSlideLength) return;

                if (type === "dispensaries") {
                  return DispensaryCarsoulListItem(item);
                }

                if (type === "deliveries") {
                  return DeliveryCarsoulListItem(item);
                }

                if (type === "brands") {
                  return BrandCarsoulListItem(item);
                }
              })}
              {/* ... */}
            </Carousel>
          </div>
        );
      })}
    </div>
  );
}

function DispensaryCarsoulListItem(item) {
  return (
    // <Carousel.Item id={"cars_" + item._id} className="">
    <a href={"/dispensaries/detail?data=" + btoa(JSON.stringify(item._id))}>
      <img
        id={"imgtag" + item._id}
        alt={item.name}
        className="car-image "
        src={imagechanges(item.logo)}
        title={item.city.city_name + ", " + item.state.state_name}
      />
      <div className="base-card__Info-sc-1fhygl1-4 bwvvTe base-card">
        <div className="styles__LocationWrapper-sc-1wt1da7-4 eNXZyL">
          <span className="text__Text-sc-fif1uk-0 base-card__Helper-sc-1fhygl1-7 iQGtwy jgJGIG">
            {item.city.city_name}, {item.state.state_name}
          </span>
          <span className="text-left text__Text-sc-fif1uk-0 styles__Distance-sc-1wt1da7-3 iQGtwy fvWKmE">
            | 3 mi
          </span>
        </div>
        <div className="text-left txt_state">{item.name}</div>
        <span className="txt-depat">Medical &amp; Recreational</span>
      </div>
    </a>
    // </Carousel.Item>
  );
}

function DeliveryCarsoulListItem(item) {
  return (
    // <Carousel.Item id={"cars_" + item._id} className="col">
    <a href={"/deliveries/detail?data=" + btoa(JSON.stringify(item._id))}>
      <img
        id={"imgtag" + item._id}
        alt={item.name}
        className="car-image"
        src={imagechanges(item.logo)}
      />
      <div className="base-card__Info-sc-1fhygl1-4 bwvvTe base-card">
        <div className="styles__LocationWrapper-sc-1wt1da7-4 eNXZyL">
          <span className="text__Text-sc-fif1uk-0 base-card__Helper-sc-1fhygl1-7 iQGtwy jgJGIG">
            {item.city.city_name}, {item.state.state_name}
          </span>
          <span className="text-left text__Text-sc-fif1uk-0 styles__Distance-sc-1wt1da7-3 iQGtwy fvWKmE">
            | 3 mi
          </span>
        </div>
        <div className="text-left txt_state">{item.name}</div>
        <span className="txt-depat">Medical &amp; Recreational</span>
      </div>
    </a>
    // </Carousel.Item>
  );
}

function BrandCarsoulListItem(item) {
  return (
    // <Carousel.Item id={"cars_" + item._id} className="col">
    <a href={"/brands/detail?data=" + btoa(JSON.stringify(item._id))}>
      <img
        id={"imgtag" + item._id}
        alt={item.name}
        className="car-image"
        src={imagechanges(item.logo)}
      />
      {/* <div className="text-left txt_state">{item.name}</div> */}
      {/* <h5 className="pt-3">{item.name}</h5> */}
    </a>
    // </Carousel.Item>
  );
}

function ShowDetail({ data, iframe_status, type = "" }) {
  const ref = React.useRef(null);
  useEffect(() => {}, []);
  const ReactHtml = (data) => {
    const htmlContent = data;

    const divElement = document.createElement("div");
    divElement.innerHTML = htmlContent;
    const textContent = divElement.innerText;

    return <div>{textContent}</div>;
  };
  const renderEditorContent = (editorContent) => {
    return { __html: editorContent };
  };
  const imagerender = (data) => {
    var str = data;
    var elem = document.createElement("div");
    elem.style.display = "none";
    document.body.appendChild(elem);
    elem.innerHTML = str;

    var imgElement = elem.querySelector("img");
    if (imgElement) {
      return imgElement.src;
    } else {
      // Handle the case when no <img> element is found
      console.error("No <img> element found.");
      return null; // or return a default image source
    }
  };

  if (data.length == 0 || type == "") {
    return;
  } else {
    if (type == "deliveries") {
      return (
        <div className="company-page-container">
          <CompanyDetail row={data} iframe_status={iframe_status} type={type} />
          <div className="page-header d-none">
            <div className="image-name-box">
              <div className="image-box">
                <img
                  src={imagechanges(data.logo)}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <h4>{data.name}</h4>
            <span className="grey-text">
              <span>{data.city.city_name},</span> {data.state.state_name}
            </span>
          </div>
          {data.original_web_url != "" && iframe_status == 0 && (
            <iframe
              className="tyDGf"
              id="iframeV"
              ref={ref}
              src={data.original_web_url}
              title={data.name}
              height="1000"
              width="100%"
            ></iframe>
          )}
        </div>
      );
    }
    if (type == "dispensaries") {
      return (
        <div className="company-page-container">
          <CompanyDetail row={data} iframe_status={iframe_status} type={type} />
          <div className="page-header d-none">
            <div className="image-name-box">
              <div className="image-box">
                <img
                  src={imagechanges(data.logo)}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <h4>{data.name}</h4>
            <span className="grey-text">
              <span>{data.city.city_name}, </span> {data.state.state_name}
            </span>
          </div>
          {data.original_web_url != "" && iframe_status == 0 && (
            <iframe
              id="iframeV"
              src={data.original_web_url}
              title={data.name}
              height="1000"
              width="100%"
            ></iframe>
          )}
        </div>
      );
    }

    if (type == "brands") {
      // <> {console.log("logo", data.logo)}</>
      return (
        <div>
          <CompanyDetail1
            row={data}
            iframe_status={iframe_status}
            type={type}
          />
          <div className="page-header d-none">
            <div className="image-name-box">
              <div className="image-box">
                <img
                  src={imagechanges(data.logo)}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <h4>{data.name}</h4>
            {/* <span className="grey-text">
              <span>{data.city.city_name},</span> {data.state.state_name}
            </span> */}
          </div>
          {/* {data.original_web_url != "" && iframe_status == 0 && (
            <iframe
              className="tyDGf"
              id="iframeV"
              ref={ref}
              src={data.original_web_url}
              title={data.name}
              height="1000"
              width="100%"
            ></iframe>
          )} */}
        </div>
      );
    }
    if (type === "deals") {
      return (
        <div className="company-page-container">
          <DealsDetail row={data} iframe_status={iframe_status} type={type} />
          <div className="page-header d-none">
            <div className="image-name-box">
              <div className="image-box">
                <img
                  src={imagechanges(data.logo)}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <h4>{data.name}</h4>
            <span className="grey-text">
              <span>{data.city.city_name},</span> {data.state.state_name}
            </span>
          </div>
        </div>
      );
    }
    if (type === "blogs") {
      return (
        <div className="company-page-container blog_detail_container">
          <BlogsDetail row={data} iframe_status={iframe_status} type={type} />
          <div className="page-header d-none">
            <div className="image-name-box">
              <div className="image-box">
                {/* <img src={samImg1} className="img-fluid" alt="" /> */}
                <img
                  src={imagerender(data.description)}
                  className="car-image_blogs"
                  alt={samImg1}
                />
              </div>
            </div>
            <h4>{data.title}</h4>
            <span className="grey-text">
              {/* <span>{ReactHtml(data.description)}</span> */}
              <div
                dangerouslySetInnerHTML={renderEditorContent(data.description)}
              />
            </span>
          </div>
        </div>
      );
    }
  }
}

function AdditionalSliderDiv({ link, name }) {
  console.log(link);
  const navigate = useNavigate();
  return (
    <h6
      className="text-right view-all-link"
      role="button"
      onClick={() => navigate("/" + link)}
    >
      Click to view all {name}
    </h6>
  );
}

function CompanyDetail({ row, iframe_status, type }) {
  return (
    <div>
      <center>
        <div
          data-testid="avatar-container col-6"
          className="avatar__AvatarStyled-sc-1h8176t-0 hwPgVa styled-components__Avatar-sc-1sy7kcj-4 gwbtRb"
          size="140"
          shape="rounded"
        >
          <img
            alt={row.name}
            data-testid="avatar-img"
            width="140"
            height="140"
            fit="crop"
            // src={
            //   imagechanges(row.logo) +
            //   "&fit=crop&amp;auto=format&amp;w=140&amp;h=140"
            // }
            // srcSet={
            //   imagechanges(row.logo) +
            //   "&w=140&amp;h=140&amp;dpr=1&amp;auto=format&amp;fit=crop 1x, " +
            //   imagechanges(row.logo) +
            //   "&w=140&amp;h=140&amp;dpr=2&amp;auto=format&amp;fit=crop 2x"
            // }
            src={imagechanges(row.logo)}
            // srcSet={
            //   imagechanges(row.logo) +
            //   "&w=140&amp;h=140&amp;dpr=1&amp;auto=format&amp;fit=crop 1x, " +
            //   imagechanges(row.logo) +
            //   "&w=140&amp;h=140&amp;dpr=2&amp;auto=format&amp;fit=crop 2x"
            // }
          />
        </div>

        <div
          height="100%"
          className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 hTpkMG dtEVmG"
        >
          <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 listing-header___StyledFlex-sc-zgze8e-0 ciTqMI dtEVmG gdgHep col-12">
            <div className="styled-components__ListingNameWrapper-sc-1sy7kcj-6 eFiTjJ">
              <h1 className="styled-components__ListingName-sc-1sy7kcj-7 fTPmOY">
                {row.name}
              </h1>
            </div>
            {/* <div
            className="styled-components__FavoriteWrapMobile-sc-1sy7kcj-10 jdGTvQ">
            <div><button
                    className="circle-button__Wrapper-sc-19k5rjp-1 eHHojs favorite-toggle"
                    aria-label="Favorite RISE Dispensaries Carson City"
                    aria-pressed="false">
                    <div
                        className="circle-button__Circle-sc-19k5rjp-0 iYrabc">
                        <span
                            className="heart-animated__IconWrapper-sc-1o5ptja-0 blAHwo"
                            ><svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24">
                                <path
                                    fill="#fff"
                                    d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z">
                                </path>
                                <path
                                    fill="#60646f"
                                    d="M12 4.76a5.94 5.94 0 014.5-2.14A5.5 5.5 0 0122 8.24c0 3.86-3.4 7-8.54 11.77l-.01.01L12 21.37l-1.45-1.34-.04-.04C5.38 15.23 2 12.1 2 8.24a5.5 5.5 0 015.5-5.62c1.74 0 3.41.83 4.5 2.14zm0 13.85l.1-.1c4.76-4.4 7.9-7.32 7.9-10.27 0-2.04-1.5-3.58-3.5-3.58a3.88 3.88 0 00-3.56 2.42h-1.87A3.9 3.9 0 007.5 4.66C5.5 4.66 4 6.2 4 8.24c0 2.95 3.14 5.87 7.9 10.27l.1.1z"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    style={{opacity:1}}>
                                </path>
                            </svg></span>
                        <div size="32"
                            className="heart-animated__AnimatedCircle-sc-1o5ptja-1 hvJUkp fDtgs"
                            >
                        </div>
                    </div><svg
                        width="8px"
                        height="8px"
                        viewBox="0 0 24 24"
                        className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                        <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z">
                        </path>
                    </svg><svg
                        width="8px"
                        height="8px"
                        viewBox="0 0 24 24"
                        className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                        <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z">
                        </path>
                    </svg><svg
                        width="8px"
                        height="8px"
                        viewBox="0 0 24 24"
                        className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                        <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z">
                        </path>
                    </svg><svg
                        width="8px"
                        height="8px"
                        viewBox="0 0 24 24"
                        className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                        <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z">
                        </path>
                    </svg><svg
                        width="8px"
                        height="8px"
                        viewBox="0 0 24 24"
                        className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                        <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z">
                        </path>
                    </svg>
                </button>
            </div>
        </div> */}
            <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 ekVVVv dtEVmG d-inline-block">
              <span className="styled-components__LocationText-sc-1sy7kcj-9 blmfrU">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </span>{" "}
              <span>
                {row.city.city_name}, {row.state.state_name},{" "}
                {row.country.country_name}
              </span>
            </div>
            <div
              display="block"
              className="src__Box-sc-1sbtrzs-0 sc-bczRLJ cPobsX"
            >
              <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 ciTqMI dtEVmG align-items-center">
                <div className="header-info__Columns-sc-1heoxjr-0 eBJiAt">
                  {type == "dispensaries" && (
                    <div
                      data-testid="listing-type"
                      className="text__Text-sc-fif1uk-0 header-info__ListingInfoLine-sc-1heoxjr-2 hniXLZ inutnG"
                    >
                      <svg
                        width="16px"
                        height="16px"
                        viewBox="0 0 24 24"
                        className="header-info__StyledStoreIcon-sc-1heoxjr-11 elvSbv"
                      >
                        <path
                          fill="#60646f"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="m20.88 7.63.02.02c.1.21.16.44 0 .67 0 .88-.72 1.57-1.6 1.57-.59 0-1.1-.3-1.39-.8-.03-.05-.13-.05-.15 0a1.62 1.62 0 0 1-2.8 0c-.02-.05-.12-.05-.15 0a1.62 1.62 0 0 1-2.78 0c-.03-.05-.13-.05-.16 0a1.62 1.62 0 0 1-2.78 0c-.03-.05-.13-.05-.16 0a1.62 1.62 0 0 1-2.78 0c-.03-.05-.13-.05-.16 0-.26.47-.8.8-1.4.8a1.58 1.58 0 0 1-1.46-2.21v-.03c.02-.07.08-.15.13-.23l1.18-1.95.03-.03V3.1c0-.05.05-.1.1-.1h15.09c.06 0 .1.05.1.1v2.5l1.06 1.95.03.04.03.04Zm-1.42 3.47c-.52 0-1-.15-1.42-.44-.03-.02-.08-.02-.13 0a2.5 2.5 0 0 1-2.84 0c-.02-.02-.07-.02-.13 0a2.5 2.5 0 0 1-2.83 0c-.03-.02-.08-.02-.13 0a2.5 2.5 0 0 1-2.84 0c-.03-.02-.08-.02-.13 0a2.5 2.5 0 0 1-2.84 0c-.02-.02-.07-.02-.13 0-.4.29-.9.44-1.41.44-.16 0-.31-.03-.47-.05-.08-.03-.13.02-.13.1v9.75c-.02.05 0 .1.08.1h1.75c.05 0 .1-.05.1-.1v-6.5c0-.06.06-.11.11-.11h4.18c.05 0 .1.05.1.1v6.5c0 .06.05.11.1.11h9.5c.05 0 .1-.05.1-.1v-9.75c0-.08-.05-.13-.13-.1-.15.02-.3.05-.46.05Zm-1.47 6.87h-4.65c-.05 0-.1-.06-.1-.1v-3.14c0-.06.05-.1.1-.1H18c.05 0 .1.04.1.1v3.13c0 .05-.05.1-.1.1Z"
                        ></path>
                      </svg>
                      Storefront
                    </div>
                  )}
                  {type == "deliveries" && (
                    <div
                      data-testid="listing-type"
                      className="text__Text-sc-fif1uk-0 header-info__ListingInfoLine-sc-1heoxjr-2 hniXLZ inutnG"
                    >
                      <svg
                        classN="header-info__StyledDeliveryIcon-sc-1heoxjr-10 kBZlzo"
                        width="16px"
                        height="16px"
                        fill="#60646f"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          clip-rule="evenodd"
                          d="M17.5 4c.7 0 1.2.4 1.4 1l2.1 6v8c0 .6-.4 1-1 1h-1a1 1 0 0 1-1-1v-1H6v1c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1v-8l2-6c.3-.6.8-1 1.5-1h11ZM6.9 6 5.4 9.9h13.2l-1.4-4H6.9Zm9.1 7.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0ZM6.5 15a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
                        ></path>
                      </svg>{" "}
                      Delivery Only
                    </div>
                  )}
                  {/* <div
                    data-testid="hours-status"
                    className="text__Text-sc-fif1uk-0 header-info__ListingInfoLine-sc-1heoxjr-2 hniXLZ inutnG"
                  >
                    <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 iSbMbQ dtEVmG">
                      <svg
                        className="styled-components__StyledClockIcon-sc-1sy7kcj-5 cLeDkd"
                        height="13px"
                        width="13px"
                        viewBox="0 0 12 12"
                        fill="#60646f"
                      >
                        <path
                          fill="#60646f"
                          fillRule="nonzero"
                          d="M6 0a6 6 0 110 12A6 6 0 016 0zm.49 6.49V1.592a.49.49 0 00-.98 0V5.51H2.571a.49.49 0 00-.481.402L2.082 6c0 .27.219.49.49.49H6.49z"
                        ></path>
                      </svg>
                      <span
                        color="#aa2637"
                        className="header-info__OpenStatusLabel-sc-1heoxjr-3 eIGlpL"
                      >
                        Closed
                      </span>
                      <span>opens 9:00am</span>
                      <span className="header-tooltip__TooltipWrapper-sc-1rjsmt3-0 iNsVeG">
                        <div>
                          <button
                            data-testid="info-button"
                            type="button"
                            aria-haspopup="dialog"
                            aria-expanded="false"
                            aria-controls="radix-19"
                            data-state="closed"
                            className="tooltip-with-info-button__TriggerButton-sc-1gec0wn-0 fXyGpt"
                            fdprocessedid="8rb0co"
                          >
                            <span className="screen-reader-text__ScreenReaderText-sc-1qiv5xg-0 fEaXzQ">
                              Info
                            </span>
                            <svg
                              width="12px"
                              height="12px"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <circle
                                cx="7"
                                cy="7"
                                r="6"
                                fill="transparent"
                              ></circle>
                              <path
                                d="M7 .333a6.667 6.667 0 110 13.334A6.667 6.667 0 017 .333zm0 1.334a5.333 5.333 0 100 10.666A5.333 5.333 0 007 1.667zm0 4.482c.368 0 .667.298.667.666v2.852a.667.667 0 01-1.334 0V6.815c0-.368.299-.666.667-.666zm0-2.482A.667.667 0 117 5a.667.667 0 010-1.333z"
                                fill="#a5a9b1"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </span>
                    </div>
                  </div> */}
                  <div
                    data-testid="license-type"
                    className="text__Text-sc-fif1uk-0 header-info__ListingInfoLine-sc-1heoxjr-2 hniXLZ inutnG"
                  >
                    <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 iSbMbQ dtEVmG">
                      <svg
                        width="16"
                        data-testid="solid-id"
                        height="16"
                        viewBox="0 0 16 16"
                        className="header-info__StyledIdIcon-sc-1heoxjr-7 irCYFT"
                      >
                        <path
                          d="M13.25 3c.966 0 1.75.784 1.75 1.75v6.125a1.75 1.75 0 01-1.75 1.75H2.75A1.75 1.75 0 011 10.875V4.75C1 3.784 1.784 3 2.75 3h10.5zm-1.313 6.125h-3.5a.437.437 0 100 .875h3.5a.437.437 0 100-.875zm-5.687-3.5H4.5a.875.875 0 00-.875.875v1.75c0 .483.392.875.875.875h1.75a.875.875 0 00.875-.875V6.5a.875.875 0 00-.875-.875zm5.688 1.75h-3.5a.437.437 0 100 .875h3.5a.437.437 0 100-.875zm0-1.75h-3.5a.437.437 0 100 .875h3.5a.437.437 0 100-.875z"
                          fill="#60646f"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                      Medical &amp; recreational
                      <span className="header-tooltip__TooltipWrapper-sc-1rjsmt3-0 iNsVeG">
                        <div>
                          <button
                            data-testid="info-button"
                            type="button"
                            aria-haspopup="dialog"
                            aria-expanded="false"
                            aria-controls="radix-20"
                            data-state="closed"
                            className="tooltip-with-info-button__TriggerButton-sc-1gec0wn-0 fXyGpt"
                            fdprocessedid="vu95ed"
                          >
                            <span className="screen-reader-text__ScreenReaderText-sc-1qiv5xg-0 fEaXzQ">
                              Info
                            </span>
                            <svg
                              width="12px"
                              height="12px"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <circle
                                cx="7"
                                cy="7"
                                r="6"
                                fill="transparent"
                              ></circle>
                              <path
                                d="M7 .333a6.667 6.667 0 110 13.334A6.667 6.667 0 017 .333zm0 1.334a5.333 5.333 0 100 10.666A5.333 5.333 0 007 1.667zm0 4.482c.368 0 .667.298.667.666v2.852a.667.667 0 01-1.334 0V6.815c0-.368.299-.666.667-.666zm0-2.482A.667.667 0 117 5a.667.667 0 010-1.333z"
                                fill="#a5a9b1"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div
                    data-testid="license-info"
                    className="text__Text-sc-fif1uk-0 header-info__ListingInfoLine-sc-1heoxjr-2 hniXLZ inutnG"
                  >
                    <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 iSbMbQ dtEVmG">
                      <svg
                        className="header-info__StyledSolidCheck-sc-1heoxjr-9 JzmEY"
                        height="16px"
                        width="16px"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="#60646f"
                          d="M8 1.788l.39.013.389.036.385.061.38.085.376.11.367.132.358.155.347.177.336.198.323.22.308.239.293.258.276.276.258.293.24.308.219.323.198.336.177.347.155.358.133.367.109.375.085.381.06.385.037.389.013.39-.013.39-.036.389-.061.385-.085.38-.11.376-.132.367-.155.358-.177.347-.198.336-.22.323-.239.308-.258.293-.276.276-.293.258-.308.24-.323.219-.336.198-.347.177-.358.155-.367.133-.375.109-.381.085-.385.06-.389.037-.39.013-.39-.013-.389-.036-.385-.061-.38-.085-.376-.11-.367-.132-.358-.155-.347-.177-.336-.198-.323-.22-.308-.239-.293-.258-.276-.276-.258-.293-.24-.308-.219-.323-.198-.336-.177-.347-.155-.358-.133-.367-.109-.375-.085-.381-.06-.385L1.8 8.39 1.788 8l.013-.39.036-.389.061-.385.085-.38.11-.376.132-.367.155-.358.177-.347.198-.336.22-.323.239-.308.258-.293.276-.276.293-.258.308-.24.323-.219.336-.198.347-.177.358-.155.367-.133.375-.109.381-.085.385-.06.389-.037.39-.013zm2.44 3.541l-3.352 3.29-1.528-1.5-1.045 1.026 2.572 2.526h.002l4.396-4.316L10.44 5.33z"
                        ></path>
                      </svg>
                      License information
                      <span className="header-tooltip__TooltipWrapper-sc-1rjsmt3-0 iNsVeG">
                        <div>
                          <button
                            data-testid="info-button"
                            type="button"
                            aria-haspopup="dialog"
                            aria-expanded="false"
                            aria-controls="radix-21"
                            data-state="closed"
                            className="tooltip-with-info-button__TriggerButton-sc-1gec0wn-0 fXyGpt"
                            fdprocessedid="5083lo"
                          >
                            <span className="screen-reader-text__ScreenReaderText-sc-1qiv5xg-0 fEaXzQ">
                              Info
                            </span>
                            <svg
                              width="12px"
                              height="12px"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <circle
                                cx="7"
                                cy="7"
                                r="6"
                                fill="transparent"
                              ></circle>
                              <path
                                d="M7 .333a6.667 6.667 0 110 13.334A6.667 6.667 0 017 .333zm0 1.334a5.333 5.333 0 100 10.666A5.333 5.333 0 007 1.667zm0 4.482c.368 0 .667.298.667.666v2.852a.667.667 0 01-1.334 0V6.815c0-.368.299-.666.667-.666zm0-2.482A.667.667 0 117 5a.667.667 0 010-1.333z"
                                fill="#a5a9b1"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </span>
                    </div>
                  </div>
                  {type == "dispensaries" &&
                    row.dispensaries_result.online_ordering.enabled ==
                      false && (
                      <div
                        data-testid="service-type"
                        className="text__Text-sc-fif1uk-0 header-info__ListingInfoLine-sc-1heoxjr-2 hniXLZ inutnG"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="header-info__StyledCartIcon-sc-1heoxjr-8 khzMEu"
                          width="16px"
                          height="16px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#60646f"
                            d="M8 18c-1.1 0-1.99.9-1.99 2S6.9 22 8 22s2-.9 2-2-.9-2-2-2zM2 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H8.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A1.003 1.003 0 0 0 21 4H6.21l-.94-2H2zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
                          ></path>
                        </svg>
                        In-store purchases only
                      </div>
                    )}

                  {type == "dispensaries" &&
                    row.dispensaries_result.online_ordering.enabled == true && (
                      <div
                        data-testid="service-type"
                        className="text__Text-sc-fif1uk-0 header-info__ListingInfoLine-sc-1heoxjr-2 hniXLZ inutnG"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="header-info__StyledCartIcon-sc-1heoxjr-8 khzMEu"
                          width="16px"
                          height="16px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#60646f"
                            d="M8 18c-1.1 0-1.99.9-1.99 2S6.9 22 8 22s2-.9 2-2-.9-2-2-2zM2 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H8.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A1.003 1.003 0 0 0 21 4H6.21l-.94-2H2zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
                          ></path>
                        </svg>
                        Order online (pickup)
                      </div>
                    )}
                  {type == "deliveries" && (
                    <div
                      data-testid="service-type"
                      className="text__Text-sc-fif1uk-0 header-info__ListingInfoLine-sc-1heoxjr-2 hniXLZ inutnG"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="header-info__StyledCartIcon-sc-1heoxjr-8 khzMEu"
                        width="16px"
                        height="16px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#60646f"
                          d="M8 18c-1.1 0-1.99.9-1.99 2S6.9 22 8 22s2-.9 2-2-.9-2-2-2zM2 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H8.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A1.003 1.003 0 0 0 21 4H6.21l-.94-2H2zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
                        ></path>
                      </svg>
                      Order online (delivery)
                    </div>
                  )}
                  {type == "dispensaries" &&
                    row.has_curbside_pickup == true && (
                      <div
                        data-testid="curbside-pickup"
                        className="text__Text-sc-fif1uk-0 header-info__ListingInfoLine-sc-1heoxjr-2 hniXLZ inutnG"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          className="header-info__StyledCurbsideIcon-sc-1heoxjr-12 butsnV"
                        >
                          <g fill="none">
                            <g stroke="#60646f">
                              <path d="M14 4h9v4a2 2 0 01-2 2h-5a2 2 0 01-2-2V4h0z"></path>
                              <path d="M17 5V2a1 1 0 011-1h1a1 1 0 011 1v3h0"></path>
                            </g>
                            <path
                              d="M2.083 13.388l1.058-2.925h7.756l1.058 2.925H2.083zm9.042 3.862A1.124 1.124 0 0110 16.125c0-.623.503-1.125 1.125-1.125.623 0 1.125.502 1.125 1.125s-.502 1.125-1.125 1.125zm-8.25 0a1.124 1.124 0 01-1.125-1.125c0-.623.503-1.125 1.125-1.125C3.498 15 4 15.502 4 16.125s-.502 1.125-1.125 1.125zm9.315-7.492A1.12 1.12 0 0011.125 9h-8.25c-.494 0-.907.315-1.065.758L.25 14.25v6c0 .413.338.75.75.75h.75c.413 0 .75-.337.75-.75v-.75h9v.75c0 .413.338.75.75.75H13c.413 0 .75-.337.75-.75v-6l-1.56-4.492z"
                              fill="#60646f"
                            ></path>
                          </g>
                        </svg>
                        Curbside pickup
                      </div>
                    )}
                </div>
                <div className="styled-components__TabletContent-sc-1sy7kcj-13 jlHUjc"></div>
                <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 styled-components__Actions-sc-1sy7kcj-11 pb-3 ifUzlM dtEVmG bZmZU">
                  {row.phone != "" && (
                    <a
                      href={"tel:" + row.phone}
                      aria-disabled="false"
                      data-loading="false"
                      className="button__Button-sc-1lygdz3-0 styled-components__ActionButton-sc-1sy7kcj-12 cXCbCb dTJPGC"
                    >
                      {/* <div
                            className="styled-components__TabletContent-sc-1sy7kcj-13 jlHUjc">
                            Call
                        </div> */}
                      <div className="header-info__DesktopButtonContent-sc-1heoxjr-1 fNwzyY">
                        <svg
                          className="wm-icon-phone"
                          width="16px"
                          height="16px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M6.65 10.02c.12.28 2.07 4.94 7.43 7.23l1.7-1.72c.3-.3.75-.4 1.14-.26 1.25.4 2.6.63 3.97.63.61 0 1.11.5 1.11 1.11v3.88c0 .61-.5 1.11-1.11 1.11A18.89 18.89 0 012 3.11C2 2.5 2.5 2 3.11 2H7c.61 0 1.11.5 1.11 1.11 0 1.39.22 2.72.63 3.97.13.39.04.82-.27 1.13l-1.82 1.81z"
                          ></path>
                        </svg>
                        {row.phone}
                      </div>
                    </a>
                  )}
                  {type == "dispensaries" && (
                    <a
                      href={
                        "https://www.google.com/maps/dir/?api=1&destination=" +
                        row.latitude +
                        "," +
                        row.longitude
                      }
                      tabIndex="0"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-disabled="false"
                      data-loading="false"
                      className="button__Button-sc-1lygdz3-0 styled-components__ActionButton-sc-1sy7kcj-12 cXCbCb dTJPGC"
                    >
                      {/* <div
                            className="styled-components__TabletContent-sc-1sy7kcj-13 jlHUjc">
                            Directions
                        </div> */}
                      <div className="header-info__DesktopButtonContent-sc-1heoxjr-1 fNwzyY">
                        <svg
                          className="wm-icon-map"
                          width="14px"
                          height="14px"
                          fill="#999999"
                          viewBox="0 0 20 20"
                        >
                          <g fill="none" fillRule="evenodd">
                            <g
                              transform="translate(-315.000000, -1550.000000)"
                              fill="#999999"
                            >
                              <g transform="translate(10.000000, 1542.000000)">
                                <g transform="translate(275.000000, 0.000000)">
                                  <g transform="translate(30.000000, 8.000000)">
                                    <path d="M14,11.025 C15.975,11.275 17.5,12.95 17.5,15 C17.5,15.65 17.35,16.25 17.075,16.775 L20,18 L20,2.5 L14,0 L14,11.025 L14,11.025 Z"></path>
                                    <path d="M9.5,15 C9.5,12.95 11.025,11.275 13,11.025 L13,0 L7,2.5 L7,18 L9.925,16.775 C9.65,16.25 9.5,15.65 9.5,15 L9.5,15 Z"></path>
                                    <polygon points="6 2.5 0 0 0 15.5 6 18"></polygon>
                                    <path d="M13.5,12 C11.85,12 10.5,13.35 10.5,15 C10.5,15.625 10.7,16.2 11.025,16.7 L13.5,20 L15.975,16.7 C16.3,16.225 16.5,15.625 16.5,15 C16.5,13.35 15.15,12 13.5,12 L13.5,12 Z M13.5,16.5 C12.675,16.5 12,15.825 12,15 C12,14.175 12.675,13.5 13.5,13.5 C14.325,13.5 15,14.175 15,15 C15,15.825 14.325,16.5 13.5,16.5 L13.5,16.5 Z"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                        Directions
                      </div>
                    </a>
                  )}
                  {type == "deliveries" &&
                    row.deliveries_result.email != "" && (
                      <a
                        href={"mailto:" + row.deliveries_result.email}
                        tabIndex="0"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-disabled="false"
                        data-loading="false"
                        className="button__Button-sc-1lygdz3-0 styled-components__ActionButton-sc-1sy7kcj-12 cXCbCb dTJPGC"
                      >
                        {/* <div
                            className="styled-components__TabletContent-sc-1sy7kcj-13 jlHUjc">
                            Directions
                        </div> */}
                        <div className="header-info__DesktopButtonContent-sc-1heoxjr-1 fNwzyY">
                          <svg
                            class="wm-icon-envelope"
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#999999"
                              fillRule="evenodd"
                              d="M4 5h16c1.1 0 2 .79 2 1.75v.88l-10 5.74L2 7.63l.01-.88C2.01 5.79 2.9 5 4 5zM2 9.93v-2.3 2.3zm20 0l-10 5.73L2 9.93v7.32c0 .96.9 1.75 2 1.75h16c1.1 0 2-.79 2-1.75V9.92z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                          Email the retailer
                        </div>
                      </a>
                    )}
                  {/* <div className="styled-components__DesktopContent-sc-1sy7kcj-14 cBnGhI">
                    <div>
                      <button
                        className="circle-button__Wrapper-sc-19k5rjp-1 eHHojs favorite-toggle"
                        aria-label="Favorite RISE Dispensaries Carson City"
                        aria-pressed="false"
                        fdprocessedid="sdjl5"
                      >
                        <div className="circle-button__Circle-sc-19k5rjp-0 hKyozc">
                          <span className="heart-animated__IconWrapper-sc-1o5ptja-0 blAHwo">
                            <svg width="24px" height="24px" viewBox="0 0 24 24">
                              <path
                                fill="#fff"
                                d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z"
                              ></path>
                              <path
                                fill="#60646f"
                                d="M12 4.76a5.94 5.94 0 014.5-2.14A5.5 5.5 0 0122 8.24c0 3.86-3.4 7-8.54 11.77l-.01.01L12 21.37l-1.45-1.34-.04-.04C5.38 15.23 2 12.1 2 8.24a5.5 5.5 0 015.5-5.62c1.74 0 3.41.83 4.5 2.14zm0 13.85l.1-.1c4.76-4.4 7.9-7.32 7.9-10.27 0-2.04-1.5-3.58-3.5-3.58a3.88 3.88 0 00-3.56 2.42h-1.87A3.9 3.9 0 007.5 4.66C5.5 4.66 4 6.2 4 8.24c0 2.95 3.14 5.87 7.9 10.27l.1.1z"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                style={{ opacity: 1 }}
                              ></path>
                            </svg>
                          </span>
                          <div
                            size="40"
                            className="heart-animated__AnimatedCircle-sc-1o5ptja-1 gCSfNI fDtgs"
                          ></div>
                        </div>
                        <svg
                          width="8px"
                          height="8px"
                          viewBox="0 0 24 24"
                          className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                          <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z"
                          ></path>
                        </svg>
                        <svg
                          width="8px"
                          height="8px"
                          viewBox="0 0 24 24"
                          className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                          <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z"
                          ></path>
                        </svg>
                        <svg
                          width="8px"
                          height="8px"
                          viewBox="0 0 24 24"
                          className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                          <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z"
                          ></path>
                        </svg>
                        <svg
                          width="8px"
                          height="8px"
                          viewBox="0 0 24 24"
                          className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                          <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z"
                          ></path>
                        </svg>
                        <svg
                          width="8px"
                          height="8px"
                          viewBox="0 0 24 24"
                          className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                          <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div> */}
                </div>
                {/* {(row.original_web_url != "" && iframe_status == 1) && (
                <div className="pt-3 pb-3 tyDGf">If there is a problem in viewing the site content, <a href={row.original_web_url} className="link" target="_blank">click here</a> to view it in a new browser.</div>
                )} */}
                {row.original_web_url != "" && iframe_status == 1 && (
                  <div className="pt-3 pb-3">
                    {btoa(JSON.stringify(row._id)) ===
                    "IjYzOTMxMWExNGY3Y2JiNzU4ZDcxYmMyMiI=" ? (
                      <a
                        href={row.original_web_url}
                        target="_blank"
                        className="btn btn_orange brdr_rdus m-1"
                      >
                        View Website
                      </a>
                    ) : (
                      ""
                    )}
                    {btoa(JSON.stringify(row._id)) ===
                    "IjYzOTMxMWExNGY3Y2JiNzU4ZDcxYmMyNCI=" ? (
                      <a
                        href={row.original_web_url}
                        target="_blank"
                        className="btn btn_red brdr_rdus m-1"
                      >
                        View Website
                      </a>
                    ) : (
                      ""
                    )}
                    {btoa(JSON.stringify(row._id)) ===
                    "IjYzOTMxMWExNGY3Y2JiNzU4ZDcxYmI4YiI=" ? (
                      <a
                        href={row.original_web_url}
                        target="_blank"
                        className="btn btn_black brdr_rdus m-1"
                      >
                        View Website
                      </a>
                    ) : (
                      ""
                    )}
                    {btoa(JSON.stringify(row._id)) ===
                    "IjYzOTMxMWExNGY3Y2JiNzU4ZDcxYmMxOCI=" ? (
                      <a
                        href={row.original_web_url}
                        target="_blank"
                        className="btn btn_green brdr_rdus m-1"
                      >
                        View Website
                      </a>
                    ) : (
                      ""
                    )}
                    {btoa(JSON.stringify(row._id)) ===
                    "IjYzOTMxMWExNGY3Y2JiNzU4ZDcxYmJkZiI=" ? (
                      <a
                        href={row.original_web_url}
                        target="_blank"
                        className="btn btn_teal brdr_rdus m-1"
                      >
                        View Website
                      </a>
                    ) : (
                      ""
                    )}
                    {btoa(JSON.stringify(row._id)) ===
                    "IjYzOTMwZWE1ZjdiZTQ0NmUyYTBmZDk2ZCI=" ? (
                      <a
                        href={row.original_web_url}
                        target="_blank"
                        className="btn btn_red_shade1 brdr_rdus m-1"
                      >
                        View Website
                      </a>
                    ) : (
                      ""
                    )}
                    {btoa(JSON.stringify(row._id)) ===
                    "IjYzOTMwZWE1ZjdiZTQ0NmUyYTBmZDkyOSI=" ? (
                      <a
                        href={row.original_web_url}
                        target="_blank"
                        className="btn btn_red_shade2 brdr_rdus m-1"
                      >
                        View Website
                      </a>
                    ) : (
                      ""
                    )}
                    {btoa(JSON.stringify(row._id)) ===
                    "IjYzOTMwZWE1ZjdiZTQ0NmUyYTBmZDk4MSI=" ? (
                      <a
                        href={row.original_web_url}
                        target="_blank"
                        className="btn btn_red_shade3 brdr_rdus m-1"
                      >
                        View Website
                      </a>
                    ) : (
                      ""
                    )}
                    {btoa(JSON.stringify(row._id)) ===
                    "IjYzOTMwZWE1ZjdiZTQ0NmUyYTBmZDhmZSI=" ? (
                      <a
                        href={row.original_web_url}
                        target="_blank"
                        className="btn btn_red_shade4 brdr_rdus m-1"
                      >
                        View Website
                      </a>
                    ) : (
                      ""
                    )}
                    {/* <a
                      href={row.original_web_url}
                      className="link"
                      target="_blank"
                    >
                      click here
                    </a>{" "}
                    to view the site in a new browser. */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
}
function CompanyDetail1({ row, iframe_status, type }) {
  // const [activeTab, setActiveTab] = useState("feed");

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };

  return (
    // <div>
    //   <center>
    //     <div
    //       data-testid="avatar-container col-6"
    //       className="avatar__AvatarStyled-sc-1h8176t-0 hwPgVa styled-components__Avatar-sc-1sy7kcj-4 gwbtRb"
    //       size="140"
    //       shape="rounded"
    //     >
    //       <img
    //         alt={row.name}
    //         data-testid="avatar-img"
    //         width="140"
    //         height="140"
    //         fit="crop"
    //         src={row.logo + "&fit=crop&amp;auto=format&amp;w=140&amp;h=140"}
    //         srcSet={
    //           row.logo +
    //           "&w=140&amp;h=140&amp;dpr=1&amp;auto=format&amp;fit=crop 1x, " +
    //           row.logo +
    //           "&w=140&amp;h=140&amp;dpr=2&amp;auto=format&amp;fit=crop 2x"
    //         }
    //       />
    //     </div>

    //     <div
    //       height="100%"
    //       className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 hTpkMG dtEVmG"
    //     >
    //       <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 listing-header___StyledFlex-sc-zgze8e-0 ciTqMI dtEVmG gdgHep col-12">
    //         <div className="styled-components__ListingNameWrapper-sc-1sy7kcj-6 eFiTjJ">
    //           <h1 className="styled-components__ListingName-sc-1sy7kcj-7 fTPmOY">
    //             {row.name}
    //           </h1>
    //         </div>
    //         <div class="card__Card-sc-g1pf5f-0 eemqwd">
    //           <div class="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 hyZISP hzdsvF">
    //             <ul class="menu-links__Section-sc-1l03by3-0 eVIEdL sm_footer_ul">

    //               <li class="menu-links__MenuItem-sc-1l03by3-1 fSdiNi sm_footer_li">
    //                 <a
    //                   href={row.instagram}
    //                   data-testid="social-link"
    //                   target="_blank"
    //                   class="src__Box-sc-1sbtrzs-0 brand-social-links__SocialLink-sc-pohbyq-1 dyjEub cdRXuS"
    //                 >
    //                   <div class="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 brand-social-links__IconWrapper-sc-pohbyq-0 eSHBtX hzdsvF gzcQhI">
    //                     <svg
    //                       class="wm-icon wm-instagram-standard"
    //                       width="20px"
    //                       height="20px"
    //                       viewBox="0 0 24 24"
    //                     >
    //                       <path
    //                         fill="#4A4A4A"
    //                         d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0zm0 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm6.406-11.845a1.44 1.44 0 1 0 0 2.881 1.44 1.44 0 0 0 0-2.881z"
    //                       ></path>
    //                     </svg>
    //                   </div>
    //                   <span>Instagramm</span>
    //                 </a>
    //               </li>

    //               <li class="menu-links__MenuItem-sc-1l03by3-1 fSdiNi sm_footer_li">
    //                 <a
    //                   href={row.facebook}
    //                   data-testid="social-link"
    //                   target="_blank"
    //                   class="src__Box-sc-1sbtrzs-0 brand-social-links__SocialLink-sc-pohbyq-1 dyjEub cdRXuS"
    //                 >
    //                   <div class="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 brand-social-links__IconWrapper-sc-pohbyq-0 eSHBtX hzdsvF gzcQhI">
    //                     <svg
    //                       class="wm-icon-facebook-standard"
    //                       width="20px"
    //                       height="20px"
    //                       viewBox="0 0 24 24"
    //                     >
    //                       <path
    //                         fill="#4A4A4A"
    //                         fillRule="nonzero"
    //                         d="M14.3 8.6V6.8v-.6l.3-.4.4-.3h3V2h-3.3c-1.9 0-3.2.4-4 1.1-.9.8-1.3 2-1.3 3.4v2H7V12h2.4v10h5V12h3.2l.4-3.4h-3.7z"
    //                       ></path>
    //                     </svg>
    //                   </div>
    //                   <span>Facebook</span>
    //                 </a>
    //               </li>
    //               <li class="menu-links__MenuItem-sc-1l03by3-1 fSdiNi sm_footer_li">
    //                 <a
    //                   href={row.twitter}
    //                   data-testid="social-link"
    //                   target="_blank"
    //                   class="src__Box-sc-1sbtrzs-0 brand-social-links__SocialLink-sc-pohbyq-1 dyjEub cdRXuS"
    //                 >
    //                   <div class="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 brand-social-links__IconWrapper-sc-pohbyq-0 eSHBtX hzdsvF gzcQhI">
    //                     <svg
    //                       class="wm-icon-twitter-standard"
    //                       width="20px"
    //                       height="20px"
    //                       viewBox="0 0 21 21"
    //                     >
    //                       <g transform="translate(-1 -2)">
    //                         <path
    //                           fill="#4A4A4A"
    //                           d="M22 6.8l-2.4.6c1-.5 1.5-1.2 1.8-2.1-.8.4-1.6.7-2.6.9a4.1 4.1 0 0 0-3-1.2c-1 0-2 .4-2.9 1.1a3.5 3.5 0 0 0-1 3.6 12.2 12.2 0 0 1-8.5-4 3.5 3.5 0 0 0 0 3.7c.3.6.7 1 1.3 1.4-.7 0-1.3-.2-1.9-.5 0 1 .3 1.7 1 2.4.6.7 1.4 1.2 2.3 1.3a4.6 4.6 0 0 1-1.9.1A4.2 4.2 0 0 0 8 16.7a8.5 8.5 0 0 1-6 1.6 12.9 12.9 0 0 0 10.4 1 10.7 10.7 0 0 0 5.6-4.1 10.6 10.6 0 0 0 2-6.5c.7-.5 1.4-1.2 2-2z"
    //                         ></path>
    //                       </g>
    //                     </svg>
    //                   </div>
    //                   <span>Twitter</span>
    //                 </a>
    //               </li>
    //               <li class="menu-links__MenuItem-sc-1l03by3-1 fSdiNi sm_footer_li">
    //                 <a
    //                   href={row.website}
    //                   data-testid="social-link"
    //                   target="_blank"
    //                   class="src__Box-sc-1sbtrzs-0 brand-social-links__SocialLink-sc-pohbyq-1 dyjEub cdRXuS"
    //                 >
    //                   <div class="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 brand-social-links__IconWrapper-sc-pohbyq-0 eSHBtX hzdsvF gzcQhI">
    //                     <svg
    //                       class="wm-icon-link"
    //                       height="20px"
    //                       width="20px"
    //                       viewBox="0 0 24 24"
    //                     >
    //                       <path
    //                         fill="#4A4A4A"
    //                         d="M9.536 13.15h4.902a1.225 1.225 0 1 0 0-2.452H9.536a1.225 1.225 0 1 0 0 2.451M15.827 6h-1.026a1.2 1.2 0 0 0 0 2.4h1.085c2.003 0 3.71 1.587 3.714 3.59.005 2.045-1.556 3.61-3.6 3.61h-1.2a1.2 1.2 0 1 0 0 2.4H16c3.364 0 6.006-2.646 6-6.012C21.994 8.651 19.164 6 15.827 6M4.4 11.99C4.405 9.988 6.11 8.4 8.114 8.4h1.085a1.2 1.2 0 1 0 0-2.4H8.173C4.836 6 2.006 8.65 2 11.988 1.994 15.354 4.636 18 8 18h1.2a1.2 1.2 0 1 0 0-2.4H8c-2.044 0-3.605-1.565-3.6-3.61"
    //                       ></path>
    //                     </svg>
    //                   </div>
    //                   <span>Website</span>
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </center>
    // </div>

    // new
    // <div>
    //   <center>
    //     <div
    //       data-testid="avatar-container col-6"
    //       className="avatar__AvatarStyled-sc-1h8176t-0 hwPgVa styled-components__Avatar-sc-1sy7kcj-4 gwbtRb"
    //       size="140"
    //       shape="rounded"
    //     >
    //       <img
    //         alt={row.name}
    //         data-testid="avatar-img"
    //         width="140"
    //         height="140"
    //         fit="crop"
    //         src={row.logo + "&fit=crop&amp;auto=format&amp;w=140&amp;h=140"}
    //         srcSet={
    //           row.logo +
    //           "&w=140&amp;h=140&amp;dpr=1&amp;auto=format&amp;fit=crop 1x, " +
    //           row.logo +
    //           "&w=140&amp;h=140&amp;dpr=2&amp;auto=format&amp;fit=crop 2x"
    //         }
    //       />
    //     </div>

    //     <div className="styled-components__ListingNameWrapper-sc-1sy7kcj-6 eFiTjJ mb-4">
    //       <h1 className="styled-components__ListingName-sc-1sy7kcj-7 fTPmOY">
    //         {row.name}
    //       </h1>
    //     </div>

    //   </center>

    //   <ul className="nav nav-pills mb-3 shadow-sm" id="pills-tab" role="tablist">

    //     <li className="nav-item" role="presentation">
    //       <button className={`nav-link ${activeTab === "feed" ? "active" : ""}`} onClick={() => handleTabClick('feed')} id="pills-home-tab" data-bs-toggle="pill" data-toggle="tab" data-bs-target="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Feed</button>
    //     </li>
    //     <li className="nav-item" role="presentation">
    //       <button className={`nav-link ${activeTab === "about" ? "active" : ""}`} onClick={() => handleTabClick('about')} id="pills-profile-tab" data-bs-toggle="pill" data-toggle="tab" data-bs-target="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">About</button>
    //     </li>
    //   </ul>

    //   <div className="tab-content" id="pills-tabContent">
    //     <div className={`tab-pane fade show ${activeTab === "feed" ? "active" : ""
    //       }`}
    //       id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
    //       <div class="row">
    //         <div class="col-md-4 col-lg-4 colsm-12 vh-100">
    //           <div className='card m-4'>
    //             <ul className="list-unstyled p-3">
    //               <li className="pt-3 pb-3">
    //                 {/* <a href="#" >link1</a> */}
    //                 <a
    //                   href={row.instagram}
    //                   data-testid="social-link"
    //                   target="_blank"
    //                   class="src__Box-sc-1sbtrzs-0 brand-social-links__SocialLink-sc-pohbyq-1 dyjEub cdRXuS d-flex"
    //                 >
    //                   <div class="p-2 src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 brand-social-links__IconWrapper-sc-pohbyq-0 eSHBtX hzdsvF gzcQhI">
    //                     <svg
    //                       class="wm-icon wm-instagram-standard"
    //                       width="20px"
    //                       height="20px"
    //                       viewBox="0 0 24 24"
    //                     >
    //                       <path
    //                         fill="#4A4A4A"
    //                         d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0zm0 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm6.406-11.845a1.44 1.44 0 1 0 0 2.881 1.44 1.44 0 0 0 0-2.881z"
    //                       ></path>
    //                     </svg>
    //                   </div>
    //                   <span className="p-2">Instagram</span>
    //                 </a>
    //               </li>
    //               <li className="pt-3 pb-3">
    //                 {/* <a href="#" >link2</a> */}
    //                 <a
    //                   href={row.facebook}
    //                   data-testid="social-link"
    //                   target="_blank"
    //                   class="src__Box-sc-1sbtrzs-0 brand-social-links__SocialLink-sc-pohbyq-1 dyjEub cdRXuS d-flex"
    //                 >
    //                   <div class="p-2 src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 brand-social-links__IconWrapper-sc-pohbyq-0 eSHBtX hzdsvF gzcQhI">
    //                     <svg
    //                       class="wm-icon-facebook-standard"
    //                       width="20px"
    //                       height="20px"
    //                       viewBox="0 0 24 24"
    //                     >
    //                       <path
    //                         fill="#4A4A4A"
    //                         fillRule="nonzero"
    //                         d="M14.3 8.6V6.8v-.6l.3-.4.4-.3h3V2h-3.3c-1.9 0-3.2.4-4 1.1-.9.8-1.3 2-1.3 3.4v2H7V12h2.4v10h5V12h3.2l.4-3.4h-3.7z"
    //                       ></path>
    //                     </svg>
    //                   </div>
    //                   <span className="p-2">Facebook</span>
    //                 </a>
    //               </li>
    //               <li className="pt-3 pb-3">
    //                 {/* <a href="#">link2</a> */}
    //                 <a
    //                   href={row.twitter}
    //                   data-testid="social-link"
    //                   target="_blank"
    //                   class="src__Box-sc-1sbtrzs-0 brand-social-links__SocialLink-sc-pohbyq-1 dyjEub cdRXuS d-flex"
    //                 >
    //                   <div class="p-2 src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 brand-social-links__IconWrapper-sc-pohbyq-0 eSHBtX hzdsvF gzcQhI">
    //                     <svg
    //                       class="wm-icon-twitter-standard"
    //                       width="20px"
    //                       height="20px"
    //                       viewBox="0 0 21 21"
    //                     >
    //                       <g transform="translate(-1 -2)">
    //                         <path
    //                           fill="#4A4A4A"
    //                           d="M22 6.8l-2.4.6c1-.5 1.5-1.2 1.8-2.1-.8.4-1.6.7-2.6.9a4.1 4.1 0 0 0-3-1.2c-1 0-2 .4-2.9 1.1a3.5 3.5 0 0 0-1 3.6 12.2 12.2 0 0 1-8.5-4 3.5 3.5 0 0 0 0 3.7c.3.6.7 1 1.3 1.4-.7 0-1.3-.2-1.9-.5 0 1 .3 1.7 1 2.4.6.7 1.4 1.2 2.3 1.3a4.6 4.6 0 0 1-1.9.1A4.2 4.2 0 0 0 8 16.7a8.5 8.5 0 0 1-6 1.6 12.9 12.9 0 0 0 10.4 1 10.7 10.7 0 0 0 5.6-4.1 10.6 10.6 0 0 0 2-6.5c.7-.5 1.4-1.2 2-2z"
    //                         ></path>
    //                       </g>
    //                     </svg>
    //                   </div>
    //                   <span className="p-2">Twitter</span>
    //                 </a>
    //               </li>
    //               <li className="pt-3 pb-3">
    //                 {/* <a href="#">link2</a> */}
    //                 <a
    //                   href={row.website}
    //                   data-testid="social-link"
    //                   target="_blank"
    //                   class="src__Box-sc-1sbtrzs-0 brand-social-links__SocialLink-sc-pohbyq-1 dyjEub cdRXuS d-flex"
    //                 >
    //                   <div class="p-2 src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 brand-social-links__IconWrapper-sc-pohbyq-0 eSHBtX hzdsvF gzcQhI">
    //                     <svg
    //                       class="wm-icon-link"
    //                       width="20px"
    //                       height="20px"
    //                       viewBox="0 0 21 21"
    //                     >
    //                       <g transform="translate(-1 -2)">
    //                         <path
    //                           fill="#4A4A4A"
    //                           d="M9.536 13.15h4.902a1.225 1.225 0 1 0 0-2.452H9.536a1.225 1.225 0 1 0 0 2.451M15.827 6h-1.026a1.2 1.2 0 0 0 0 2.4h1.085c2.003 0 3.71 1.587 3.714 3.59.005 2.045-1.556 3.61-3.6 3.61h-1.2a1.2 1.2 0 1 0 0 2.4H16c3.364 0 6.006-2.646 6-6.012C21.994 8.651 19.164 6 15.827 6M4.4 11.99C4.405 9.988 6.11 8.4 8.114 8.4h1.085a1.2 1.2 0 1 0 0-2.4H8.173C4.836 6 2.006 8.65 2 11.988 1.994 15.354 4.636 18 8 18h1.2a1.2 1.2 0 1 0 0-2.4H8c-2.044 0-3.605-1.565-3.6-3.61"
    //                         ></path>
    //                       </g>
    //                     </svg>
    //                   </div>
    //                   <span className="p-2">Website</span>
    //                 </a>
    //               </li>
    //               {/* <li className="p-3">
    //             <a
    //               href={row.website}
    //               data-testid="social-link"
    //               target="_blank"
    //               class="src__Box-sc-1sbtrzs-0 brand-social-links__SocialLink-sc-pohbyq-1 dyjEub cdRXuS d-flex"
    //             >
    //               <div class="p-2 src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 brand-social-links__IconWrapper-sc-pohbyq-0 eSHBtX hzdsvF gzcQhI">
    //                 <svg
    //                   class="wm-icon-link"
    //                   height="20px"
    //                   width="20px"
    //                   viewBox="0 0 24 24"
    //                 >
    //                   <path
    //                     fill="#4A4A4A"
    //                     d="M9.536 13.15h4.902a1.225 1.225 0 1 0 0-2.452H9.536a1.225 1.225 0 1 0 0 2.451M15.827 6h-1.026a1.2 1.2 0 0 0 0 2.4h1.085c2.003 0 3.71 1.587 3.714 3.59.005 2.045-1.556 3.61-3.6 3.61h-1.2a1.2 1.2 0 1 0 0 2.4H16c3.364 0 6.006-2.646 6-6.012C21.994 8.651 19.164 6 15.827 6M4.4 11.99C4.405 9.988 6.11 8.4 8.114 8.4h1.085a1.2 1.2 0 1 0 0-2.4H8.173C4.836 6 2.006 8.65 2 11.988 1.994 15.354 4.636 18 8 18h1.2a1.2 1.2 0 1 0 0-2.4H8c-2.044 0-3.605-1.565-3.6-3.61"
    //                   ></path>
    //                 </svg>
    //               </div>
    //               <span className="p-2">Website</span>
    //             </a>
    //           </li> */}
    //             </ul>
    //           </div>
    //         </div>
    //       </div>

    //     </div>

    //     <div className={`tab-pane fade show ${activeTab === "about" ? "active" : ""
    //       }`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
    //       <div className="container vh-100">
    //         <div className="mb-2">
    //           <h2>About Us</h2>
    //         </div>
    //         <div>
    //           <p>{row.about}</p>
    //         </div>

    //       </div>
    //     </div>

    //   </div>
    // </div>

    <>
      <center className="min-vh-100">
        <div
          data-testid="avatar-container col-6"
          className="avatar__AvatarStyled-sc-1h8176t-0 hwPgVa styled-components__Avatar-sc-1sy7kcj-4 gwbtRb"
          size="140"
          shape="rounded"
        >
          <img
            alt={row.name}
            data-testid="avatar-img"
            width="140"
            height="140"
            fit="crop"
            src={
              imagechanges(row.logo) +
              "&fit=crop&amp;auto=format&amp;w=140&amp;h=140"
            }
            srcSet={
              row.logo +
              "&w=140&amp;h=140&amp;dpr=1&amp;auto=format&amp;fit=crop 1x, " +
              row.logo +
              "&w=140&amp;h=140&amp;dpr=2&amp;auto=format&amp;fit=crop 2x"
            }
          />
        </div>

        <div className="styled-components__ListingNameWrapper-sc-1sy7kcj-6 mb-4">
          <h1 className="styled-components__ListingName-sc-1sy7kcj-7 fTPmOY">
            {row.name}
          </h1>
          <div className="about-feed-alignment mt-4">
            {row.about && (
              <div class="card-design">
                <div className="mt-5 mb-5 w-75">
                  <h2 className="mb-4">About</h2>
                  <p className="paragraph_spacing">{row.about}</p>
                </div>
              </div>
            )}

            {(row.instagram || row.facebook || row.twitter || row.website) && (
              <div class="mt-5 mb-5">
                <h2 className="mb-4">Feed</h2>
                <div class="row">
                  <div class="col-md-4 col-lg-4 colsm-12">
                    <ul className="list-unstyled">
                      {row.instagram && (
                        <li className="pt-1 pb-1">
                          {/* <a href="#" >link1</a> */}
                          <a
                            href={row.instagram}
                            data-testid="social-link"
                            target="_blank"
                            class="src__Box-sc-1sbtrzs-0 brand-social-links__SocialLink-sc-pohbyq-1 dyjEub cdRXuS d-flex"
                          >
                            <div class="p-2 src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 brand-social-links__IconWrapper-sc-pohbyq-0 eSHBtX hzdsvF gzcQhI">
                              <svg
                                class="wm-icon wm-instagram-standard"
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#4A4A4A"
                                  d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0zm0 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm6.406-11.845a1.44 1.44 0 1 0 0 2.881 1.44 1.44 0 0 0 0-2.881z"
                                ></path>
                              </svg>
                            </div>
                            <span className="p-2">Instagram</span>
                          </a>
                        </li>
                      )}
                      {row.facebook && (
                        <li className="pt-1 pb-1">
                          {/* <a href="#" >link2</a> */}
                          <a
                            href={row.facebook}
                            data-testid="social-link"
                            target="_blank"
                            class="src__Box-sc-1sbtrzs-0 brand-social-links__SocialLink-sc-pohbyq-1 dyjEub cdRXuS d-flex"
                          >
                            <div class="p-2 src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 brand-social-links__IconWrapper-sc-pohbyq-0 eSHBtX hzdsvF gzcQhI">
                              <svg
                                class="wm-icon-facebook-standard"
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#4A4A4A"
                                  fillRule="nonzero"
                                  d="M14.3 8.6V6.8v-.6l.3-.4.4-.3h3V2h-3.3c-1.9 0-3.2.4-4 1.1-.9.8-1.3 2-1.3 3.4v2H7V12h2.4v10h5V12h3.2l.4-3.4h-3.7z"
                                ></path>
                              </svg>
                            </div>
                            <span className="p-2">Facebook</span>
                          </a>
                        </li>
                      )}

                      {row.twitter && (
                        <li className="pt-1 pb-1">
                          {/* <a href="#">link2</a> */}
                          <a
                            href={row.twitter}
                            data-testid="social-link"
                            target="_blank"
                            class="src__Box-sc-1sbtrzs-0 brand-social-links__SocialLink-sc-pohbyq-1 dyjEub cdRXuS d-flex"
                          >
                            <div class="p-2 src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 brand-social-links__IconWrapper-sc-pohbyq-0 eSHBtX hzdsvF gzcQhI">
                              <svg
                                class="wm-icon-twitter-standard"
                                width="20px"
                                height="20px"
                                viewBox="0 0 21 21"
                              >
                                <g transform="translate(-1 -2)">
                                  <path
                                    fill="#4A4A4A"
                                    d="M22 6.8l-2.4.6c1-.5 1.5-1.2 1.8-2.1-.8.4-1.6.7-2.6.9a4.1 4.1 0 0 0-3-1.2c-1 0-2 .4-2.9 1.1a3.5 3.5 0 0 0-1 3.6 12.2 12.2 0 0 1-8.5-4 3.5 3.5 0 0 0 0 3.7c.3.6.7 1 1.3 1.4-.7 0-1.3-.2-1.9-.5 0 1 .3 1.7 1 2.4.6.7 1.4 1.2 2.3 1.3a4.6 4.6 0 0 1-1.9.1A4.2 4.2 0 0 0 8 16.7a8.5 8.5 0 0 1-6 1.6 12.9 12.9 0 0 0 10.4 1 10.7 10.7 0 0 0 5.6-4.1 10.6 10.6 0 0 0 2-6.5c.7-.5 1.4-1.2 2-2z"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <span className="p-2">Twitter</span>
                          </a>
                        </li>
                      )}

                      {row.website && (
                        <li className="pt-1 pb-1">
                          {/* <a href="#">link2</a> */}
                          <a
                            href={row.website}
                            data-testid="social-link"
                            target="_blank"
                            class="src__Box-sc-1sbtrzs-0 brand-social-links__SocialLink-sc-pohbyq-1 dyjEub cdRXuS d-flex"
                          >
                            <div class="p-2 src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 brand-social-links__IconWrapper-sc-pohbyq-0 eSHBtX hzdsvF gzcQhI">
                              <svg
                                class="wm-icon-link"
                                width="20px"
                                height="20px"
                                viewBox="0 0 21 21"
                              >
                                <g transform="translate(-1 -2)">
                                  <path
                                    fill="#4A4A4A"
                                    d="M9.536 13.15h4.902a1.225 1.225 0 1 0 0-2.452H9.536a1.225 1.225 0 1 0 0 2.451M15.827 6h-1.026a1.2 1.2 0 0 0 0 2.4h1.085c2.003 0 3.71 1.587 3.714 3.59.005 2.045-1.556 3.61-3.6 3.61h-1.2a1.2 1.2 0 1 0 0 2.4H16c3.364 0 6.006-2.646 6-6.012C21.994 8.651 19.164 6 15.827 6M4.4 11.99C4.405 9.988 6.11 8.4 8.114 8.4h1.085a1.2 1.2 0 1 0 0-2.4H8.173C4.836 6 2.006 8.65 2 11.988 1.994 15.354 4.636 18 8 18h1.2a1.2 1.2 0 1 0 0-2.4H8c-2.044 0-3.605-1.565-3.6-3.61"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <span className="p-2">Website</span>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </center>
    </>
  );
}
function DealsDetail({ row, iframe_status, type }) {
  return (
    <div>
      <center>
        <div>
          <img
            alt={row.name}
            data-testid="avatar-img"
            // width="140"
            // height="140"
            // fit="crop"
            class="w_35_per"
            src={imagechanges(row.logo)}
          />
        </div>

        <div
          height="100%"
          className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 hTpkMG dtEVmG"
        >
          <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 listing-header___StyledFlex-sc-zgze8e-0 ciTqMI dtEVmG gdgHep col-12">
            <div className="styled-components__ListingNameWrapper-sc-1sy7kcj-6 eFiTjJ">
              <h1 className="styled-components__ListingName-sc-1sy7kcj-7 fTPmOY">
                {row.name}
              </h1>
            </div>
            <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 ekVVVv dtEVmG d-inline-block">
              <span className="styled-components__LocationText-sc-1sy7kcj-9 blmfrU">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </span>{" "}
              <span>
                {row.city.city_name},{row.state.state_name},{" "}
                {row.country.country_name}
              </span>
            </div>
            <div
              display="block"
              className="src__Box-sc-1sbtrzs-0 sc-bczRLJ cPobsX"
            >
              <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 ciTqMI dtEVmG align-items-center">
                <div className="header-info__Columns-sc-1heoxjr-0 eBJiAt">
                  {type == "deals" && (
                    <div
                      data-testid="listing-type"
                      className="text__Text-sc-fif1uk-0 header-info__ListingInfoLine-sc-1heoxjr-2 hniXLZ inutnG"
                    >
                      <svg
                        class="header-info__StyledDeliveryIcon-sc-1heoxjr-10 kBZlzo"
                        width="16px"
                        height="16px"
                        fill="#60646f"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          clip-rule="evenodd"
                          d="M17.5 4c.7 0 1.2.4 1.4 1l2.1 6v8c0 .6-.4 1-1 1h-1a1 1 0 0 1-1-1v-1H6v1c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1v-8l2-6c.3-.6.8-1 1.5-1h11ZM6.9 6 5.4 9.9h13.2l-1.4-4H6.9Zm9.1 7.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0ZM6.5 15a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
                        ></path>
                      </svg>{" "}
                      Delivery Only
                    </div>
                  )}
                  <div
                    data-testid="license-type"
                    className="text__Text-sc-fif1uk-0 header-info__ListingInfoLine-sc-1heoxjr-2 hniXLZ inutnG"
                  >
                    <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 iSbMbQ dtEVmG">
                      <svg
                        width="16"
                        data-testid="solid-id"
                        height="16"
                        viewBox="0 0 16 16"
                        className="header-info__StyledIdIcon-sc-1heoxjr-7 irCYFT"
                      >
                        <path
                          d="M13.25 3c.966 0 1.75.784 1.75 1.75v6.125a1.75 1.75 0 01-1.75 1.75H2.75A1.75 1.75 0 011 10.875V4.75C1 3.784 1.784 3 2.75 3h10.5zm-1.313 6.125h-3.5a.437.437 0 100 .875h3.5a.437.437 0 100-.875zm-5.687-3.5H4.5a.875.875 0 00-.875.875v1.75c0 .483.392.875.875.875h1.75a.875.875 0 00.875-.875V6.5a.875.875 0 00-.875-.875zm5.688 1.75h-3.5a.437.437 0 100 .875h3.5a.437.437 0 100-.875zm0-1.75h-3.5a.437.437 0 100 .875h3.5a.437.437 0 100-.875z"
                          fill="#60646f"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                      Medical &amp; recreational
                      {/* <span className="header-tooltip__TooltipWrapper-sc-1rjsmt3-0 iNsVeG">
                        <div>
                          <button
                            data-testid="info-button"
                            type="button"
                            aria-haspopup="dialog"
                            aria-expanded="false"
                            aria-controls="radix-20"
                            data-state="closed"
                            className="tooltip-with-info-button__TriggerButton-sc-1gec0wn-0 fXyGpt"
                            fdprocessedid="vu95ed"
                          >
                            <span className="screen-reader-text__ScreenReaderText-sc-1qiv5xg-0 fEaXzQ">
                              Info
                            </span>
                            <svg
                              width="12px"
                              height="12px"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <circle
                                cx="7"
                                cy="7"
                                r="6"
                                fill="transparent"
                              ></circle>
                              <path
                                d="M7 .333a6.667 6.667 0 110 13.334A6.667 6.667 0 017 .333zm0 1.334a5.333 5.333 0 100 10.666A5.333 5.333 0 007 1.667zm0 4.482c.368 0 .667.298.667.666v2.852a.667.667 0 01-1.334 0V6.815c0-.368.299-.666.667-.666zm0-2.482A.667.667 0 117 5a.667.667 0 010-1.333z"
                                fill="#a5a9b1"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </span> */}
                    </div>
                  </div>
                  <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 ciTqMI dtEVmG align-items-center">
                    <div className="header-info__Columns-sc-1heoxjr-0 eBJiAt">
                      <div
                        data-testid="service-type"
                        className="text__Text-sc-fif1uk-0 header-info__ListingInfoLine-sc-1heoxjr-2 hniXLZ inutnG"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="header-info__StyledCartIcon-sc-1heoxjr-8 khzMEu"
                          width="16px"
                          height="16px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#60646f"
                            d="M8 18c-1.1 0-1.99.9-1.99 2S6.9 22 8 22s2-.9 2-2-.9-2-2-2zM2 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H8.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A1.003 1.003 0 0 0 21 4H6.21l-.94-2H2zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
                          ></path>
                        </svg>
                        Order online
                      </div>
                    </div>
                  </div>
                </div>
                <div className="styled-components__TabletContent-sc-1sy7kcj-13 jlHUjc"></div>

                <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 styled-components__Actions-sc-1sy7kcj-11 pb-3 ifUzlM dtEVmG bZmZU">
                  {/* {row.phone != "" && ( */}
                  <a
                    // href={"tel:" + "(669)%20230-3695"}
                    href={"tel:" + row.phone}
                    aria-disabled="false"
                    data-loading="false"
                    className="button__Button-sc-1lygdz3-0 styled-components__ActionButton-sc-1sy7kcj-12 cXCbCb dTJPGC"
                  >
                    {/* <div
                            className="styled-components__TabletContent-sc-1sy7kcj-13 jlHUjc">
                            Call
                        </div> */}
                    <div className="header-info__DesktopButtonContent-sc-1heoxjr-1 fNwzyY">
                      <svg
                        className="wm-icon-phone"
                        width="16px"
                        height="16px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M6.65 10.02c.12.28 2.07 4.94 7.43 7.23l1.7-1.72c.3-.3.75-.4 1.14-.26 1.25.4 2.6.63 3.97.63.61 0 1.11.5 1.11 1.11v3.88c0 .61-.5 1.11-1.11 1.11A18.89 18.89 0 012 3.11C2 2.5 2.5 2 3.11 2H7c.61 0 1.11.5 1.11 1.11 0 1.39.22 2.72.63 3.97.13.39.04.82-.27 1.13l-1.82 1.81z"
                        ></path>
                      </svg>
                      {phoneFormatter.format(row.phone, "NNN-NNN-NNNN")}
                    </div>
                  </a>
                  {/* )} */}
                  {/* {type == "dispensaries" && ( */}
                  {/* <a
                    href={
                      "https://www.google.com/maps/dir/?api=1&destination=" +
                      row.latitude +
                      "," +
                      row.longitude
                    }
                    tabIndex="0"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-disabled="false"
                    data-loading="false"
                    className="button__Button-sc-1lygdz3-0 styled-components__ActionButton-sc-1sy7kcj-12 cXCbCb dTJPGC"
                  >
                    
                    <div className="header-info__DesktopButtonContent-sc-1heoxjr-1 fNwzyY">
                      <svg
                        className="wm-icon-map"
                        width="14px"
                        height="14px"
                        fill="#999999"
                        viewBox="0 0 20 20"
                      >
                        <g fill="none" fillRule="evenodd">
                          <g
                            transform="translate(-315.000000, -1550.000000)"
                            fill="#999999"
                          >
                            <g transform="translate(10.000000, 1542.000000)">
                              <g transform="translate(275.000000, 0.000000)">
                                <g transform="translate(30.000000, 8.000000)">
                                  <path d="M14,11.025 C15.975,11.275 17.5,12.95 17.5,15 C17.5,15.65 17.35,16.25 17.075,16.775 L20,18 L20,2.5 L14,0 L14,11.025 L14,11.025 Z"></path>
                                  <path d="M9.5,15 C9.5,12.95 11.025,11.275 13,11.025 L13,0 L7,2.5 L7,18 L9.925,16.775 C9.65,16.25 9.5,15.65 9.5,15 L9.5,15 Z"></path>
                                  <polygon points="6 2.5 0 0 0 15.5 6 18"></polygon>
                                  <path d="M13.5,12 C11.85,12 10.5,13.35 10.5,15 C10.5,15.625 10.7,16.2 11.025,16.7 L13.5,20 L15.975,16.7 C16.3,16.225 16.5,15.625 16.5,15 C16.5,13.35 15.15,12 13.5,12 L13.5,12 Z M13.5,16.5 C12.675,16.5 12,15.825 12,15 C12,14.175 12.675,13.5 13.5,13.5 C14.325,13.5 15,14.175 15,15 C15,15.825 14.325,16.5 13.5,16.5 L13.5,16.5 Z"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      Directions
                    </div>
                  </a> */}
                  {/* )}  */}
                  {/* {type == "deals" && row.deliveries_result.email != "" && ( */}
                  {/* <a
                    // href={"mailto:" + row.deliveries_result.email}
                    href=""
                    tabIndex="0"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-disabled="false"
                    data-loading="false"
                    className="button__Button-sc-1lygdz3-0 styled-components__ActionButton-sc-1sy7kcj-12 cXCbCb dTJPGC"
                  >
                    
                    <div className="header-info__DesktopButtonContent-sc-1heoxjr-1 fNwzyY">
                      <svg
                        class="wm-icon-envelope"
                        width="16px"
                        height="16px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#999999"
                          fillRule="evenodd"
                          d="M4 5h16c1.1 0 2 .79 2 1.75v.88l-10 5.74L2 7.63l.01-.88C2.01 5.79 2.9 5 4 5zM2 9.93v-2.3 2.3zm20 0l-10 5.73L2 9.93v7.32c0 .96.9 1.75 2 1.75h16c1.1 0 2-.79 2-1.75V9.92z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      Email the retailer
                    </div>
                  </a> */}
                  {/*  )} */}
                  {/* <div className="styled-components__DesktopContent-sc-1sy7kcj-14 cBnGhI">
                    <div>
                      <button
                        className="circle-button__Wrapper-sc-19k5rjp-1 eHHojs favorite-toggle"
                        aria-label="Favorite RISE Dispensaries Carson City"
                        aria-pressed="false"
                        fdprocessedid="sdjl5"
                      >
                        <div className="circle-button__Circle-sc-19k5rjp-0 hKyozc">
                          <span className="heart-animated__IconWrapper-sc-1o5ptja-0 blAHwo">
                            <svg width="24px" height="24px" viewBox="0 0 24 24">
                              <path
                                fill="#fff"
                                d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z"
                              ></path>
                              <path
                                fill="#60646f"
                                d="M12 4.76a5.94 5.94 0 014.5-2.14A5.5 5.5 0 0122 8.24c0 3.86-3.4 7-8.54 11.77l-.01.01L12 21.37l-1.45-1.34-.04-.04C5.38 15.23 2 12.1 2 8.24a5.5 5.5 0 015.5-5.62c1.74 0 3.41.83 4.5 2.14zm0 13.85l.1-.1c4.76-4.4 7.9-7.32 7.9-10.27 0-2.04-1.5-3.58-3.5-3.58a3.88 3.88 0 00-3.56 2.42h-1.87A3.9 3.9 0 007.5 4.66C5.5 4.66 4 6.2 4 8.24c0 2.95 3.14 5.87 7.9 10.27l.1.1z"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                style={{ opacity: 1 }}
                              ></path>
                            </svg>
                          </span>
                          <div
                            size="40"
                            className="heart-animated__AnimatedCircle-sc-1o5ptja-1 gCSfNI fDtgs"
                          ></div>
                        </div>
                        <svg
                          width="8px"
                          height="8px"
                          viewBox="0 0 24 24"
                          className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                          <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z"
                          ></path>
                        </svg>
                        <svg
                          width="8px"
                          height="8px"
                          viewBox="0 0 24 24"
                          className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                          <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z"
                          ></path>
                        </svg>
                        <svg
                          width="8px"
                          height="8px"
                          viewBox="0 0 24 24"
                          className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                          <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z"
                          ></path>
                        </svg>
                        <svg
                          width="8px"
                          height="8px"
                          viewBox="0 0 24 24"
                          className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                          <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z"
                          ></path>
                        </svg>
                        <svg
                          width="8px"
                          height="8px"
                          viewBox="0 0 24 24"
                          className="heart-animated__FlyHeart-sc-1o5ptja-2 gnbIPX"
                        >
                          <path
                            fill="#ff585d"
                            d="M16.5 2.62c-1.74 0-3.41.83-4.5 2.14a5.94 5.94 0 00-4.5-2.14A5.5 5.5 0 002 8.24c0 3.86 3.4 7 8.55 11.8L12 21.36l1.45-1.35C18.6 15.25 22 12.1 22 8.24a5.5 5.5 0 00-5.5-5.62z"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>

              {/* <div className="m-3">
                  <section className="mt-2 mb-2">
                    Deals are valid for a limited time only. Weedmaps reserves
                    the right to modify or cancel deals at any time. The deal
                    applies only to qualifying items displaying the deal offer
                    on the item detail page. The offer will not be valid until
                    it is applied to the qualifying item. The promotion is
                    limited to one deal per customer. If you return any of the
                    items ordered with a deal, the deal discount or value may be
                    subtracted from the return credit. Offer good while supplies
                    last. Void where prohibited. Weedmaps has no obligation for
                    payment of any tax in conjunction with the distribution or
                    use of any deal. Consumer is required to pay any applicable
                    sales tax related to the use of the deal. Deals are void if
                    restricted or prohibited by law.
                  </section>
                </div> */}
            </div>
          </div>
        </div>
      </center>
    </div>
  );
}
function BlogsDetail({ row, iframe_status, type }) {
  const ReactHtml = (data) => {
    const htmlContent = data;

    const divElement = document.createElement("div");
    divElement.innerHTML = htmlContent;
    const textContent = divElement.innerText;

    return <div>{textContent}</div>;
  };
  const renderEditorContent = (editorContent) => {
    return { __html: editorContent };
  };

  return (
    <div>
      {/* <center> */}
      {/* <div>{row.title}</div> */}

      <div
        height="100%"
        className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 hTpkMG dtEVmG"
      >
        <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 listing-header___StyledFlex-sc-zgze8e-0 ciTqMI dtEVmG gdgHep col-12">
          <div className="styled-components__ListingNameWrapper-sc-1sy7kcj-6 eFiTjJ">
            <h1 className="styled-components__ListingName-sc-1sy7kcj-7 fTPmOY">
              {row.title}
            </h1>
          </div>
          <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 ekVVVv dtEVmG d-inline-block">
            {/* <span className="styled-components__LocationText-sc-1sy7kcj-9 blmfrU">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </span>{" "} */}
            {/* <span>{ReactHtml(row.description)}</span> */}
            <div
              dangerouslySetInnerHTML={renderEditorContent(row.description)}
            />
          </div>
        </div>
      </div>
      {/* </center> */}
    </div>
  );
}
export {
  DispensaryCarsoulList,
  DeliveryCarsoulList,
  BrandsCarsoulList,
  DispensaryAPI,
  DeliveryAPI,
  BrandAPI,
  fontIcons,
  ShowDetail,
};

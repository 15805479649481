import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import bannerImg from "../assets/images_/p5.png";
import samImg from "../assets/images_/products/default_blog_image.png";
import Carousel from "react-multi-carousel";
// import Carousel from "better-react-carousel";
import "react-multi-carousel/lib/styles.css";
import "./additionalCSS.css";
import { ReactSession } from "react-client-session";
import { ShowDetail } from "./commonComp";
import MediaComponent from "./mediafile";
import nav1 from "../assets/img/product/details/nav/product-details-nav-1.jpg";
import nav2 from "../assets/img/product/details/nav/product-details-nav-2.jpg";
import nav3 from "../assets/img/product/details/nav/product-details-nav-3.jpg";
import nav4 from "../assets/img/product/details/nav/product-details-nav-4.jpg";
import nav5 from "../assets/img/product/details/nav/product-details-nav-5.jpg";
import productsmainpage from "../assets/img/product/details/main/product-details-main-1.jpg";
import paymentOption from "../assets/img/product/icons/payment-option.png";
import productRelated from "../assets/img/product/related/product-related-1.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import pdflogo from "../assets/images/hella-lit-logo.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Tween } from "jquery";
function Profile() {
  console.log("console");
  const navigate = useNavigate();

  // const [user, setUser] = useState([]);

  const [user_id, setUser_id] = useState(
    window.localStorage.getItem("user_id")
      ? window.localStorage.getItem("user_id")
      : ""
  );

  const [user, setUser] = useState({
    email_address: "",
    facebook: "",
    twitter: "",
    phone_number: "",
    gender: "",
    address: "",
    bio: "",
    first_name: "",
  });

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [orderhistory, setOrderhistory] = useState([]);
  const [showInvoice, setInvoice] = useState(false);
  const [invoicevalue, setInvoicevalue] = useState(false);
  const [cartlist, setCartList] = useState([]);
  const [notify, setnotify] = useState("");

  useEffect(() => {
    if (!user_id) {
      navigate("/login");
    }
  }, [navigate, user_id]);

  function UserInfo() {
    axios
      .get(process.env.REACT_APP_API_URL + "user/info?user_id=" + user_id)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          console.log("suceess");
          setUser(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    UserInfo();
  }, []);
  const handleChangefirst = (e) => {
    setUser((prevUser) => ({
      ...prevUser,
      first_name: e.target.value,
    }));
  };

  const handleChangefacebook = (e) => {
    setUser((prevUser) => ({
      ...prevUser,
      facebook: e.target.value,
    }));
  };

  const handleChangetwitter = (e) => {
    setUser((prevUser) => ({
      ...prevUser,
      twitter: e.target.value,
    }));
  };
  const handleChangephone = (e) => {
    setUser((prevUser) => ({
      ...prevUser,
      phone_number: e.target.value,
    }));
  };
  const handleChangegender = (e) => {
    setUser((prevUser) => ({
      ...prevUser,
      gender: e.target.value,
    }));
  };
  const handleChangebio = (e) => {
    setUser((prevUser) => ({
      ...prevUser,
      bio: e.target.value,
    }));
  };
  const handleChangeaddress = (e) => {
    setUser((prevUser) => ({
      ...prevUser,
      address: e.target.value,
    }));
  };

  function handleUpdateProfile() {
    const file_detailsd = new FormData();
    if (user_id) {
      file_detailsd.append("user_id", user_id);
      file_detailsd.append("first_name", user.first_name);
      file_detailsd.append("facebook", user.facebook);
      file_detailsd.append("phone_number", user.phone_number);
      file_detailsd.append("twitter", user.twitter);
      file_detailsd.append("bio", user.bio);
      file_detailsd.append("address", user.address);
      file_detailsd.append("setup", "name");
      axios
        .post(process.env.REACT_APP_API_URL + "user/update", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status === "success") {
            toast.success(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const handleUpdatePwd = (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      toast.error("New passwords and confirm password don't match");
      return;
    }
    if (user_id && newPassword === confirmNewPassword) {
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("password", oldPassword);
      formData.append("new_password", newPassword);
      formData.append("setup", "password");

      axios
        .post(process.env.REACT_APP_API_URL + "user/update", formData)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status === "success") {
            toast.success(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  function getProductsorderhistory() {
    if (user_id.length === 5) {
      navigate("/login");
    } else {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "products/order/history?user_id=" +
            window.localStorage.getItem("user_id")
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === "success") {
            console.log("suceess", res.data.result.data);
            setOrderhistory(res.data.result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  useEffect(() => {
    getProductsorderhistory();
  }, []);

  const handleClickInvoice = (row) => {
    setInvoice(true);
    setInvoicevalue(row);
  };
  function getProductscartList() {
    // if (user_id.length === 5) {
    //   navigate("/login");
    // } else {
    var url;
    if (user_id) {
      url =
        process.env.REACT_APP_API_URL +
        "products/wish/list?user_id=" +
        window.localStorage.getItem("user_id");
    }
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setCartList(res.data.result.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  }

  useEffect(() => {
    getProductscartList();
  }, []);

  function getNotification() {
    // if (user_id.length === 5) {
    //   navigate("/login");
    // } else {
    var url;
    if (user_id) {
      url =
        process.env.REACT_APP_API_URL +
        "notification/count?user_id=" +
        window.localStorage.getItem("user_id");
    }
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess", res.data.result);
          setnotify(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  }

  useEffect(() => {
    getNotification();
  }, []);
  return (
    <>
      <main>
        {/* profile area start */}
        <section className="profile__area pt-120 pb-120">
          <div className="container">
            <div className="profile__inner p-relative">
              <div className="row">
                <div className="col-xxl-4 col-lg-4">
                  <div className="profile__tab mr-40">
                    <nav>
                      <div
                        className="nav nav-tabs tp-tab-menu flex-column"
                        id="profile-tab"
                        role="tablist"
                      >
                        <button
                          className="nav-link active"
                          id="nav-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-profile"
                          type="button"
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="false"
                        >
                          <span>
                            <i className="fa-regular fa-user-pen" />
                          </span>
                          Profile
                        </button>
                        <button
                          className="nav-link"
                          id="nav-information-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-information"
                          type="button"
                          role="tab"
                          aria-controls="nav-information"
                          aria-selected="false"
                        >
                          <span>
                            <i className="fa-regular fa-circle-info" />
                          </span>{" "}
                          Information
                        </button>
                        <button
                          className="nav-link"
                          id="nav-address-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-address"
                          type="button"
                          role="tab"
                          aria-controls="nav-address"
                          aria-selected="false"
                        >
                          <span>
                            <i className="fa-light fa-location-dot" />
                          </span>{" "}
                          Address{" "}
                        </button>
                        <button
                          className="nav-link"
                          id="nav-order-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-order"
                          type="button"
                          role="tab"
                          aria-controls="nav-order"
                          aria-selected="false"
                        >
                          <span>
                            <i className="fa-light fa-clipboard-list-check" />
                          </span>{" "}
                          My Orders{" "}
                        </button>
                        <button
                          className="nav-link"
                          id="nav-notification-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-notification"
                          type="button"
                          role="tab"
                          aria-controls="nav-notification"
                          aria-selected="false"
                        >
                          <span>
                            <i className="fa-regular fa-bell" />
                          </span>{" "}
                          Notification
                        </button>
                        <button
                          className="nav-link"
                          id="nav-password-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-password"
                          type="button"
                          role="tab"
                          aria-controls="nav-password"
                          aria-selected="false"
                        >
                          <span>
                            <i className="fa-regular fa-lock" />
                          </span>{" "}
                          Change Password
                        </button>
                        <span
                          id="marker-vertical"
                          className="tp-tab-line d-none d-sm-inline-block"
                        />
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="col-xxl-8 col-lg-8">
                  <div className="profile__tab-content">
                    <div className="tab-content" id="profile-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="nav-profile"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                      >
                        <div className="profile__main">
                          <div className="profile__main-top pb-80">
                            <div className="row align-items-center">
                              <div className="col-md-6">
                                <div className="profile__main-inner d-flex flex-wrap align-items-center">
                                  <div className="profile__main-thumb">
                                    <img src="assets/img/no_image.png" alt />
                                    <div className="profile__main-thumb-edit">
                                      <input
                                        id="profile-thumb-input"
                                        className="profile-img-popup"
                                        type="file"
                                      />
                                      <label htmlFor="profile-thumb-input">
                                        <i className="fa-light fa-camera" />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="profile__main-content">
                                    <h4 className="profile__main-title">
                                      Welcome Mr. {user.first_name}!
                                    </h4>
                                    <p>
                                      You have <span>{notify}</span>{" "}
                                      notifications
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="profile__main-logout text-sm-end">
                                  <a
                                    href="login.html"
                                    className="tp-logout-btn"
                                  >
                                    Logout
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="profile__main-info">
                            <div className="row gx-3">
                              <div className="col-md-3 col-sm-6">
                                <a href="order.html">
                                  <div className="profile__main-info-item">
                                    <div className="profile__main-info-icon">
                                      <span>
                                        <span className="profile-icon-count profile-order">
                                          5
                                        </span>
                                        <svg viewBox="0 0 512 512">
                                          <path d="M472.916,224H448.007a24.534,24.534,0,0,0-23.417-18H398V140.976a6.86,6.86,0,0,0-3.346-6.062L207.077,26.572a6.927,6.927,0,0,0-6.962,0L12.48,134.914A6.981,6.981,0,0,0,9,140.976V357.661a7,7,0,0,0,3.5,6.062L200.154,472.065a7,7,0,0,0,3.5.938,7.361,7.361,0,0,0,3.6-.938L306,415.108v41.174A29.642,29.642,0,0,0,335.891,486H472.916A29.807,29.807,0,0,0,503,456.282v-202.1A30.2,30.2,0,0,0,472.916,224Zm-48.077-4A10.161,10.161,0,0,1,435,230.161v.678A10.161,10.161,0,0,1,424.839,241H384.161A10.161,10.161,0,0,1,374,230.839v-.678A10.161,10.161,0,0,1,384.161,220ZM203.654,40.717l77.974,45.018L107.986,185.987,30.013,140.969ZM197,453.878,23,353.619V153.085L197,253.344Zm6.654-212.658-81.668-47.151L295.628,93.818,377.3,140.969ZM306,254.182V398.943l-95,54.935V253.344L384,153.085V206h.217A24.533,24.533,0,0,0,360.8,224H335.891A30.037,30.037,0,0,0,306,254.182Zm183,202.1A15.793,15.793,0,0,1,472.916,472H335.891A15.628,15.628,0,0,1,320,456.282v-202.1A16.022,16.022,0,0,1,335.891,238h25.182a23.944,23.944,0,0,0,23.144,17H424.59a23.942,23.942,0,0,0,23.143-17h25.183A16.186,16.186,0,0,1,489,254.182Z" />
                                          <path d="M343.949,325h7.327a7,7,0,1,0,0-14H351V292h19.307a6.739,6.739,0,0,0,6.655,4.727A7.019,7.019,0,0,0,384,289.743v-4.71A7.093,7.093,0,0,0,376.924,278H343.949A6.985,6.985,0,0,0,337,285.033v32.975A6.95,6.95,0,0,0,343.949,325Z" />
                                          <path d="M344,389h33a7,7,0,0,0,7-7V349a7,7,0,0,0-7-7H344a7,7,0,0,0-7,7v33A7,7,0,0,0,344,389Zm7-33h19v19H351Z" />
                                          <path d="M351.277,439H351V420h18.929a7.037,7.037,0,0,0,14.071.014v-6.745A7.3,7.3,0,0,0,376.924,406H343.949A7.191,7.191,0,0,0,337,413.269v32.975A6.752,6.752,0,0,0,343.949,453h7.328a7,7,0,1,0,0-14Z" />
                                          <path d="M393.041,286.592l-20.5,20.5-6.236-6.237a7,7,0,1,0-9.9,9.9l11.187,11.186a7,7,0,0,0,9.9,0l25.452-25.452a7,7,0,0,0-9.9-9.9Z" />
                                          <path d="M393.041,415.841l-20.5,20.5-6.236-6.237a7,7,0,1,0-9.9,9.9l11.187,11.186a7,7,0,0,0,9.9,0l25.452-25.452a7,7,0,0,0-9.9-9.9Z" />
                                          <path d="M464.857,295H420.891a7,7,0,0,0,0,14h43.966a7,7,0,0,0,0-14Z" />
                                          <path d="M464.857,359H420.891a7,7,0,0,0,0,14h43.966a7,7,0,0,0,0-14Z" />
                                          <path d="M464.857,423H420.891a7,7,0,0,0,0,14h43.966a7,7,0,0,0,0-14Z" />
                                        </svg>
                                      </span>
                                    </div>
                                    <h4 className="profile__main-info-title">
                                      Orders
                                    </h4>
                                  </div>
                                </a>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <a href="/wishlist">
                                  <div className="profile__main-info-item">
                                    <div className="profile__main-info-icon">
                                      <span>
                                        <span className="profile-icon-count profile-wishlist">
                                          {cartlist.length}
                                        </span>
                                        <svg
                                          viewBox="0 -20 480 480"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="m348 0c-43 .0664062-83.28125 21.039062-108 56.222656-24.71875-35.183594-65-56.1562498-108-56.222656-70.320312 0-132 65.425781-132 140 0 72.679688 41.039062 147.535156 118.6875 216.480469 35.976562 31.882812 75.441406 59.597656 117.640625 82.625 2.304687 1.1875 5.039063 1.1875 7.34375 0 42.183594-23.027344 81.636719-50.746094 117.601563-82.625 77.6875-68.945313 118.726562-143.800781 118.726562-216.480469 0-74.574219-61.679688-140-132-140zm-108 422.902344c-29.382812-16.214844-224-129.496094-224-282.902344 0-66.054688 54.199219-124 116-124 41.867188.074219 80.460938 22.660156 101.03125 59.128906 1.539062 2.351563 4.160156 3.765625 6.96875 3.765625s5.429688-1.414062 6.96875-3.765625c20.570312-36.46875 59.164062-59.054687 101.03125-59.128906 61.800781 0 116 57.945312 116 124 0 153.40625-194.617188 266.6875-224 282.902344zm0 0" />
                                        </svg>
                                      </span>
                                    </div>
                                    <h4 className="profile__main-info-title">
                                      Wishlist
                                    </h4>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-information"
                        role="tabpanel"
                        aria-labelledby="nav-information-tab"
                      >
                        <div className="profile__info">
                          <h3 className="profile__info-title">
                            Personal Details
                          </h3>
                          <div className="profile__info-content">
                            <form>
                              <div className="row">
                                <div className="col-xxl-6 col-md-6">
                                  <div className="profile__input-box">
                                    <div className="profile__input">
                                      <input
                                        type="text"
                                        placeholder="Enter your username"
                                        value={user.first_name}
                                        onChange={handleChangefirst}
                                      />
                                      <span>
                                        <i className="fa-regular fa-user" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xxl-6 col-md-6">
                                  <div className="profile__input-box">
                                    <div className="profile__input">
                                      <input
                                        type="email"
                                        placeholder="Enter your email"
                                        value={user.email_address}
                                        // onChange={handleChangeEmail}
                                        readOnly
                                      />
                                      <span>
                                        <i className="fa-regular fa-envelope" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xxl-6 col-md-6">
                                  <div className="profile__input-box">
                                    <div className="profile__input">
                                      <input
                                        type="text"
                                        placeholder="Enter username"
                                        value={user.facebook}
                                        onChange={handleChangefacebook}
                                      />
                                      <span>
                                        <i className="fa-brands fa-facebook-f" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xxl-6 col-md-6">
                                  <div className="profile__input-box">
                                    <div className="profile__input">
                                      <input
                                        type="text"
                                        placeholder="Enter username"
                                        value={user.twitter}
                                        onChange={handleChangetwitter}
                                      />
                                      <span>
                                        <i className="fa-brands fa-twitter" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xxl-6 col-md-6">
                                  <div className="profile__input-box">
                                    <div className="profile__input">
                                      <input
                                        type="text"
                                        placeholder="Enter your number"
                                        value={user.phone_number}
                                        onChange={handleChangephone}
                                      />
                                      <span>
                                        <i className="fa-solid fa-mobile-screen" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xxl-6 col-md-6">
                                  <div className="profile__input-box">
                                    <div className="profile__input">
                                      <select>
                                        <option>Male</option>
                                        <option>Female</option>
                                        <option>Others</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xxl-12">
                                  <div className="profile__input-box">
                                    <div className="profile__input">
                                      <input
                                        type="text"
                                        placeholder="Enter your address"
                                        value={user.address}
                                        onChange={handleChangeaddress}
                                      />
                                      <span>
                                        <i className="fa-solid fa-location-dot" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xxl-12">
                                  <div className="profile__input-box">
                                    <div className="profile__input">
                                      <textarea
                                        placeholder="Enter your bio"
                                        defaultValue={""}
                                        value={user.bio}
                                        onChange={handleChangebio}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xxl-12">
                                  <div className="profile__btn">
                                    <button
                                      type="submit"
                                      className="tp-btn"
                                      onClick={handleUpdateProfile}
                                    >
                                      Update Profile
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-address"
                        role="tabpanel"
                        aria-labelledby="nav-address-tab"
                      >
                        <div className="profile__address">
                          <div className="row">
                            {orderhistory.map((row) => {
                              return (
                                <div className="col-md-6">
                                  <div className="profile__address-item d-sm-flex align-items-start">
                                    <div className="profile__address-icon">
                                      <span>
                                        <i className="fa-solid fa-file-invoice fa-2x f_orange" />
                                      </span>
                                    </div>
                                    <div className="profile__address-content">
                                      <h3 className="profile__address-title">
                                        Billing Address
                                      </h3>
                                      <p>
                                        <span>Street:</span>
                                        {row.products[0].street_address}
                                      </p>
                                      <p>
                                        <span>City:</span>
                                        {row.products[0].city}
                                      </p>
                                      <p>
                                        <span>State/province/area:</span>
                                        {row.products[0].state}
                                      </p>
                                      <p>
                                        <span>Phone number:</span>
                                        {row.products[0].phone_number}
                                      </p>
                                      <p>
                                        <span>Zip code:</span>
                                        {row.products[0].zip_code}
                                      </p>
                                      {/* <p>
                                        <span>Country Calling Code:</span> +1
                                      </p> */}
                                      <p>
                                        <span>Country:</span>
                                        {row.products[0].country}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {/* <div className="col-md-6">
                              <div className="profile__address-item d-sm-flex align-items-start">
                                <div className="profile__address-icon">
                                  <span>
                                    <i className="fa-solid fa-truck-fast fa-2x f_orange" />
                                  </span>
                                </div>
                                <div className="profile__address-content">
                                  <h3 className="profile__address-title">
                                    Shipping Address
                                  </h3>
                                  <p>
                                    <span>Street:</span>3133 Lewis Street
                                  </p>
                                  <p>
                                    <span>City:</span>Naperville
                                  </p>
                                  <p>
                                    <span>State/province/area:</span>Illinois
                                  </p>
                                  <p>
                                    <span>Phone number:</span>630-857-9127
                                  </p>
                                  <p>
                                    <span>Zip code:</span> 60563
                                  </p>
                                  <p>
                                    <span>Country Calling Code:</span>+1
                                  </p>
                                  <p>
                                    <span>Country:</span>United States
                                  </p>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-order"
                        role="tabpanel"
                        aria-labelledby="nav-order-tab"
                      >
                        <div className="profile__ticket table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Order Id</th>
                                <th scope="col">Product Title</th>
                                <th scope="col">Status</th>
                                <th scope="col">View</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderhistory.map((row) => {
                                return (
                                  <tr>
                                    <th scope="row">
                                      {" "}
                                      {row.products[0].checkout_id.substring(
                                        0,
                                        15
                                      )}
                                    </th>
                                    <td data-info="title">
                                      {row.products[0].products.name}
                                    </td>
                                    <td data-info="status pending">
                                      {row.products[0].status == "paid"
                                        ? "Resolved"
                                        : "Pending"}{" "}
                                    </td>
                                    <td>
                                      <a
                                        className="tp-logout-btn"
                                        // href="/invoice"
                                        // target="_blank"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          navigate(
                                            "/invoice?data=" +
                                              btoa(
                                                JSON.stringify(
                                                  row.products[0]._id
                                                )
                                              )
                                          );
                                          console.log("jjjjjjjjj");
                                        }}
                                      >
                                        Invoice
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-notification"
                        role="tabpanel"
                        aria-labelledby="nav-notification-tab"
                      >
                        <div className="profile__notification">
                          <div className="profile__notification-top mb-30">
                            <h3 className="profile__notification-title">
                              My activity settings
                            </h3>
                            <p>
                              Stay up to date with notification on activity that
                              involves you including mentions, messages,
                              replies, new items, sale and administrative
                              updates{" "}
                            </p>
                          </div>
                          <div className="profile__notification-wrapper">
                            <div className="profile__notification-item mb-20">
                              <div className="form-check form-switch d-flex align-items-center">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="like"
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="like"
                                >
                                  Like &amp; Follows Notifications
                                </label>
                              </div>
                            </div>
                            <div className="profile__notification-item mb-20">
                              <div className="form-check form-switch d-flex align-items-center">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="post"
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="post"
                                >
                                  Post, Comments &amp; Replies Notifications
                                </label>
                              </div>
                            </div>
                            <div className="profile__notification-item mb-20">
                              <div className="form-check form-switch d-flex align-items-center">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="new"
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="new"
                                >
                                  New Product Notifications
                                </label>
                              </div>
                            </div>
                            <div className="profile__notification-item mb-20">
                              <div className="form-check form-switch d-flex align-items-center">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="sale"
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="sale"
                                >
                                  Product on sale Notifications
                                </label>
                              </div>
                            </div>
                            <div className="profile__notification-item mb-20">
                              <div className="form-check form-switch d-flex align-items-center">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="payment"
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="payment"
                                >
                                  Payment Notifications
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-password"
                        role="tabpanel"
                        aria-labelledby="nav-password-tab"
                      >
                        <div className="profile__password">
                          <form onSubmit={handleUpdatePwd}>
                            <div className="row">
                              {/* Old Password */}
                              <div className="col-xxl-12">
                                <div className="tp-profile-input-box">
                                  <div className="tp-contact-input">
                                    <input
                                      name="old_pass"
                                      id="old_pass"
                                      type="password"
                                      value={oldPassword}
                                      onChange={handleOldPasswordChange}
                                    />
                                  </div>
                                  <div className="tp-profile-input-title">
                                    <label htmlFor="old_pass">
                                      Old Password
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/* New Password */}
                              <div className="col-xxl-6 col-md-6">
                                <div className="tp-profile-input-box">
                                  <div className="tp-profile-input">
                                    <input
                                      name="new_pass"
                                      id="new_pass"
                                      type="password"
                                      value={newPassword}
                                      onChange={handleNewPasswordChange}
                                    />
                                  </div>
                                  <div className="tp-profile-input-title">
                                    <label htmlFor="new_pass">
                                      New Password
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/* Confirm New Password */}
                              <div className="col-xxl-6 col-md-6">
                                <div className="tp-profile-input-box">
                                  <div className="tp-profile-input">
                                    <input
                                      name="con_new_pass"
                                      id="con_new_pass"
                                      type="password"
                                      value={confirmNewPassword}
                                      onChange={handleConfirmNewPasswordChange}
                                    />
                                  </div>
                                  <div className="tp-profile-input-title">
                                    <label htmlFor="con_new_pass">
                                      Confirm Password
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/* Submit Button */}
                              <div className="col-xxl-6 col-md-6">
                                <div className="profile__btn">
                                  <button type="submit" className="tp-btn">
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </section>
        {/* profile area end */}
      </main>
      {/* {showInvoice && <Invoice invoice={invoicevalue} />} */}
    </>
  );
}

function Invoice(props) {
  const navigate = useNavigate();
  const pdfRef = useRef(null);
  const [user_id, setUser_id] = useState(
    window.localStorage.getItem("user_id")
      ? window.localStorage.getItem("user_id")
      : ""
  );
  const [randomString, setRandomString] = useState(
    window.localStorage.getItem("randomString")
      ? window.localStorage.getItem("randomString")
      : ""
  );
  let id = JSON.parse(
    atob(new URLSearchParams(window.location.search).get("data"))
  );
  console.log("id", id);
  // const [invoicedata, setInvoice] = useState(props);
  const [orderhistory, setOrderhistory] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [Total, setTotal] = useState("");

  // const [Total, setTotal] = useState("");
  if (!user_id) {
    navigate("/login");
  }
  function getProductsorderhistory() {
    if (user_id.length === 5) {
      navigate("/login");
    } else {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "products/order/history/info?user_id=" +
            window.localStorage.getItem("user_id") +
            "&purchase_id=" +
            id
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === "success") {
            console.log("suceess", res.data.result.data);
            setOrderhistory(res.data.result);
            setInvoiceDate(res.data.result[0].updated_date);
            setInvoiceId(res.data.result[0]._id);
            setTotal(res.data.result[0].total_price);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  useEffect(() => {
    getProductsorderhistory();
  }, []);
  const ReactHtml = (data) => {
    const htmlContent = data;

    const divElement = document.createElement("div");
    divElement.innerHTML = htmlContent;
    const textContent = divElement.innerText;

    return (
      <div>
        {/* {textContent} */}
        {textContent.length > 180
          ? textContent.substring(0, 180) + "... Read More"
          : textContent}
      </div>
    );
  };
  const date = (data) => {
    const dateStr = data;
    const formattedDate = new Date(dateStr).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "UTC",
    });

    return <div>{formattedDate}</div>;
  };
  const generatePdf = () => {
    const input = pdfRef.current;

    if (input) {
      const pdf = new jsPDF("p", "mm", [180, 270]); // Create a new PDF document

      // Get canvas image data
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        // Add the canvas image to the PDF
        pdf.addImage(imgData, "PNG", 10, 20, 140, 120); // Adjust position and size

        pdf.save("invoice.pdf"); // Save the PDF with a filename
      });
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          maxWidth: "595px",
          margin: "100px auto",
          backgroundColor: "#ffffff",
          padding: "40px",
        }}
      >
        <div ref={pdfRef} style={{ fontSize: "12px" }}>
          <table
            width="100%"
            cellPadding={10}
            cellSpacing={0}
            style={{
              fontFamily: "Arial",
              fontSize: 16,
              color: "#333333",
              lineHeight: "25px",
            }}
          >
            <tbody>
              <tr>
                <td valign="top">
                  <img
                    src={pdflogo}
                    alt="Hellalit Weedfindr"
                    style={{
                      fontFamily: "Arial",
                      fontSize: "24px",
                      fontWeight: "bold",
                      height: 25,
                    }}
                    // style={{ height: 25 }}
                  />
                </td>
                <td valign="top">
                  <div style={{ textAlign: "right" }}>
                    +91 8825478557
                    <br />
                    test@hellalit.com
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>&nbsp;</td>
              </tr>
              <tr>
                <td valign="top">
                  <div>
                    <div style={{ fontSize: 18, paddingBottom: 30 }}>
                      <strong>Recipient</strong>
                    </div>
                    <span style={{ fontSize: 17, color: "#f14526" }}>
                      <strong>Alexander Pierce</strong>
                    </span>
                    <br />
                    {window.localStorage.getItem("email_address")} <br />
                  </div>
                </td>
                <td valign="top">
                  <div style={{ textAlign: "right" }}>
                    <div
                      style={{
                        fontSize: 34,
                        paddingBottom: 30,
                        color: "#f14526",
                      }}
                    >
                      <strong>Invoice</strong>
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                      <span style={{ fontSize: 18 }}>
                        <strong>Invoice No</strong>
                      </span>
                      <br />
                      {invoiceId.substring(0, 15)}
                    </div>
                    <div>
                      <span style={{ fontSize: 18 }}>
                        <strong>Invoice Date</strong>
                      </span>
                      <br />
                      {date(invoiceDate)}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>&nbsp;</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table width="100%" cellPadding={10} cellSpacing={0}>
                    <tbody>
                      <tr>
                        <th
                          align="left"
                          style={{ borderBottom: "1px solid #dee2e6" }}
                        >
                          S.No
                        </th>
                        <th
                          align="left"
                          style={{ borderBottom: "1px solid #dee2e6" }}
                        >
                          Item Code
                        </th>
                        <th
                          align="left"
                          style={{ borderBottom: "1px solid #dee2e6" }}
                        >
                          Item Name
                        </th>
                        <th
                          align="right"
                          style={{ borderBottom: "1px solid #dee2e6" }}
                        >
                          Amount
                        </th>
                      </tr>
                      {orderhistory.map((row) => {
                        return (
                          <tr>
                            <td
                              style={{
                                borderBottom: "1px solid rgb(222, 226, 230)",
                                padding: "10px",
                              }}
                            >
                              1
                            </td>
                            <td
                              style={{
                                borderBottom: "1px solid rgb(222, 226, 230)",
                                padding: "10px",
                              }}
                            >
                              {row.products[0].checkout_id.substring(0, 15)}
                            </td>
                            <td
                              style={{
                                borderBottom: "1px solid rgb(222, 226, 230)",
                                padding: "10px",
                              }}
                            >
                              {row.products[0].products.name}
                            </td>
                            <td
                              align="right"
                              style={{
                                borderBottom: "1px solid rgb(222, 226, 230)",
                                padding: "10px",
                              }}
                            >
                              ${row.total_price / 100}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td
                          style={{
                            borderBottom: "1px solid rgb(222, 226, 230)",
                            padding: "10px",
                          }}
                        >
                          <strong>Shipping</strong>
                        </td>
                        <td
                          align="right"
                          style={{
                            borderBottom: "1px solid rgb(222, 226, 230)",
                            padding: "10px",
                          }}
                        >
                          <strong>$15</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td
                          style={{
                            borderBottom: "1px solid rgb(222, 226, 230)",
                            padding: "10px",
                          }}
                        >
                          <strong>Total</strong>
                        </td>
                        <td
                          align="right"
                          style={{
                            borderBottom: "1px solid #dee2e6",
                            fontSize: 30,
                            color: "#f14526",
                          }}
                        >
                          <strong>${Total / 100 + 15}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <button className="tp-logout-btn" onClick={generatePdf}>
          Generate PDF
        </button>
         */}
        <div style={{ width: "100%", textAlign: "right", paddingTop: 20 }}>
          <button
            onClick={generatePdf}
            className="tp-logout-btn"
            style={{
              fontWeight: 500,
              fontSize: 16,
              color: "#ffffff",
              backgroundColor: "#000000",
              display: "inline-block",
              padding: "10px 20px",
              border: 0,
            }}
          >
            Generate PDF
          </button>
        </div>
      </div>
    </>
  );
}
export { Profile, Invoice };

import React, { useContext } from 'react'
import { ThingsContext } from './thingsContext'
import { ReactSession } from 'react-client-session';
import { withScriptjs } from "react-google-maps";
import {fontawesome} from '@fortawesome/fontawesome'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import { faTruck } from '@fortawesome/fontawesome-free-solid'
import { faCookie, faCannabis, faJarWheat, faTruck, faShop, faMobileScreenButton }  from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DispensaryCarsoulList, DispensaryAPI } from "./commonComp";
import ReactDOM from 'react-dom'
import axios, {isCancel, AxiosError} from 'axios';
import Gmap from './Gmap';
import staticLocations from "./staticLocations.json";

function searchthisarea(newCenter){
    ReactSession.set("searchLocationBetween",true);
        ReactSession.set("searchLocationBetweenVP", newCenter);
        ReactSession.set("userlocationVP", newCenter);
        // window.location.reload(false);
            axios.get( DispensaryAPI(ReactSession.get("userCurrentLocationFlag"), ReactSession.get('userlocationLat'),ReactSession.get('userlocationLng'), ReactSession.get('userlocationDetails').city, ReactSession.get('userlocationDetails').state, ReactSession.get('userlocationDetails').country))
            .then((response) => {
            if(response.data.status == 'success'){
                console.log('asddad')
                // setDynamicDetailList(response.data.result);
                // console.log(dynamicList)
            }
        });
}

function Maps() {
    const [dynamicDetailList, setDynamicDetailList] = React.useState([]);

    React.useEffect(() => {
        axios.get( DispensaryAPI(ReactSession.get("userCurrentLocationFlag"), ReactSession.get('userlocationLat'),ReactSession.get('userlocationLng'), ReactSession.get('userlocationDetails').city, ReactSession.get('userlocationDetails').state, ReactSession.get('userlocationDetails').country))
        .then((response) => {
        if(response.data.status == 'success'){
            setDynamicDetailList(response.data.result);
        }
      });
    }, []);

  return (
    <div className="">
        <DetailList dynamicDetailList={dynamicDetailList}/>
    </div>
  );
}    

  function DetailList({dynamicDetailList}) {
    const things = ReactSession.get("userlocation")
    const navigate = useNavigate();
    // if(list.length > 0){
        return(
            <div className="container pt-0 mw-100">
  <div className="list-header">
    <div>
      {/* <span>Dispensaries near <span className="black-text">{things}</span></span> */}
    </div>
  </div>
  <div className="row d-flex mapBox">
  <div className='col-12 col-md-8 col-lg-8 gmap map-dispensaries' >
        <Gmap dynamicDetailList={dynamicDetailList} />
     </div>
    <div className="col-12  col-md-4 col-lg-4 map-dispensaries">
        {(dynamicDetailList.length == 0) ? "No Dispensaries Found":dynamicDetailList.map(row => {
            return(
            <div className="brand-card" onClick={() => navigate('/dispensaries/detail?data='+btoa(JSON.stringify(row._id)))}> 
    <div className="image-col col-12 col-lg-4 mb-0">
      <img alt={row.name} src={row.logo} className="img-fluid" />
    </div>
    <div className="col-12 col-lg-7">
      <h6>{row.name}</h6>
      <div className="type-of-services">Medicinal - Recreational - CBD</div>
      <div className="menu-breakdown">
      <span>{row.city.city_name}, {row.state.state_name}, {row.zip_code}</span>
      <div className="menu-category justify-category">
        {row.phone != "" && (
            <div className="item">
            <FontAwesomeIcon  title={row.phone} icon={faMobileScreenButton}></FontAwesomeIcon>
          </div>
        )}
        
         {row.hasDispensary === true && (
             <div className="item">
             <FontAwesomeIcon icon={faShop}></FontAwesomeIcon>
           </div>
        )}
       
       {row.hasDelivery === true && (
        <div className="item">
          <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
        </div>
        )}

      </div>

      {/* <div class="available-services-wrapper col-md-12 d-flex">
      <FontAwesomeIcon class="col-md-3 " icon={faMobileScreenButton}></FontAwesomeIcon>
      <FontAwesomeIcon class="col-md-3 " icon={faShop}></FontAwesomeIcon>
      <FontAwesomeIcon class="col-md-3  " icon={faTruck}></FontAwesomeIcon>
            </div> */}
      </div>
    </div>
</div>); })}

     </div>
     
   </div>
   </div>
        );
    }
    // }
    
export default Maps;

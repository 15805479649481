import React, { Component, useContext, useState } from 'react'
import { ThingsContext } from '../components/thingsContext'
import logo from '../assets/images/hella-lit-logo.png';
import { useNavigate } from 'react-router-dom';
import NavigationBar from "./NavigationBar";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import 'bootstrap/dist/css/bootstrap.min.css';

import { ReactSession } from 'react-client-session';

function Header({ pathname }) {
    const navigate = useNavigate();
    const [userLoc, setUserLoc] = useState(ReactSession.get("userlocation"));
    return (
        <header>
            <NavigationBar></NavigationBar>
        </header>
    );
}

// function GeoLocation(data) {
//     const handleSubmit = event => {
//         console.log('handleSubmit ran');
//         event.preventDefault();
//       };

//       const api = 'AIzaSyCSYGvIgsNeJhQxgWta0NzNIsUUrw_9LTs';
//     const [autoc, setAutoC] = React.useState();
//     function loadAuto(autocomplete) {
//         console.log('autocomplete: ', autocomplete)

//         setAutoC(autocomplete);
//       }

//       function compIsType(t, s) { 
//         for(let z = 0; z < t.length; ++z) 
//            if(t[z] === s)
//           return true;

//         return false;
//     }

//     function onPlaceChangedAuto () {
//         let  showKey = "";
//         let removeTextFlag="";

//         if (autoc !== null) {
//             console.log(autoc);

//             const place = autoc.getPlace();
//             //console.log(" from google auto complete place===>",place);
//             let lat, lng, addrSel, placeName, placeId =''; 
//             let country, state, city =null;  
//             if(place.geometry!==undefined ){
//             const plcGeom = place.geometry;
//             if(plcGeom.location!==undefined){
//                 lat = plcGeom.location ? plcGeom.location.lat():'';
//                 lng = plcGeom.location ? plcGeom.location.lng():'';
//             }      
//             }   

//             addrSel  = place.formatted_address!==undefined?place.formatted_address:"";
//             placeName  = place.name!==undefined?place.name:"";
//             placeId = place.place_id!==undefined?place.place_id:"";

//             if(place.address_components!==undefined){
//             let addrComp = place.address_components;
//             for(let i = 0; i < addrComp.length; ++i)
//             {
//                 var typ = addrComp[i].types;
//                 if(compIsType(typ, 'administrative_area_level_1'))
//                     state = addrComp[i].long_name; //store the state
//                 else if(compIsType(typ, 'locality'))
//                     city = addrComp[i].long_name; //store the city
//                 else if(compIsType(typ, 'country'))
//                 country = addrComp[i].long_name; //store the country        

//                 //we can break early if we find all three data
//                 if(state != null && city != null && country != null) break;
//             }




//             }

//             let nameData = "";
//             if(showKey!=="" ){


//                 if(showKey === "CITY"){
//                 nameData = city;
//                 }
//                 else if(showKey === "STATE"){
//                 nameData = state;
//                 }
//                 else if(showKey === "COUNTRY"){
//                 nameData = country;
//                 }
//                 else if(showKey === "PLACE_NAME"){
//                 nameData = country;
//                 }
//                 else if(showKey === "FORMATTED_ADDRESS"){
//                 nameData = addrSel;
//                 }
//                 else if(showKey === "PLACE_ID"){
//                 nameData = placeId;
//                 }
//                 else{
//                 nameData = addrSel;
//                 }

//             }else{

//                 nameData = addrSel;
//             }



//             if(removeTextFlag!=="" && removeTextFlag==="YES" ){
//                 nameData = "";
//             }

//             let stateResp = {
//             'lat':lat,
//             'lng': lng,
//             'formattedAddress':addrSel, 
//             'placeName': placeName,
//             'placeId': placeId,
//             'city':city,
//             'state':state,
//             'country':country,
//             'textboxtext':nameData
//             };
//             ReactSession.set("userCurrentLocationFlag", false);
//             ReactSession.set("userlocation", autoc.getPlace().formatted_address);
//             ReactSession.set("userlocationLat", autoc.getPlace().geometry.location.lat());
//             ReactSession.set("userlocationLng", autoc.getPlace().geometry.location.lng());
//             ReactSession.set("userlocationVP", autoc.getPlace().geometry.viewport);
//             ReactSession.set("userlocationDetails", stateResp);
//             window.location.reload(false);
//         } else {
//           console.log('Autocomplete is not loaded yet!')
//         }
//       }

//     return(
//         <ThingsContext.Provider value={data.userLoc}>

//         <form className="" onSubmit={handleSubmit}>
//         <div className="d-flex justify-content-between" onSubmit={handleSubmit}>
//         <LoadScript
//             googleMapsApiKey ={api}
//             libraries={["places"]}
//         >
//             <Autocomplete onLoad={loadAuto}
//             onPlaceChanged={onPlaceChangedAuto}>
//                     <input
//                         id="userLocation" className="form-control locationSearchBox" type="text" placeholder="Search location"  defaultValue={data.userLoc}
//                     />
//                 </Autocomplete>

//           </LoadScript>
//                 <button title="Get My Current Location" onClick={getCurrentLocation} className="btn border-0" type="button">
//                 <FontAwesomeIcon icon={faLocationDot}></FontAwesomeIcon>
//                 </button>
//         </div>
//         </form>
//         </ThingsContext.Provider>
//     );
// }

// function getCurrentLocation(){
//     navigator.geolocation.getCurrentPosition(function(position) {
//         console.log("Latitude is :", position.coords.latitude);
//         console.log("Longitude is :", position.coords.longitude);
//         fetch("https://api.mapbox.com/geocoding/v5/mapbox.places/"+position.coords.longitude+","+position.coords.latitude+".json?access_token=pk.eyJ1IjoiaGVsbGEtbGl0IiwiYSI6ImNsYTNod25vbTBybTYzeG8zZzk2NGVuZW0ifQ.5W4GWKSi-Vv9ZU9YXgOUPg")
//         .then(response => response.json())
//         .then(data => {
//             console.log(data);
//             ReactSession.set("userlocation", data.features[3].text);
//             ReactSession.set("userCurrentLocationFlag", true);
//             ReactSession.set("userlocationLat", data.query[1]);
//             ReactSession.set("userlocationLng", data.query[0]);
//             ReactSession.set("userlocationDetails", parseReverseGeo(data));
//             window.location.reload(false);
//         });
//     });
// }

// function parseReverseGeo(geoData) {
//     let  city="", state="", country = "";
//     geoData.features.map((context, i) => {
//         if(context.place_type[0] === 'place')
//             city = context.text;
//         if(context.place_type[0] === 'region')
//             state = context.text;
//         if(context.place_type[0] === 'country')
//             country = context.text; 
//         return [];
//     });

//     return JSON.stringify({'city':city, 'state':state, 'country':country});   
// }
export default Header;
import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function PdfGenerator() {
  const pdfRef = useRef(null);

  const generatePdf = () => {
    const input = pdfRef.current;

    if (input) {
      const pdf = new jsPDF("p", "mm", "a4"); // Create a new PDF document
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 10, 10, 190, 277); // Add the canvas image to the PDF
        pdf.save("../example.pdf"); // Save the PDF with a filename
      });
    }
  };

  return (
    <div>
      <div ref={pdfRef}>
        {/* Your content to be included in the PDF */}
        <h1>Hello, PDF!</h1>
        <p>This is an example PDF generated from a React component.</p>
      </div>
      <button onClick={generatePdf}>Generate PDF</button>
    </div>
  );
}

export { PdfGenerator };

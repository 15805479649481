import React, { Component } from "react";
import axios, {isCancel, AxiosError} from 'axios';

export default class GeoLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {latitude: 10, longitude: 0};
      }

      componentDidMount(){
        navigator.geolocation.getCurrentPosition(function(position) {
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
            this.setState({latitude: position.coords.latitude, longitude: position.coords.longitude});
            fetch("https://api.mapbox.com/geocoding/v5/mapbox.places/"+position.coords.longitude+","+position.coords.latitude+".json?access_token=pk.eyJ1IjoiaGVsbGEtbGl0IiwiYSI6ImNsYTNod25vbTBybTYzeG8zZzk2NGVuZW0ifQ.5W4GWKSi-Vv9ZU9YXgOUPg")
            .then(response => response.json())
            .then(data => {
                this.setState({ userCurrentLocation: data.features[3].text})
            });
          }.bind(this));
      }
      
      render() {
        console.log(this.state);
    return;
  }
}
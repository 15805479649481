import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import bannerImg from "../assets/images_/p5.png";
import samImg from "../assets/images_/products/default_blog_image.png";
import Carousel from "react-multi-carousel";
// import Carousel from "better-react-carousel";
import "react-multi-carousel/lib/styles.css";
import "./additionalCSS.css";
import { ReactSession } from "react-client-session";
import { ShowDetail } from "./commonComp";
import MediaComponent from "./mediafile";
import nav1 from "../assets/img/product/details/nav/product-details-nav-1.jpg";
import nav2 from "../assets/img/product/details/nav/product-details-nav-2.jpg";
import nav3 from "../assets/img/product/details/nav/product-details-nav-3.jpg";
import nav4 from "../assets/img/product/details/nav/product-details-nav-4.jpg";
import nav5 from "../assets/img/product/details/nav/product-details-nav-5.jpg";
import productsmainpage from "../assets/img/product/details/main/product-details-main-1.jpg";
import paymentOption from "../assets/img/product/icons/payment-option.png";
import productRelated from "../assets/img/product/related/product-related-1.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
function ProductsList() {
  console.log("console");
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products_id, setProductsid] = useState("");
  const [user_id, setUser_id] = useState(
    window.localStorage.getItem("user_id")
      ? window.localStorage.getItem("user_id")
      : ""
  );
  const [randomString, setRandomString] = useState(
    window.localStorage.getItem("randomString")
      ? window.localStorage.getItem("randomString")
      : ""
  );
  const [socket, setSocket] = useState(null);
  const [receivedData, setReceivedData] = useState(null);
  const [searchtext, setSearchtext] = useState("");
  const [selectedSort, setSelectedSort] = useState("Default Sorting");
  function getProducts(id) {
    var url;
    if (id) {
      url = process.env.REACT_APP_API_URL + "products/list?category_id=" + id;
    } else {
      url = process.env.REACT_APP_API_URL + "products/list";
    }
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          // console.log("suceess");
          setProducts(res.data.result);
        } else {
          setProducts([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function getProductsSearch(search, sorting) {
    var url;
    if (search || sorting) {
      url =
        process.env.REACT_APP_API_URL +
        "products/list?search_text=" +
        search +
        "&sorting=" +
        sorting;
    } else {
      url = process.env.REACT_APP_API_URL + "products/list";
    }
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          // console.log("suceess");
          setProducts(res.data.result);
        } else {
          setProducts([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //products add
  useEffect(() => {
    const socketInstance = io("https://development-api.hellalit.org:2022", {
      transports: ["websocket"],
    }); // Replace with your server's address
    setSocket(socketInstance);
    console.log("socket", socket);
    socketInstance.on("hellalitsocket", handleSocketMessage);

    return () => {
      // Clean up the socket connection when the component unmounts
      socketInstance.off("hellalitsocket", handleSocketMessage);
      socketInstance.close();
    };
  }, []);
  const handleSocketMessage = (data) => {
    // Handle the emitted data from the server
    console.log("Received data from server:", data);
    setReceivedData(data);
    getProducts();
    // You can update your component's state or perform any other action here
  };

  function getCategories() {
    axios
      .get(process.env.REACT_APP_API_URL + "categories/list")
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          console.log("suceess");
          setCategories(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    getCategories();
  }, []);

  // useEffect(() => {
  //   console.log({ products });
  // }, [products]);
  const ReactHtml = (data) => {
    const htmlContent = data;

    const divElement = document.createElement("div");
    divElement.innerHTML = htmlContent;
    const textContent = divElement.innerText;

    return (
      <div>
        {/* {textContent} */}
        {textContent.length > 180
          ? textContent.substring(0, 180) + "... Read More"
          : textContent}
      </div>
    );
  };
  function addTocartproducts(row) {
    const file_detailsd = new FormData();
    if (user_id) {
      file_detailsd.append("user_id", user_id);
    } else {
      file_detailsd.append("random_string", randomString);
    }

    file_detailsd.append("products", row._id);
    file_detailsd.append("cart_type", "products");
    file_detailsd.append(
      "order_data_count",
      parseInt(row.order_data_count) + 1
    );
    axios
      .post(process.env.REACT_APP_API_URL + "products/add/cart", file_detailsd)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);
        toast.success("Product has been added to the cart successfully.");
        if (res.data.status === "success") {
          getProducts();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function addTowishproducts(row) {
    const file_detailsd = new FormData();
    if (user_id) {
      file_detailsd.append("user_id", user_id);
    } else {
      file_detailsd.append("random_string", randomString);
    }
    file_detailsd.append("products", row._id);
    file_detailsd.append(
      "order_data_count",
      parseInt(row.order_data_count) + 1
    );
    axios
      .post(
        process.env.REACT_APP_API_URL + "products/add/wishlist",
        file_detailsd
      )
      .then((res) => {
        console.log(res);
        console.log(res.data.message);
        toast.success(res.data.message);
        if (res.data.status === "success") {
          getProducts();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const generateRandomString = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 15; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };
  useEffect(() => {
    if (
      !window.localStorage.getItem("user_id") &&
      !window.localStorage.getItem("randomString")
    ) {
      const randomString = generateRandomString();
      window.localStorage.setItem("randomString", randomString);
      setRandomString(randomString);
    }
  }, []);
  const handleClick = (id) => {
    getProducts(id);
  };
  const handleSearch = (e) => {
    setSearchtext(e.target.value); // Update searchtext using e.target.value
    getProductsSearch(e.target.value); // Call your search function with the updated value
  };
  const handleSortChange = (e) => {
    setSelectedSort(e.target.value); // Update the selectedSort state with the new value
    getProductsSearch("", e.target.value);
  };
  return (
    <>
      <main>
        <section className="tp-shop-area pb-120 pt-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4">
                <div className="tp-shop-sidebar bg-white p-3 mb-15">
                  {/* filter */}
                  {/* <div className="tp-shop-widget mb-35">
                    <h3 className="tp-shop-widget-title no-border">
                      Price Filter
                    </h3>
                    <div className="tp-shop-widget-content">
                      <div className="tp-shop-widget-filter">
                        <div id="slider-range" className="mb-10" />
                        <div className="tp-order-info-list-shipping-item d-flex flex-column align-items-start">
                          <div className="tp-checkout-payment-item">
                            <input
                              type="radio"
                              id="pricerange1"
                              name="payment"
                            />
                            <label htmlFor="pricerange1">Any</label>
                          </div>
                          <div className="tp-checkout-payment-item">
                            <input
                              type="radio"
                              id="pricerange2"
                              name="payment"
                            />
                            <label htmlFor="pricerange2">Under $25</label>
                          </div>
                          <div className="tp-checkout-payment-item">
                            <input
                              type="radio"
                              id="pricerange3"
                              name="payment"
                            />
                            <label htmlFor="pricerange3">$25 to $50</label>
                          </div>
                          <div className="tp-checkout-payment-item">
                            <input
                              type="radio"
                              id="pricerange4"
                              name="payment"
                            />
                            <label htmlFor="pricerange4">$50 to $100</label>
                          </div>
                          <div className="tp-checkout-payment-item">
                            <input
                              type="radio"
                              id="pricerange5"
                              name="payment"
                            />
                            <label htmlFor="pricerange5">$100 to $200</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* status */}
                  <div className="tp-shop-widget mb-50">
                    <h3 className="tp-shop-widget-title">Product Status</h3>
                    <div className="tp-shop-widget-content">
                      <div className="tp-shop-widget-checkbox">
                        <ul className="filter-items filter-checkbox">
                          <li className="filter-item checkbox">
                            <input id="on_sale" type="checkbox" />
                            <label htmlFor="on_sale">On sale</label>
                          </li>
                          <li className="filter-item checkbox">
                            <input id="in_stock" type="checkbox" />
                            <label htmlFor="in_stock">In Stock</label>
                          </li>
                        </ul>
                        {/* .filter-items */}
                      </div>
                    </div>
                  </div>
                  {/* categories */}
                  <div className="tp-shop-widget mb-50">
                    <h3 className="tp-shop-widget-title">Categories</h3>
                    <div className="tp-shop-widget-content">
                      <div className="tp-shop-widget-categories">
                        <ul>
                          <li>
                            <a
                              onClick={() => {
                                getProducts();
                              }}
                            >
                              All Products
                            </a>
                          </li>
                          {/* <li>
                            <a className="category crsr_pntr">
                              Leather
                              <span>
                                <i className="fa-solid fa-caret-down" />
                              </span>
                            </a>
                            <ul
                              className="ms-3 mt-2 subcategory"
                              style={{ display: "none" }}
                            >
                              <li>
                                <a href="#">
                                  Sub Category 1<span>1</span>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  Sub Category 2<span>4</span>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  Sub Category 3<span>7</span>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  Sub Category 4<span>4</span>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  Sub Category 5<span>6</span>
                                </a>
                              </li>
                            </ul>
                          </li> */}
                          {/* <li>
                            <a href="#">Leather Man Wacth</a>
                          </li>
                          <li>
                            <a href="#">Trending Watch</a>
                          </li>
                          <li>
                            <a href="#">Google</a>
                          </li>
                          <li>
                            <a href="#">Woman Wacth </a>
                          </li>
                          <li>
                            <a href="#">Tables </a>
                          </li>
                          <li>
                            <a href="#">Electronics </a>
                          </li>
                          <li>
                            <a href="#">Phones </a>
                          </li> */}
                          {categories.map((row) => {
                            return (
                              <li>
                                <a
                                  onClick={() => {
                                    handleClick(row._id);
                                  }}
                                  href="#"
                                >
                                  {row.name}{" "}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* product rating */}
                  {/* <div className="tp-shop-widget mb-50">
                    <h3 className="tp-shop-widget-title">Top Rated Products</h3>
                    <div className="tp-shop-widget-content">
                      <div className="tp-shop-widget-product">
                        <div className="tp-shop-widget-product-item d-flex align-items-center">
                          <div className="tp-shop-widget-product-thumb">
                            <a href="product-details.html">
                              <img
                                src="assets/img/product/shop/sm/shop-sm-1.jpg"
                                alt
                              />
                            </a>
                          </div>
                          <div className="tp-shop-widget-product-content">
                            <div className="tp-shop-widget-product-rating-wrapper d-flex align-items-center">
                              <div className="tp-shop-widget-product-rating">
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </div>
                              <div className="tp-shop-widget-product-rating-number">
                                <span>(4.2)</span>
                              </div>
                            </div>
                            <h4 className="tp-shop-widget-product-title">
                              <a href="product-details.html">
                                Smart watches wood...
                              </a>
                            </h4>
                            <div className="tp-shop-widget-product-price-wrapper">
                              <span className="tp-shop-widget-product-price">
                                $150.00
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="tp-shop-widget-product-item d-flex align-items-center">
                          <div className="tp-shop-widget-product-thumb">
                            <a href="product-details.html">
                              <img
                                src="assets/img/product/shop/sm/shop-sm-2.jpg"
                                alt
                              />
                            </a>
                          </div>
                          <div className="tp-shop-widget-product-content">
                            <div className="tp-shop-widget-product-rating-wrapper d-flex align-items-center">
                              <div className="tp-shop-widget-product-rating">
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </div>
                              <div className="tp-shop-widget-product-rating-number">
                                <span>(4.5)</span>
                              </div>
                            </div>
                            <h4 className="tp-shop-widget-product-title">
                              <a href="product-details.html">
                                Decoration for panda.
                              </a>
                            </h4>
                            <div className="tp-shop-widget-product-price-wrapper">
                              <span className="tp-shop-widget-product-price">
                                $120.00
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="tp-shop-widget-product-item d-flex align-items-center">
                          <div className="tp-shop-widget-product-thumb">
                            <a href="product-details.html">
                              <img
                                src="assets/img/product/shop/sm/shop-sm-3.jpg"
                                alt
                              />
                            </a>
                          </div>
                          <div className="tp-shop-widget-product-content">
                            <div className="tp-shop-widget-product-rating-wrapper d-flex align-items-center">
                              <div className="tp-shop-widget-product-rating">
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </div>
                              <div className="tp-shop-widget-product-rating-number">
                                <span>(3.5)</span>
                              </div>
                            </div>
                            <h4 className="tp-shop-widget-product-title">
                              <a href="product-details.html">
                                Trending Watch for Man
                              </a>
                            </h4>
                            <div className="tp-shop-widget-product-price-wrapper">
                              <span className="tp-shop-widget-product-price">
                                $99.00
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="tp-shop-widget-product-item d-flex align-items-center">
                          <div className="tp-shop-widget-product-thumb">
                            <a href="product-details.html">
                              <img
                                src="assets/img/product/shop/sm/shop-sm-4.jpg"
                                alt
                              />
                            </a>
                          </div>
                          <div className="tp-shop-widget-product-content">
                            <div className="tp-shop-widget-product-rating-wrapper d-flex align-items-center">
                              <div className="tp-shop-widget-product-rating">
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 0L7.854 3.756L12 4.362L9 7.284L9.708 11.412L6 9.462L2.292 11.412L3 7.284L0 4.362L4.146 3.756L6 0Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </div>
                              <div className="tp-shop-widget-product-rating-number">
                                <span>(4.8)</span>
                              </div>
                            </div>
                            <h4 className="tp-shop-widget-product-title">
                              <a href="product-details.html">
                                Minimal Backpack.
                              </a>
                            </h4>
                            <div className="tp-shop-widget-product-price-wrapper">
                              <span className="tp-shop-widget-product-price">
                                $165.00
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* brand */}
                  {/* <div className="tp-shop-widget mb-50">
                    <h3 className="tp-shop-widget-title">Popular Brands</h3>
                    <div className="tp-shop-widget-content">
                      <div className="tp-shop-widget-checkbox">
                        <ul className="filter-items filter-checkbox">
                          <li className="filter-item checkbox">
                            <input id="brand1" type="checkbox" />
                            <label htmlFor="brand1">
                              <div className="tp-shop-widget-brand-item">
                                <a href="#">
                                  <img
                                    src="assets/img/product/shop/brand/logo_01.png"
                                    alt
                                  />
                                </a>
                              </div>
                            </label>
                          </li>
                          <li className="filter-item checkbox">
                            <input id="brand2" type="checkbox" />
                            <label htmlFor="brand2">
                              <div className="tp-shop-widget-brand-item">
                                <a href="#">
                                  <img
                                    src="assets/img/product/shop/brand/logo_02.png"
                                    alt
                                  />
                                </a>
                              </div>
                            </label>
                          </li>
                          <li className="filter-item checkbox">
                            <input id="brand3" type="checkbox" />
                            <label htmlFor="brand3">
                              <div className="tp-shop-widget-brand-item">
                                <a href="#">
                                  <img
                                    src="assets/img/product/shop/brand/logo_03.png"
                                    alt
                                  />
                                </a>
                              </div>
                            </label>
                          </li>
                          <li className="filter-item checkbox">
                            <input id="brand4" type="checkbox" />
                            <label htmlFor="brand4">
                              <div className="tp-shop-widget-brand-item">
                                <a href="#">
                                  <img
                                    src="assets/img/product/shop/brand/logo_04.png"
                                    alt
                                  />
                                </a>
                              </div>
                            </label>
                          </li>
                          <li className="filter-item checkbox">
                            <input id="brand5" type="checkbox" />
                            <label htmlFor="brand5">
                              <div className="tp-shop-widget-brand-item">
                                <a href="#">
                                  <img
                                    src="assets/img/product/shop/brand/logo_05.png"
                                    alt
                                  />
                                </a>
                              </div>
                            </label>
                          </li>
                        </ul>
                      
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="tp-shop-main-wrapper bg-white p-3">
                  <div className="tp-shop-top mb-45">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="tp-shop-top-left d-flex align-items-center ">
                          <div className="tp-shop-top-tab tp-tab w-100">
                            <form
                              className="d-flex srchfrm product_lst_srch"
                              role="search"
                            >
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control nobrdr_rds"
                                  placeholder="Search for Products"
                                  value={searchtext}
                                  onChange={handleSearch}
                                />
                                <span className="input-group-text nobrdr_rds">
                                  <i className="fa-solid fa-magnifying-glass crsr_pntr" />
                                </span>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="tp-shop-top-right d-sm-flex align-items-center justify-content-xl-end">
                          <div className="tp-shop-top-select">
                            <select
                              value={selectedSort}
                              onChange={handleSortChange}
                            >
                              <option value="Default Sorting">
                                Default Sorting
                              </option>
                              <option value="low_to_high">Low to High</option>
                              <option value="high_to_low">High to Low</option>
                              <option value="Newly Added">Newly Added</option>
                              {/* <option value="On Sale">On Sale</option> */}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tp-shop-items-wrapper tp-shop-item-primary">
                    <div className="tp-shop-list-wrapper tp-shop-item-primary mb-70">
                      <div className="row">
                        <div className="col-xl-12">
                          {products && products.length > 0 ? (
                            products.map((row) => (
                              <a
                                href="#"
                                onClick={(event) => {
                                  event.preventDefault();
                                  navigate(
                                    "/products/prodclaims?data=" +
                                      btoa(JSON.stringify(row._id))
                                  );
                                  console.log("jjjjjjjjj");
                                }}
                              >
                                <div className="tp-product-list-item d-md-flex mb-20">
                                  <div
                                    className="tp-product-list-thumb p-relative fix px-3"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      navigate(
                                        "/products/prodclaims?data=" +
                                          btoa(JSON.stringify(row._id))
                                      );
                                      console.log("jjjjjjjjj");
                                    }}
                                  >
                                    <img
                                      src={row.logo}
                                      style={{
                                        width: "310px",
                                        height: "260px",
                                      }}
                                      alt
                                    />
                                  </div>
                                  <div className="tp-product-list-content p-relative">
                                    <div className="product_wishlist crsr_pntr">
                                      {/* Outline */}
                                      <i
                                        className="fa-regular fa-heart"
                                        onClick={(event) => {
                                          event.stopPropagation();

                                          addTowishproducts(row);
                                        }}
                                      />
                                      {/* Filled */}
                                      {/* <i class="fa-solid fa-heart f_orange"></i> */}
                                    </div>
                                    <div className="tp-product-content-2 pt-15">
                                      <div className="tp-product-tag-2">
                                        {row.category_id.name}
                                      </div>
                                      <h3 className="tp-product-title-2">
                                        {row.name}
                                      </h3>
                                      <div className="tp-product-rating-icon tp-product-rating-icon-2">
                                        <span>
                                          <i className="fa-solid fa-star" />
                                        </span>
                                        <span>
                                          <i className="fa-solid fa-star" />
                                        </span>
                                        <span>
                                          <i className="fa-solid fa-star" />
                                        </span>
                                        <span>
                                          <i className="fa-solid fa-star" />
                                        </span>
                                        <span>
                                          <i className="fa-solid fa-star" />
                                        </span>
                                      </div>
                                      <div className="tp-product-price-wrapper-2">
                                        <span className="tp-product-price-2 new-price">
                                          ${row.price / 100}.00
                                        </span>
                                        {/* <span className="tp-product-price-2 old-price">
                                          $50.00
                                        </span> */}
                                      </div>
                                      <p>
                                        {ReactHtml(row.description)}
                                        {/* Auctor urna nunc id cursus. Scelerisque
                                        purus semper eget duis at pharetra vel
                                        turpis nunc eget. */}
                                      </p>
                                      <div className="tp-product-list-add-to-cart">
                                        <button
                                          className="tp-product-list-add-to-cart-btn"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            addTocartproducts(row);
                                          }}
                                        >
                                          Add To Cart
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            ))
                          ) : (
                            <p>No products available.</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="tp-shop-pagination mt-20">
                    <div className="tp-pagination">
                      <nav>
                        <ul>
                          <li>
                            <a
                              href="#"
                              className="tp-pagination-prev prev page-numbers"
                            >
                              <svg
                                width={15}
                                height={13}
                                viewBox="0 0 15 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.00017 6.77879L14 6.77879"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6.24316 11.9999L0.999899 6.77922L6.24316 1.55762"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a href="#">1</a>
                          </li>
                          <li>
                            <span className="current">2</span>
                          </li>
                          <li>
                            <a href="#">3</a>
                          </li>
                          <li>
                            <a href="#" className="next page-numbers">
                              <svg
                                width={15}
                                height={13}
                                viewBox="0 0 15 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.9998 6.77883L1 6.77883"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8.75684 1.55767L14.0001 6.7784L8.75684 12"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </section>
      </main>
    </>
  );
}

function ProdClaims(props) {
  const navigate = useNavigate();
  const [user_id, setUser_id] = useState(
    window.localStorage.getItem("user_id")
      ? window.localStorage.getItem("user_id")
      : ""
  );
  const [randomString, setRandomString] = useState(
    window.localStorage.getItem("randomString")
      ? window.localStorage.getItem("randomString")
      : ""
  );
  let id = JSON.parse(
    atob(new URLSearchParams(window.location.search).get("data"))
  );
  console.log("id", id);
  const [detail, setDetail] = useState([]);
  const [category, setCategories] = useState([]);
  useEffect(() => {
    productsInfo(id);
  }, []);
  const productsInfo = (id) => {
    axios
      .get(process.env.REACT_APP_API_URL + "products/list?products_id=" + id)
      .then((response) => {
        if (response.data.status == "success") {
          setDetail(response.data.result);
          setCategories(response.data.result.category_id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const generateRandomString = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 15; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };
  useEffect(() => {
    if (
      !window.localStorage.getItem("user_id") &&
      !window.localStorage.getItem("randomString")
    ) {
      const randomString = generateRandomString();
      window.localStorage.setItem("randomString", randomString);
      setRandomString(randomString);
    }
  }, []);
  const addTocart = (row) => {
    const file_detailsd = new FormData();
    if (user_id) {
      file_detailsd.append("user_id", user_id);
    } else {
      file_detailsd.append("random_string", randomString);
    }
    file_detailsd.append("products", row._id);
    file_detailsd.append(
      "order_data_count",
      parseInt(row.order_data_count) + 1
    );
    axios
      .post(process.env.REACT_APP_API_URL + "products/add/cart", file_detailsd)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status === "success") {
          toast.success("Product has been added to the cart successfully.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addTocartpayment = (id) => {
    if (!user_id) {
      navigate("/login");
    } else {
      if (user_id) {
        const file_detailsd = new FormData();
        file_detailsd.append("user_id", user_id);
        file_detailsd.append("products", id);
        file_detailsd.append("payment_type", "direct");

        axios
          .post(
            process.env.REACT_APP_API_URL + "products/payment/create",
            file_detailsd
          )
          .then((res) => {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess");
              if (res.data.result) {
                window.location.href = res.data.result.url;
                productsInfo(id);
              } else {
                // window.localStorage.setItem("cart_id", res.data.result._id);
                // console.log("/home");
                // toast.success("User has been created successfully.");
                // navigate("/home");
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const ReactHtml = (data) => {
    const htmlContent = data;

    const divElement = document.createElement("div");
    divElement.innerHTML = htmlContent;
    const textContent = divElement.innerText;

    return (
      <div>
        {/* {textContent} */}

        {textContent}
      </div>
    );
  };
  function addTocartproducts(id) {
    const file_detailsd = new FormData();
    if (user_id) {
      file_detailsd.append("user_id", user_id);
    } else {
      file_detailsd.append("random_string", randomString);
    }

    file_detailsd.append("products", id);
    file_detailsd.append("cart_type", "products");

    axios
      .post(process.env.REACT_APP_API_URL + "products/add/cart", file_detailsd)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);
        toast.success("Product has been added to the cart successfully.");
        if (res.data.status === "success") {
          productsInfo(id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function addTocartproductsless(row) {
    const file_detailsd = new FormData();
    if (user_id) {
      file_detailsd.append("user_id", user_id);
    } else {
      file_detailsd.append("random_string", randomString);
    }
    file_detailsd.append("products", row.products._id);
    file_detailsd.append(
      "order_data_count",
      parseInt(row.order_data_count) - 1
    );
    axios
      .post(
        process.env.REACT_APP_API_URL + "products/add/wishlist",
        file_detailsd
      )
      .then((res) => {
        console.log(res);
        console.log(res.data.message);
        toast.success(res.data.message);
        if (res.data.status === "success") {
          productsInfo(id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <main>
        {/* product details area start */}
        <section className="tp-product-details-area">
          <div className="tp-product-details-top pt-100">
            <div className="container bg-white pb-75">
              <div className="row">
                <div className="col-xl-7 col-lg-6">
                  <div className="tp-product-details-thumb-wrapper tp-tab d-sm-flex py-4 px-3">
                    <div
                      id="carouselProductIndicators"
                      className="carousel slide"
                      data-bs-ride="true"
                    >
                      <div className="carousel-indicators">
                        <button
                          type="button"
                          data-bs-target="#carouselProductIndicators"
                          data-bs-slide-to={0}
                          className="active"
                          aria-current="true"
                          aria-label="Slide 1"
                        />
                        <button
                          type="button"
                          data-bs-target="#carouselProductIndicators"
                          data-bs-slide-to={1}
                          aria-label="Slide 2"
                        />
                        <button
                          type="button"
                          data-bs-target="#carouselProductIndicators"
                          data-bs-slide-to={2}
                          aria-label="Slide 3"
                        />
                      </div>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img src={detail.logo} className="d-block w-100" />
                        </div>
                        <div className="carousel-item">
                          <img src={detail.logo} className="d-block w-100" />
                        </div>
                        <div className="carousel-item">
                          <img src={detail.logo} className="d-block w-100" />
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselProductIndicators"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        >
                          <i className="fa-solid fa-chevron-left" />
                        </span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselProductIndicators"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        >
                          <i className="fa-solid fa-chevron-right" />
                        </span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>{" "}
                {/* col end */}
                <div className="col-xl-5 col-lg-6">
                  <div className="tp-product-details-wrapper ms-0 p-3">
                    <div className="tp-product-details-category">
                      <span>{category.name}</span>
                    </div>
                    <h3 className="tp-product-details-title">{detail.name}</h3>
                    {/* inventory details */}
                    <div className="tp-product-details-inventory d-flex align-items-center mb-10">
                      <div className="tp-product-details-stock mb-10">
                        <span>In Stock</span>
                      </div>
                      <div className="tp-product-details-rating-wrapper d-flex align-items-center mb-10">
                        <div className="tp-product-details-rating">
                          <span>
                            <i className="fa-solid fa-star" />
                          </span>
                          <span>
                            <i className="fa-solid fa-star" />
                          </span>
                          <span>
                            <i className="fa-solid fa-star" />
                          </span>
                          <span>
                            <i className="fa-solid fa-star" />
                          </span>
                          <span>
                            <i className="fa-solid fa-star" />
                          </span>
                        </div>
                        <div className="tp-product-details-reviews">
                          <span>(36 Reviews)</span>
                        </div>
                      </div>
                    </div>
                    <p>
                      {category.name}
                      {/* <a href="#">
                        <span className="f_orange">See more</span>
                      </a> */}
                    </p>
                    price
                    <div className="tp-product-details-price-wrapper mb-20">
                      {/* <span className="tp-product-details-price old-price">
                        $320.00
                      </span> */}
                      <span className="tp-product-details-price new-price">
                        ${detail.price / 100}
                      </span>
                    </div>
                    {/* actions */}
                    <div className="tp-product-details-action-wrapper">
                      {/* <h3 className="tp-product-details-action-title">
                        Quantity
                      </h3>
                      <div className="tp-product-details-action-item-wrapper d-flex align-items-center">
                        <div className="tp-product-details-quantity">
                          <div className="tp-product-quantity mb-15 mr-15">
                            <span
                              className="tp-cart-minus"
                              onClick={() => {
                                addTocartproductsless(detail);
                              }}
                            >
                              <i className="fa-solid fa-minus" />
                            </span>
                            <input
                              className="tp-cart-input"
                              type="text"
                              defaultValue={1}
                            />
                            <span
                              className="tp-cart-plus"
                              onClick={() => {
                                addTocartproducts(detail);
                              }}
                            >
                              <i className="fa-solid fa-plus" />
                            </span>
                          </div>
                        </div>
                        <div className="tp-product-details-add-to-cart mb-15 w-100">
                          <button
                            className="tp-product-details-add-to-cart-btn w-100"
                            onClick={() => {
                              addTocart(detail);
                            }}
                          >
                            Add To Cart
                          </button>
                        </div>
                      </div> */}
                      <button
                        className="tp-product-details-buy-now-btn w-100"
                        onClick={() => {
                          addTocartproducts(detail._id);
                        }}
                      >
                        Add To Cart
                      </button>
                    </div>
                    <div className="tp-product-details-action-sm">
                      <button
                        type="button"
                        className="tp-product-details-action-sm-btn"
                      >
                        <i className="fa-regular fa-heart" />
                        Add Wishlist
                      </button>
                    </div>
                    <div className="tp-product-details-query">
                      <div className="tp-product-details-query-item d-flex align-items-center">
                        <span>Category:</span>
                        <p>{category.name}</p>
                      </div>
                      {/* <div className="tp-product-details-query-item d-flex align-items-center">
                        <span>Sub Category: </span>
                        <p>Sub Category 1</p>
                      </div> */}
                    </div>
                    <div className="tp-product-details-social">
                      <span>Share: </span>
                      <a href="#">
                        <i className="fa-brands fa-facebook-f" />
                      </a>
                      <a href="#">
                        <i className="fa-brands fa-twitter" />
                      </a>
                      <a href="#">
                        <i className="fa-brands fa-linkedin-in" />
                      </a>
                    </div>
                    <div className="tp-product-details-msg mb-15">
                      <ul>
                        <li>30 days easy returns</li>
                        <li>Order yours before 2.30pm for same day dispatch</li>
                      </ul>
                    </div>
                    {/* <div className="tp-product-details-payment d-flex align-items-center flex-wrap justify-content-between">
                      <p>
                        Guaranteed safe <br /> &amp; secure checkout
                      </p>
                      <img src="assets/img/payment-option.png" alt />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* product details area end */}
      </main>
    </>
  );
}
function ProductsCartList() {
  console.log("console");
  const navigate = useNavigate();
  const [user_id, setUser_id] = useState(
    window.localStorage.getItem("user_id")
      ? window.localStorage.getItem("user_id")
      : ""
  );
  const [randomString, setRandomString] = useState(
    window.localStorage.getItem("randomString")
      ? window.localStorage.getItem("randomString")
      : ""
  );
  const [cartlist, setCartList] = useState([]);
  const [cartprice, setCartPrice] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products_id, setProductsid] = useState("");

  function getProductscartList() {
    // if (user_id.length === 5) {
    //   navigate("/login");
    // } else {
    var url;
    if (user_id) {
      url =
        process.env.REACT_APP_API_URL +
        "products/cart/list?user_id=" +
        window.localStorage.getItem("user_id");
    } else {
      url =
        process.env.REACT_APP_API_URL +
        "products/cart/list?random_string=" +
        randomString;
    }
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setCartList(res.data.result.data);
          setCartPrice(res.data.result.totresult[0].total_price);
        } else {
          setCartList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  }

  useEffect(() => {
    getProductscartList();
  }, []);

  const ReactHtml = (data) => {
    const htmlContent = data;

    const divElement = document.createElement("div");
    divElement.innerHTML = htmlContent;
    const textContent = divElement.innerText;

    return (
      <div>
        {/* {textContent} */}
        {textContent.length > 180
          ? textContent.substring(0, 180) + "... Read More"
          : textContent}
      </div>
    );
  };

  function addTocartproducts(row) {
    const file_detailsd = new FormData();
    if (user_id) {
      file_detailsd.append("user_id", user_id);
    } else {
      file_detailsd.append("random_string", randomString);
    }
    file_detailsd.append("products", row.products._id);
    file_detailsd.append(
      "order_data_count",
      parseInt(row.order_data_count) + 1
    );
    axios
      .post(process.env.REACT_APP_API_URL + "products/add/cart", file_detailsd)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);
        toast.success("Product has been added to the cart successfully.");
        if (res.data.status === "success") {
          getProductscartList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function addTocartproductsless(row) {
    const file_detailsd = new FormData();
    if (user_id) {
      file_detailsd.append("user_id", user_id);
    } else {
      file_detailsd.append("random_string", randomString);
    }
    file_detailsd.append("products", row.products._id);
    file_detailsd.append(
      "order_data_count",
      parseInt(row.order_data_count) - 1
    );
    axios
      .post(process.env.REACT_APP_API_URL + "products/add/cart", file_detailsd)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);
        toast.success("Product has been added to the cart successfully.");
        if (res.data.status === "success") {
          getProductscartList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const generateRandomString = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 15; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };
  useEffect(() => {
    if (
      !window.localStorage.getItem("user_id") &&
      !window.localStorage.getItem("randomString")
    ) {
      const randomString = generateRandomString();
      window.localStorage.setItem("randomString", randomString);
      setRandomString(randomString);
    }
  }, []);

  function addTocartproductsDelete(row) {
    const file_detailsd = new FormData();
    file_detailsd.append("user_id", user_id);
    file_detailsd.append("cart_id", row._id);
    file_detailsd.append(
      "order_data_count",
      parseInt(row.order_data_count) - 1
    );
    axios
      .post(
        process.env.REACT_APP_API_URL + "products/cart/delete",
        file_detailsd
      )
      .then((res) => {
        console.log(res);
        console.log(res.data.message);
        toast.success("Add to cart has been deleted successfully.");
        if (res.data.status === "success") {
          getProductscartList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <main>
        {/* cart area start */}
        <section className="tp-cart-area pt-100 pb-120">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-8">
                <div className="tp-cart-list mb-25 mr-30 bg-white p-3">
                  <table className="table">
                    <thead>
                      <tr>
                        <th colSpan={2} className="tp-cart-header-product">
                          Product
                        </th>
                        <th className="tp-cart-header-price">Price</th>
                        <th className="tp-cart-header-quantity">Quantity</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {cartlist && cartlist.length > 0 ? (
                        cartlist.map((row) => (
                          <tr>
                            {/* img */}
                            <td className="tp-cart-img">
                              <a href="">
                                {" "}
                                <img src={row.products.logo} alt />
                              </a>
                            </td>
                            {/* title */}
                            <td className="tp-cart-title">
                              <a href="">{row.products.name}</a>
                            </td>
                            {/* price */}
                            <td className="tp-cart-price">
                              <span>${row.products.price / 100}.00</span>
                            </td>
                            {/* quantity */}
                            <td className="tp-cart-quantity">
                              <div className="tp-product-quantity mt-10 mb-10">
                                <span
                                  className="tp-cart-minus"
                                  onClick={() => {
                                    addTocartproductsless(row);
                                  }}
                                >
                                  <i className="fa-solid fa-minus" />
                                </span>
                                <input
                                  className="tp-cart-input"
                                  type="text"
                                  value={row.order_data_count}
                                />
                                <span
                                  className="tp-cart-plus"
                                  onClick={() => {
                                    addTocartproducts(row);
                                  }}
                                >
                                  <i className="fa-solid fa-plus" />
                                </span>
                              </div>
                            </td>
                            {/* action */}
                            <td className="tp-cart-action">
                              <a
                                href="#"
                                className="tp-cart-action-btn"
                                onClick={() => {
                                  addTocartproductsDelete(row);
                                }}
                              >
                                <i className="fa-regular fa-trash-can f_18" />
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <p>No Carts available.</p>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* <div className="tp-cart-bottom">
                  <div className="row align-items-end">
                    <div className="col-xl-6 col-md-8">
                      <div className="tp-cart-coupon">
                        <form action="#">
                          <div className="tp-cart-coupon-input-box">
                            <label>Coupon Code:</label>
                            <div className="tp-cart-coupon-input d-flex align-items-center">
                              <input
                                type="text"
                                placeholder="Enter Coupon Code"
                              />
                              <button type="submit">Apply</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-4">
                      <div className="tp-cart-update text-md-end pr-30">
                        <button type="button" className="tp-cart-update-btn">
                          Update Cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="tp-cart-checkout-wrapper">
                  <div className="tp-cart-checkout-top d-flex align-items-center justify-content-between">
                    <span className="tp-cart-checkout-top-title">Subtotal</span>
                    <span className="tp-cart-checkout-top-price">
                      ${cartprice / 100}
                    </span>
                  </div>
                  <div className="tp-cart-checkout-shipping">
                    <h4 className="tp-cart-checkout-shipping-title">
                      Shipping
                    </h4>
                    <div className="tp-cart-checkout-shipping-option-wrapper">
                      {/* <div className="tp-cart-checkout-shipping-option">
                        <input id="flat_rate" type="radio" name="shipping" />
                        <label htmlFor="flat_rate">
                          Flat rate: <span>$20.00</span>
                        </label>
                      </div>
                      <div className="tp-cart-checkout-shipping-option">
                        <input id="local_pickup" type="radio" name="shipping" />
                        <label htmlFor="local_pickup">
                          Local pickup: <span> $25.00</span>
                        </label>
                      </div> */}
                      <div className="tp-cart-checkout-shipping-option">
                        <input
                          id="free_shipping"
                          type="radio"
                          name="shipping"
                        />
                        <label htmlFor="free_shipping">Free shipping</label>
                      </div>
                    </div>
                  </div>
                  <div className="tp-cart-checkout-total d-flex align-items-center justify-content-between">
                    <span>Total</span>
                    <span>${cartprice / 100}</span>
                  </div>
                  <div
                    className="tp-cart-checkout-proceed"
                    onClick={() => {
                      if (cartlist.length !== 0) navigate("/checkout");
                    }}
                  >
                    <a className="tp-cart-checkout-btn w-100">
                      Proceed to Checkout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* cart area end */}
      </main>
    </>
  );
}
function ProductsCheckout() {
  console.log("console");
  const navigate = useNavigate();
  const [user_id, setUser_id] = useState(
    window.localStorage.getItem("user_id")
      ? window.localStorage.getItem("user_id")
      : "12345"
  );
  const [cartlist, setCartList] = useState([]);
  const [cartprice, setCartPrice] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products_id, setProductsid] = useState([]);
  //form
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [agree, setAgree] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip_code, setZipcode] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [street_address, setStreetAddress] = useState("");
  const [orderhistory, setOrderhistory] = useState([]);
  const [addressflag, setAddressflag] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState("");
  if (user_id.length === 5) {
    navigate("/login");
  }
  function getProductscartList() {
    if (user_id.length === 5) {
      navigate("/login");
    } else {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "products/cart/list?user_id=" +
            window.localStorage.getItem("user_id")
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === "success") {
            console.log("suceess");
            setCartList(res.data.result.data);
            setCartPrice(res.data.result.totresult[0].total_price);
            setProductsid(res.data.result.products);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    getProductscartList();
  }, []);

  const ReactHtml = (data) => {
    const htmlContent = data;

    const divElement = document.createElement("div");
    divElement.innerHTML = htmlContent;
    const textContent = divElement.innerText;

    return (
      <div>
        {/* {textContent} */}
        {textContent.length > 180
          ? textContent.substring(0, 180) + "... Read More"
          : textContent}
      </div>
    );
  };
  const addTocartpayment = () => {
    if (user_id.length === 5) {
      navigate("/login");
    } else {
      if (user_id) {
        const file_detailsd = new FormData();
        file_detailsd.append("user_id", user_id);
        file_detailsd.append("products", products_id.toString());
        file_detailsd.append("first_name", firstName);
        file_detailsd.append("last_name", lastName);
        file_detailsd.append("company_name", company);
        file_detailsd.append("country", country);
        file_detailsd.append("street_address", street_address);
        file_detailsd.append("city", city);
        file_detailsd.append("state", state);
        file_detailsd.append("zip_code", zip_code);
        file_detailsd.append("phone_number", phone);
        file_detailsd.append("email_address", email);
        console.log("file_detailsd", file_detailsd);

        axios
          .post(
            process.env.REACT_APP_API_URL + "products/payment/create",
            file_detailsd
          )
          .then((res) => {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess");
              if (res.data.result) {
                window.location.href = res.data.result.url;
                // toast.success(
                //   "Add to cart has been created successfully."
                // );
                // setProductsid(res.data.result.products);
              } else {
                // window.localStorage.setItem("cart_id", res.data.result._id);
                // console.log("/home");
                // toast.success("User has been created successfully.");
                // navigate("/home");
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const validateFormpage = () => {
    if (firstName.trim() === "") {
      toast.error("Please enter your first name.");
      return false;
    }

    if (lastName.trim() === "") {
      toast.error("Please enter your last name.");
      return false;
    }

    if (email.trim() === "") {
      toast.error("Please enter your email address.");
      return false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      toast.error("Please enter a valid email address.");
      return false;
    }

    if (phone.trim() === "") {
      toast.error("Please enter your phone number.");
      return false;
    } else if (!/^\d{10}$/.test(phone)) {
      toast.error("Please enter a valid 10-digit phone number.");
      return false;
    }

    if (!agree) {
      toast.error("Please agree to the website terms and conditions.");
      return false;
    }

    return true;
  };

  const validateForm = () => {
    if (selectedPayment) {
      if (!agree) {
        toast.error("Please agree to the website terms and conditions.");
        return false;
      }
      addTocartpayment();
    } else {
      if (validateFormpage()) {
        // Perform form submission or other logic
        addTocartpayment();
      }
    }
  };
  function getProductsorderhistory() {
    if (user_id.length === 5) {
      navigate("/login");
    } else {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "products/order/history?user_id=" +
            window.localStorage.getItem("user_id")
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === "success") {
            console.log("suceess", res.data.result.data);
            setOrderhistory(res.data.result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  useEffect(() => {
    getProductsorderhistory();
  }, []);
  function getProductsorderhistoryInfo(id) {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "products/order/history/info?user_id=" +
          window.localStorage.getItem("user_id") +
          "&purchase_id=" +
          id
      )
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess", res.data.result.data);

          setFirstName(res.data.result[0].products[0].first_name);
          setLastName(res.data.result[0].products[0].last_name);
          setCompany(res.data.result[0].products[0].company_name);
          setCountry(res.data.result[0].products[0].country);
          setStreetAddress(res.data.result[0].products[0].street_address);
          setCity(res.data.result[0].products[0].city);
          setState(res.data.result[0].products[0].state);
          setZipcode(res.data.result[0].products[0].zip_code);
          setPhone(res.data.result[0].products[0].phone_number);
          setEmail(res.data.result[0].products[0].email_address);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleShippingAddress() {
    console.log("test", addressflag);

    if (addressflag === false) {
      setSelectedPayment("");
      setAddressflag(true);
    } else {
      setAddressflag(false);
    }
  }
  const handlePaymentChange = (event) => {
    console.log("event.target.value", event.target.value);
    setSelectedPayment(event.target.value);
    getProductsorderhistoryInfo(event.target.value);
  };
  return (
    <>
      <main>
        {/* checkout area start */}
        <section
          className="tp-checkout-area pt-100 pb-120"
          data-bg-color="#EFF1F5"
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-7">
                <div className="tp-checkout-verify">
                  <div className="tp-checkout-verify-item">
                    <p className="tp-checkout-verify-reveal">
                      Returning customer?{" "}
                      <a
                        className="tp-checkout-login-form-reveal-btn"
                        href="/login"
                      >
                        Click here to login
                      </a>
                    </p>
                  </div>
                  {/* <div className="tp-checkout-verify-item">
              <p className="tp-checkout-verify-reveal">Have a coupon? <a className="tp-checkout-coupon-form-reveal-btn">Click here to enter your code</a></p>
              <div id="tpCheckoutCouponForm" className="tp-return-customer">
                <form action="#">
                  <div className="tp-return-customer-input">
                    <label>Coupon Code :</label>
                    <input type="text" placeholder="Coupon" />
                  </div>
                  <button type="submit" className="tp-return-customer-btn tp-checkout-btn">Apply</button>
                </form>
              </div>
            </div> */}
                </div>
              </div>
              <div className="col-lg-7">
                <div className="tp-checkout-bill-area">
                  <h3 className="tp-checkout-bill-title">Billing Details</h3>
                  <div>
                    <h6>Please choose the shipping address</h6>
                    <div className="address_shipping mt-3">
                      {orderhistory.map((row) => {
                        return (
                          <div
                            className="tp-checkout-payment-item"
                            key={row.products[0]._id}
                          >
                            <input
                              type="radio"
                              id={`payment_${row.products[0]._id}`}
                              name="payment"
                              value={row.products[0]._id}
                              checked={selectedPayment === row.products[0]._id}
                              onChange={handlePaymentChange}
                            />
                            <label htmlFor={`payment_${row.products[0]._id}`}>
                              {`${
                                row.products[0].first_name
                                  ? row.products[0].first_name + ", "
                                  : ""
                              }${
                                row.products[0].street_address
                                  ? row.products[0].street_address + ", "
                                  : ""
                              }${
                                row.products[0].city
                                  ? row.products[0].city + ", "
                                  : ""
                              }${
                                row.products[0].state
                                  ? row.products[0].state + ". "
                                  : ""
                              }${
                                row.products[0].phone_number
                                  ? row.products[0].phone_number
                                  : ""
                              }`}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    <div className="mb-25" align="right">
                      <div
                        className="f_yellow  crsr_pntr addnew_address"
                        onClick={handleShippingAddress}
                      >
                        Add new shipping address
                      </div>
                    </div>
                    <div
                      className="tp-checkout-bill-form"
                      id="new_address_frm"
                      style={{
                        display: addressflag === true ? "block" : "none",
                      }}
                    >
                      <form action="#">
                        <div className="tp-checkout-bill-inner">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="tp-checkout-input">
                                <label>
                                  First Name <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="First Name"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="tp-checkout-input">
                                <label>
                                  Last Name <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="Last Name"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="tp-checkout-input">
                                <label>Company name (optional)</label>
                                <input
                                  type="text"
                                  placeholder="Example LTD."
                                  value={company}
                                  onChange={(e) => setCompany(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="tp-checkout-input">
                                <label>Country / Region </label>
                                <input
                                  type="text"
                                  placeholder="United States (US)"
                                  value={country}
                                  onChange={(e) => setCountry(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="tp-checkout-input">
                                <label>Street address</label>
                                <input
                                  type="text"
                                  placeholder="House number and street name"
                                  value={street_address}
                                  onChange={(e) =>
                                    setStreetAddress(e.target.value)
                                  }
                                />
                              </div>
                              {/* <div className="tp-checkout-input">
                              <input
                                type="text"
                                placeholder="Apartment, suite, unit, etc. (optional)" value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </div> */}
                            </div>
                            <div className="col-md-12">
                              <div className="tp-checkout-input">
                                <label>Town / City</label>
                                <input
                                  type="text"
                                  placeholder
                                  value={city}
                                  onChange={(e) => setCity(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="tp-checkout-input">
                                <label>State / County</label>
                                <input
                                  type="text"
                                  placeholder
                                  value={state}
                                  onChange={(e) => setState(e.target.value)}
                                />
                                {/* <select>
                                <option>New York US</option>
                                <option>Berlin Germany</option>
                                <option>Paris France</option>
                                <option>Tokiyo Japan</option>
                              </select> */}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="tp-checkout-input">
                                <label>Postcode ZIP</label>
                                <input
                                  type="text"
                                  placeholder
                                  value={zip_code}
                                  onChange={(e) => setZipcode(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="tp-checkout-input">
                                <label>
                                  Phone <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="tp-checkout-input">
                                <label>
                                  Email address <span>*</span>
                                </label>
                                <input
                                  type="email"
                                  placeholder
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>
                            {/* <div className="col-md-12">
                            <div className="tp-checkout-option-wrapper">
                              <div className="tp-checkout-option">
                                <input
                                  id="create_free_account"
                                  type="checkbox"
                                />
                                <label htmlFor="create_free_account">
                                  Create an account?
                                </label>
                              </div>
                              <div className="tp-checkout-option">
                                <input
                                  id="ship_to_diff_address"
                                  type="checkbox"
                                  defaultValue={1}
                                  className="ship_different_address"
                                />
                                <label htmlFor="ship_to_diff_address">
                                  Ship to a different address?
                                </label>
                              </div>
                            </div>
                          </div> */}
                            {/* <div className="tp-checkout-bill-inner bg_grey p-3 ship_different_address_div">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="tp-checkout-input">
                                  <label>Country / Region </label>
                                  <input
                                    type="text"
                                    placeholder="United States (US)"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="tp-checkout-input">
                                  <label>Street address</label>
                                  <input
                                    type="text"
                                    placeholder="House number and street name"
                                  />
                                </div>
                                <div className="tp-checkout-input">
                                  <input
                                    type="text"
                                    placeholder="Apartment, suite, unit, etc. (optional)"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="tp-checkout-input">
                                  <label>Town / City</label>
                                  <input type="text" placeholder />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="tp-checkout-input">
                                  <label>State / County</label>
                                  <select>
                                    <option>New York US</option>
                                    <option>Berlin Germany</option>
                                    <option>Paris France</option>
                                    <option>Tokiyo Japan</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="tp-checkout-input">
                                  <label>Postcode ZIP</label>
                                  <input type="text" placeholder />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="tp-checkout-input">
                              <label>Order notes (optional)</label>
                              <textarea
                                placeholder="Notes about your order, e.g. special notes for delivery."
                                defaultValue={""}
                              />
                            </div>
                          </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                {/* checkout place order */}
                <div className="tp-checkout-place white-bg">
                  <h3 className="tp-checkout-place-title">Your Order</h3>
                  <div className="tp-order-info-list">
                    <ul>
                      {/* header */}
                      <li className="tp-order-info-list-header">
                        <h4>Product</h4>
                        <h4>Total</h4>
                      </li>
                      {/* item list */}
                      {cartlist.map((row) => {
                        return (
                          <li className="tp-order-info-list-desc">
                            <p>{row.products.name}.</p>
                            <span>${row.purchased_price / 100}:00</span>
                          </li>
                        );
                      })}

                      {/* subtotal */}
                      <li className="tp-order-info-list-subtotal">
                        <span>Subtotal</span>
                        <span>${cartprice / 100}.00</span>
                      </li>
                      {/* shipping */}
                      <li className="tp-order-info-list-shipping">
                        <span>Shipping</span>
                        <div className="tp-order-info-list-shipping-item d-flex flex-column align-items-end">
                          {/* <span>
                            <input
                              id="flat_rate"
                              type="radio"
                              name="shipping"
                            />
                            <label htmlFor="flat_rate">
                              Flat rate: <span>$20.00</span>
                            </label>
                          </span>
                          <span>
                            <input
                              id="local_pickup"
                              type="radio"
                              name="shipping"
                            />
                            <label htmlFor="local_pickup">
                              Local pickup: <span>$25.00</span>
                            </label>
                          </span> */}
                          <span>
                            <input
                              id="free_shipping"
                              type="radio"
                              name="shipping"
                            />
                            <label htmlFor="free_shipping">Free shipping</label>
                          </span>
                        </div>
                      </li>
                      {/* total */}
                      <li className="tp-order-info-list-total">
                        <span>Total</span>
                        <span>${cartprice / 100}.00</span>
                      </li>
                    </ul>
                  </div>
                  <div className="tp-checkout-payment">
                    <div className="tp-checkout-payment-item">
                      <input type="radio" id="back_transfer" name="payment" />
                      <label
                        htmlFor="back_transfer"
                        data-bs-toggle="direct-bank-transfer"
                      >
                        Direct Bank Transfer
                      </label>
                      <div className="tp-checkout-payment-desc direct-bank-transfer">
                        <p>
                          Make your payment directly into our bank account.
                          Please use your Order ID as the payment reference.
                          Your order will not be shipped until the funds have
                          cleared in our account.
                        </p>
                      </div>
                    </div>
                    {/* <div className="tp-checkout-payment-item">
                      <input type="radio" id="cheque_payment" name="payment" />
                      <label htmlFor="cheque_payment">Cheque Payment</label>
                      <div className="tp-checkout-payment-desc cheque-payment">
                        <p>
                          Make your payment directly into our bank account.
                          Please use your Order ID as the payment reference.
                          Your order will not be shipped until the funds have
                          cleared in our account.
                        </p>
                      </div>
                    </div>
                    <div className="tp-checkout-payment-item">
                      <input type="radio" id="cod" name="payment" />
                      <label htmlFor="cod">Cash on Delivery</label>
                      <div className="tp-checkout-payment-desc cash-on-delivery">
                        <p>
                          Make your payment directly into our bank account.
                          Please use your Order ID as the payment reference.
                          Your order will not be shipped until the funds have
                          cleared in our account.
                        </p>
                      </div>
                    </div>
                    <div className="tp-checkout-payment-item paypal-payment">
                      <input type="radio" id="paypal" name="payment" />
                      <label htmlFor="paypal">
                        PayPal{" "}
                        <img src="assets/img/icon/payment-option.png" alt />{" "}
                        <a href="#">What is PayPal?</a>
                      </label>
                    </div> */}
                  </div>
                  <div className="tp-checkout-agree">
                    <div className="tp-checkout-option">
                      <input
                        id="read_all"
                        type="checkbox"
                        placeholder
                        value={agree}
                        onClick={(e) => setAgree(e.target.value)}
                      />
                      <label htmlFor="read_all">
                        I have read and agree to the website.
                      </label>
                    </div>
                  </div>
                  <div
                    className="tp-checkout-btn-wrapper"
                    onClick={() => {
                      validateForm();
                    }}
                  >
                    <a href="#" className="tp-checkout-btn w-100">
                      Place Order
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </section>
        {/* checkout area end */}
      </main>
    </>
  );
}
function ProductsOrderHistory() {
  console.log("console");
  const navigate = useNavigate();
  const [user_id, setUser_id] = useState(
    window.localStorage.getItem("user_id")
      ? window.localStorage.getItem("user_id")
      : ""
  );
  const [orderhistory, setOrderhistory] = useState([]);

  if (!user_id) {
    navigate("/login");
  }
  function getProductsorderhistory() {
    if (user_id.length === 5) {
      navigate("/login");
    } else {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "products/order/history?user_id=" +
            window.localStorage.getItem("user_id")
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === "success") {
            console.log("suceess");
            setOrderhistory(res.data.result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    getProductsorderhistory();
  }, []);

  const ReactHtml = (data) => {
    const htmlContent = data;

    const divElement = document.createElement("div");
    divElement.innerHTML = htmlContent;
    const textContent = divElement.innerText;

    return (
      <div>
        {/* {textContent} */}
        {textContent.length > 180
          ? textContent.substring(0, 180) + "... Read More"
          : textContent}
      </div>
    );
  };

  return (
    <>
      <main>
        {/* order area start */}
        <section className="tp-order-area pt-100 pb-160">
          <div className="container">
            <div className="tp-order-inner">
              <div className="row gx-0">
                <div className="col-lg-12">
                  <div className="tp-order-info-wrapper">
                    <h4 className="tp-order-info-title">Order History</h4>
                    {orderhistory.map((row) => {
                      return (
                        <div className="mb-60">
                          <h5 className="f_bold mb-2">
                            Your Order Date : {row.updated_date}
                          </h5>
                          <div className="tp-cart-list">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th
                                    colSpan={2}
                                    className="tp-cart-header-product"
                                  >
                                    Product
                                  </th>
                                  <th className="tp-cart-header-price txtalgn_rgt">
                                    Price
                                  </th>
                                  <th className="tp-cart-header-quantity txtalgn_rgt">
                                    Quantity
                                  </th>
                                  <th className="tp-cart-header-price txtalgn_rgt pe-2">
                                    Total
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {row.products.map((data) => {
                                  return (
                                    <tr>
                                      {/* img */}
                                      <td className="tp-cart-img">
                                        <a href="product_details.html">
                                          {" "}
                                          <img src={data.products.logo} alt />
                                        </a>
                                      </td>
                                      {/* title */}
                                      <td className="tp-cart-title">
                                        <a href="product_details.html">
                                          {data.products.name}
                                        </a>
                                      </td>
                                      {/* price */}
                                      <td className="tp-cart-price txtalgn_rgt">
                                        <span>
                                          ${data.products.price / 100}.00
                                        </span>
                                      </td>
                                      {/* quantity */}
                                      <td className="tp-cart-quantity txtalgn_rgt">
                                        <span>{data.order_data_count}</span>
                                      </td>
                                      {/* total */}
                                      <td className="tp-cart-price txtalgn_rgt">
                                        <span>
                                          ${data.purchased_price / 100}.00
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <th colSpan={4}>
                                    <h5 className="mt-2 txtalgn_rgt">Total</h5>
                                  </th>
                                  <th className="txtalgn_rgt">
                                    <h5 className="mt-2 f_yellow">
                                      ${row.total_price / 100}.00
                                    </h5>
                                  </th>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* order area end */}
      </main>
    </>
  );
}
function ProductsWishList() {
  console.log("console");
  const navigate = useNavigate();
  const [user_id, setUser_id] = useState(
    window.localStorage.getItem("user_id")
      ? window.localStorage.getItem("user_id")
      : ""
  );
  const [randomString, setRandomString] = useState(
    window.localStorage.getItem("randomString")
      ? window.localStorage.getItem("randomString")
      : ""
  );
  const [cartlist, setCartList] = useState([]);
  const [cartprice, setCartPrice] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products_id, setProductsid] = useState("");

  function getProductscartList() {
    // if (user_id.length === 5) {
    //   navigate("/login");
    // } else {
    var url;
    if (user_id) {
      url =
        process.env.REACT_APP_API_URL +
        "products/wish/list?user_id=" +
        window.localStorage.getItem("user_id");
    } else {
      url =
        process.env.REACT_APP_API_URL +
        "products/wish/list?random_string=" +
        randomString;
    }
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setCartList(res.data.result.data);
          setCartPrice(res.data.result.totresult[0].total_price);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  }

  useEffect(() => {
    getProductscartList();
  }, []);

  const ReactHtml = (data) => {
    const htmlContent = data;

    const divElement = document.createElement("div");
    divElement.innerHTML = htmlContent;
    const textContent = divElement.innerText;

    return (
      <div>
        {/* {textContent} */}
        {textContent.length > 180
          ? textContent.substring(0, 180) + "... Read More"
          : textContent}
      </div>
    );
  };

  function addTowishproducts(row) {
    const file_detailsd = new FormData();
    if (user_id) {
      file_detailsd.append("user_id", user_id);
    } else {
      file_detailsd.append("random_string", randomString);
    }
    file_detailsd.append("products", row.products._id);
    file_detailsd.append(
      "order_data_count",
      parseInt(row.order_data_count) + 1
    );
    axios
      .post(
        process.env.REACT_APP_API_URL + "products/add/wishlist",
        file_detailsd
      )
      .then((res) => {
        console.log(res);
        console.log(res.data.message);
        toast.success(res.data.message);
        if (res.data.status === "success") {
          getProductscartList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function addTocartproducts(row) {
    const file_detailsd = new FormData();
    if (user_id) {
      file_detailsd.append("user_id", user_id);
    } else {
      file_detailsd.append("random_string", randomString);
    }
    file_detailsd.append("products", row.products._id);
    file_detailsd.append(
      "order_data_count",
      parseInt(row.order_data_count) + 1
    );
    axios
      .post(process.env.REACT_APP_API_URL + "products/add/cart", file_detailsd)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);
        toast.success("Product has been added to the cart successfully.");
        if (res.data.status === "success") {
          getProductscartList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function addTocartproductsless(row) {
    const file_detailsd = new FormData();
    if (user_id) {
      file_detailsd.append("user_id", user_id);
    } else {
      file_detailsd.append("random_string", randomString);
    }
    file_detailsd.append("products", row.products._id);
    file_detailsd.append(
      "order_data_count",
      parseInt(row.order_data_count) - 1
    );
    axios
      .post(
        process.env.REACT_APP_API_URL + "products/add/wishlist",
        file_detailsd
      )
      .then((res) => {
        console.log(res);
        console.log(res.data.message);
        toast.success("Add to cart has been created successfully.");
        if (res.data.status === "success") {
          getProductscartList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const generateRandomString = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 15; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };
  useEffect(() => {
    if (
      !window.localStorage.getItem("user_id") &&
      !window.localStorage.getItem("randomString")
    ) {
      const randomString = generateRandomString();
      window.localStorage.setItem("randomString", randomString);
      setRandomString(randomString);
    }
  }, []);

  function addTocartproductsDelete(row) {
    const file_detailsd = new FormData();
    file_detailsd.append("user_id", user_id);
    file_detailsd.append("cart_id", row._id);
    file_detailsd.append(
      "order_data_count",
      parseInt(row.order_data_count) - 1
    );
    axios
      .post(
        process.env.REACT_APP_API_URL + "products/cart/delete",
        file_detailsd
      )
      .then((res) => {
        console.log(res);
        console.log(res.data.message);
        toast.success("Add to cart has been deleted successfully.");
        if (res.data.status === "success") {
          getProductscartList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <main>
        {/* cart area start */}
        <section className="tp-cart-area pt-100 pb-120">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="tp-cart-list mb-25 mr-30 bg-white p-3">
                  <table className="table">
                    <thead>
                      <tr>
                        <th colSpan={2} className="tp-cart-header-product">
                          Product
                        </th>
                        <th className="tp-cart-header-price">Price</th>
                        <th className="tp-cart-header-quantity">Quantity</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {cartlist.map((row) => {
                        return (
                          <tr>
                            {/* img */}
                            <td className="tp-cart-img">
                              <a href="">
                                {" "}
                                <img src={row.products.logo} alt />
                              </a>
                            </td>
                            {/* title */}
                            <td className="tp-cart-title">
                              <a href="">{row.products.name}</a>
                            </td>
                            {/* price */}
                            <td className="tp-cart-price">
                              <span>${row.purchased_price / 100}.00</span>
                            </td>
                            {/* quantity */}
                            <td className="tp-cart-quantity">
                              <div className="tp-product-quantity mt-10 mb-10">
                                <span
                                  className="tp-cart-minus"
                                  onClick={() => {
                                    addTocartproductsless(row);
                                  }}
                                >
                                  <i className="fa-solid fa-minus" />
                                </span>
                                <input
                                  className="tp-cart-input"
                                  type="text"
                                  value={row.order_data_count}
                                />
                                <span
                                  className="tp-cart-plus"
                                  onClick={() => {
                                    addTowishproducts(row);
                                  }}
                                >
                                  <i className="fa-solid fa-plus" />
                                </span>
                              </div>
                            </td>
                            <td class="tp-cart-add-to-cart">
                              <a
                                href="#"
                                class="tp-btn tp-btn-2 tp-btn-blue"
                                onClick={() => {
                                  addTocartproducts(row);
                                }}
                              >
                                Add To Cart
                              </a>
                            </td>
                            {/* action */}
                            <td className="tp-cart-action">
                              <a
                                href="#"
                                className="tp-cart-action-btn"
                                onClick={() => {
                                  addTocartproductsDelete(row);
                                }}
                              >
                                <i className="fa-regular fa-trash-can f_18" />
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* <div className="tp-cart-bottom">
                  <div className="row align-items-end">
                    <div className="col-xl-6 col-md-8">
                      <div className="tp-cart-coupon">
                        <form action="#">
                          <div className="tp-cart-coupon-input-box">
                            <label>Coupon Code:</label>
                            <div className="tp-cart-coupon-input d-flex align-items-center">
                              <input
                                type="text"
                                placeholder="Enter Coupon Code"
                              />
                              <button type="submit">Apply</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-4">
                      <div className="tp-cart-update text-md-end pr-30">
                        <button type="button" className="tp-cart-update-btn">
                          Update Cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* cart area end */}
      </main>
    </>
  );
}
export {
  ProductsList,
  ProdClaims,
  ProductsCartList,
  ProductsCheckout,
  ProductsOrderHistory,
  ProductsWishList,
};

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import bannerImg from "../assets/images_/p5.png";
import samImg from "../assets/images_/products/default_blog_image.png";
import Carousel from "react-multi-carousel";
// import Carousel from "better-react-carousel";
import "react-multi-carousel/lib/styles.css";
import "./additionalCSS.css";
import { ReactSession } from "react-client-session";
import { ShowDetail } from "./commonComp";
import MediaComponent from "./mediafile";
import nav1 from "../assets/img/product/details/nav/product-details-nav-1.jpg";
import nav2 from "../assets/img/product/details/nav/product-details-nav-2.jpg";
import nav3 from "../assets/img/product/details/nav/product-details-nav-3.jpg";
import nav4 from "../assets/img/product/details/nav/product-details-nav-4.jpg";
import nav5 from "../assets/img/product/details/nav/product-details-nav-5.jpg";
import productsmainpage from "../assets/img/product/details/main/product-details-main-1.jpg";
import paymentOption from "../assets/img/product/icons/payment-option.png";
import productRelated from "../assets/img/product/related/product-related-1.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function HeadBanner(props) {
  return (
    <div className="banner_mrg_top">
      <img width="100%" src={props.imgUrl} className="img-fluid" />
      <div className="m-4">
        <h2 className="text-center pt-4 pb-3">{props.headerContent}</h2>
      </div>
    </div>
  );
}

function Contact() {
  const navigate = useNavigate();

  //   const [email_address, setEmail] = useState("");
  //   const [first_name, setFirstName] = useState("");
  //   const [subject, setSubject] = useState("");
  //   const [message, setMessage] = useState("");
  //   const [category_id, setCatory] = useState("");
  //   const [check, setCheck] = useState("");
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    selectedCategory: "",
    remember: false,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  //   const handleChangeemail = (e) => {
  //     setEmail(e.target.value);
  //     console.log(e.target.value);
  //   };

  //   const handleChange = (e) => {
  //     setCategories(e.target.value);
  //     console.log(e.target.value);
  //   };
  //   const validateEmail = (email) => {
  //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     return emailRegex.test(email);
  //   };
  //   const handleFirstname = (e) => {
  //     setFirstName(e.target.value);
  //     console.log(e.target.value);
  //   };
  //   const handleMessage = (e) => {
  //     setMessage(e.target.value);
  //     console.log(e.target.value);
  //   };
  //   const handleSubject = (e) => {
  //     setSubject(e.target.value);
  //     console.log(e.target.value);
  //   };
  //   const handlecheck = (e) => {
  //     setCheck(e.target.value);
  //     console.log(e.target.value);
  //   };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    // Extract form data from state
    const { email, first_name, message, selectedCategory, subject, remember } =
      formData;
    console.log("form ", formData);
    if (email.trim() === "") {
      toast.error("Email is required");
      return;
    } else if (!validateEmail(email)) {
      toast.error("Invalid email address");
      return;
    }
    // Create a FormData object
    const formDataToSend = new FormData();
    formDataToSend.append("email_address", email);
    formDataToSend.append("first_name", first_name);
    formDataToSend.append("message", message);
    formDataToSend.append("category_id", selectedCategory);
    formDataToSend.append("subject", subject);

    // Send the form data to the server using Axios
    axios
      .post(
        process.env.REACT_APP_API_URL + "user/contact/create",
        formDataToSend
      )
      .then((res) => {
        console.log(res.data.message);

        if (res.data.status === "success") {
          // Handle success case, e.g., show a success message to the user
        }
      })
      .catch((err) => {
        console.log(err);
        // Handle error case, e.g., show an error message to the user
      });
  };
  function getCategories() {
    axios
      .get(process.env.REACT_APP_API_URL + "categories/list")
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          console.log("suceess");
          setCategories(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getCategories();
  }, []);
  return (
    <>
      <main>
        {/* contact area start */}
        <section className="tp-contact-area pt-100 pb-100">
          <div className="container">
            <div className="tp-contact-inner">
              <div className="row">
                <div className="col-xl-9 col-lg-8">
                  <div className="tp-contact-wrapper">
                    <h3 className="tp-contact-title">Send A Message</h3>
                    <div className="tp-contact-form">
                      <form onSubmit={handleSubmit}>
                        <div className="tp-contact-input-wrapper">
                          {/* Name */}
                          <div className="tp-contact-input-box">
                            <div className="tp-contact-input">
                              <input
                                name="name"
                                id="name"
                                type="text"
                                value={formData.name}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="tp-contact-input-title">
                              <label htmlFor="name">Your Name</label>
                            </div>
                          </div>

                          {/* Email */}
                          <div className="tp-contact-input-box">
                            <div className="tp-contact-input">
                              <input
                                name="email"
                                id="email"
                                type="email"
                                placeholder="example@mail.com"
                                value={formData.email}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="tp-contact-input-title">
                              <label htmlFor="email">Your Email</label>
                            </div>
                          </div>

                          {/* Subject */}
                          <div className="tp-contact-input-box">
                            <div className="tp-contact-input">
                              <input
                                name="subject"
                                id="subject"
                                type="text"
                                placeholder="Write your subject"
                                value={formData.subject}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="tp-contact-input-title">
                              <label htmlFor="subject">Subject</label>
                            </div>
                          </div>

                          {/* Message */}
                          <div className="tp-contact-input-box">
                            <div className="tp-contact-input">
                              <textarea
                                id="message"
                                name="message"
                                placeholder="Write your message here..."
                                value={formData.message}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="tp-contact-input-title">
                              <label htmlFor="message">Your Message</label>
                            </div>
                          </div>

                          {/* Category */}
                          <div className="tp-contact-input-box">
                            <div className="tp-contact-input">
                              <select
                                name="selectedCategory"
                                onChange={handleChange}
                                value={formData.selectedCategory}
                              >
                                <option value="">Select value</option>
                                {categories.map((item) => (
                                  <option key={item._id} value={item._id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="tp-contact-input-title">
                              <label htmlFor="selectedCategory">Category</label>
                            </div>
                          </div>
                        </div>

                        {/* Remember */}
                        <div className="tp-contact-suggetions mb-20">
                          <div className="tp-contact-remeber">
                            <input
                              id="remeber"
                              name="remember"
                              type="checkbox"
                              checked={formData.remember}
                              onChange={handleChange}
                            />
                            <label htmlFor="remeber">
                              Save my name, email, and website in this browser
                              for the next time I comment.
                            </label>
                          </div>
                        </div>

                        {/* Submit Button */}
                        <div className="tp-contact-btn">
                          <button type="submit">Send Message</button>
                        </div>
                      </form>
                      <p className="ajax-response" />
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4">
                  <div className="tp-contact-info-wrapper">
                    <div className="tp-contact-info-item">
                      <div className="tp-contact-info-icon">
                        <span>
                          <i className="fa-regular fa-envelope f_30 f_orange" />
                        </span>
                      </div>
                      <div className="tp-contact-info-content">
                        <p data-info="mail">
                          <a href="mailto:test@hellalit.com">
                            test@hellalit.com
                          </a>
                        </p>
                        <p data-info="phone">
                          <a href="tel:670-413-90-762">+670 413 90 762</a>
                        </p>
                      </div>
                    </div>
                    <div className="tp-contact-info-item">
                      <div className="tp-contact-info-icon">
                        <span>
                          <i className="fa-regular fa-map f_30 f_orange" />
                        </span>
                      </div>
                      <div className="tp-contact-info-content">
                        <p>
                          <a
                            href="https://www.google.com/maps/place/New+York,+NY,+USA/@40.6976637,-74.1197638,11z/data=!3m1!4b1!4m6!3m5!1s0x89c24fa5d33f083b:0xc80b8f06e177fe62!8m2!3d40.7127753!4d-74.0059728!16zL20vMDJfMjg2"
                            target="_blank"
                          >
                            84 Sleepy Hollow St. <br /> Jamaica, New York 1432
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="tp-contact-info-item">
                      <div className="tp-contact-info-icon">
                        <span>
                          <i className="fa-solid fa-globe f_30 f_orange" />
                        </span>
                      </div>
                      <div className="tp-contact-info-content">
                        <div className="tp-contact-social-wrapper mt-5">
                          <h4 className="tp-contact-social-title">
                            Find on social media
                          </h4>
                          <div className="tp-contact-social-icon">
                            <a href="#">
                              <i className="fa-brands fa-facebook-f" />
                            </a>
                            <a href="#">
                              <i className="fa-brands fa-twitter" />
                            </a>
                            <a href="#">
                              <i className="fa-brands fa-linkedin-in" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </section>
        {/* contact area end */}
        {/* map area start */}
        {/* <section className="tp-map-area pb-120">
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="tp-map-wrapper">
            <div className="tp-map-hotspot">
              <span className="tp-hotspot tp-pulse-border">
                <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx={6} cy={6} r={6} fill="#821F40" />
                </svg>
              </span>
            </div>
            <div className="tp-map-iframe">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15830894612!2d-74.11976383964465!3d40.69766374865766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1678114595329!5m2!1sen!2sbd" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
        {/* map area end */}
      </main>
    </>
  );
}

export { Contact };

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import bannerImg from "../assets/images_/p5.png";
import samImg from "../assets/images_/products/default_blog_image.png";
import Carousel from "react-multi-carousel";
// import Carousel from "better-react-carousel";
import "react-multi-carousel/lib/styles.css";
import "./additionalCSS.css";
import { ReactSession } from "react-client-session";
import { ShowDetail } from "./commonComp";
import MediaComponent from "./mediafile";
import nav1 from "../assets/img/product/details/nav/product-details-nav-1.jpg";
import nav2 from "../assets/img/product/details/nav/product-details-nav-2.jpg";
import nav3 from "../assets/img/product/details/nav/product-details-nav-3.jpg";
import nav4 from "../assets/img/product/details/nav/product-details-nav-4.jpg";
import nav5 from "../assets/img/product/details/nav/product-details-nav-5.jpg";
import productsmainpage from "../assets/img/product/details/main/product-details-main-1.jpg";
import paymentOption from "../assets/img/product/icons/payment-option.png";
import productRelated from "../assets/img/product/related/product-related-1.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function HeadBanner(props) {
  return (
    <div className="banner_mrg_top">
      <img width="100%" src={props.imgUrl} className="img-fluid" />
      <div className="m-4">
        <h2 className="text-center pt-4 pb-3">{props.headerContent}</h2>
      </div>
    </div>
  );
}

function SignIn() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleChangeemail = (e) => {
    setEmail(e.target.value);
    console.log(e.target.value);
  };
  const handleChangepwd = (e) => {
    setPassword(e.target.value);
    console.log(e.target.value);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSignin = (e) => {
    // Validate email field
    if (email.trim() === "") {
      toast.error("Email is required");
      return;
    } else if (!validateEmail(email)) {
      toast.error("Invalid email address");
      return;
    }

    // Validate password field
    if (password.trim() === "") {
      toast.error("Password is required");
      return;
    } else if (password.length < 6) {
      toast.error("Password should be at least 6 characters long");
      return;
    }

    console.log(e.target.files);
    const file_detailsd = new FormData();
    file_detailsd.append("email_address", email);
    file_detailsd.append("password", password);
    axios
      .post(process.env.REACT_APP_API_URL + "user/login", file_detailsd)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == "success") {
          window.localStorage.setItem("email_address", email);
          window.localStorage.setItem("user_id", res.data.result._id);
          console.log("/home");
          toast.success("User has been login successfully.");
          navigate("/home");
          const file_detailsd = new FormData();

          file_detailsd.append("user_id", res.data.result._id);

          file_detailsd.append(
            "random_string",
            window.localStorage.getItem("randomString")
          );
          axios
            .post(
              process.env.REACT_APP_API_URL + "products/update/cart",
              file_detailsd
            )
            .then((res) => {
              console.log(res);
              console.log(res.data.message);
              toast.success("Add to cart has been updated successfully.");
              if (res.data.status === "success") {
                window.localStorage.setItem("randomString", "");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <main>
        {/* login area start */}
        <section className="tp-login-area pt-100 pb-140 p-relative fix">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="tp-login-wrapper">
                  <div className="tp-login-top text-center mb-30">
                    <h3 className="tp-login-title">Login</h3>
                    <p>
                      Don't have an account?{" "}
                      <span>
                        <a href="/signup">Create a free account</a>
                      </span>
                    </p>
                  </div>
                  <div className="tp-login-option">
                    <div className="tp-login-input-wrapper">
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            onChange={handleChangeemail}
                            id="email"
                            type="email"
                            placeholder="example@mail.com"
                          />
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="email">Your Email</label>
                        </div>
                      </div>
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            onChange={handleChangepwd}
                            id="tp_password"
                            type="password"
                            placeholder="Min. 6 character"
                          />
                        </div>
                        <div
                          className="tp-login-input-eye"
                          id="password-show-toggle"
                        >
                          <span id="open-eye" className="open-eye">
                            <i className="fa-solid fa-eye" />
                          </span>
                          <span id="close-eye" className="open-close">
                            <i className="fa-solid fa-eye-slash" />
                          </span>
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="tp_password">Password</label>
                        </div>
                      </div>
                    </div>
                    <div className="tp-login-suggetions d-sm-flex align-items-center justify-content-between mb-20">
                      <div className="tp-login-remeber">
                        <input id="remeber" type="checkbox" />
                        <label htmlFor="remeber">Remember me</label>
                      </div>
                      <div className="tp-login-forgot">
                        <a href="/forgot" className="f_yellow">
                          Forgot Password?
                        </a>
                      </div>
                    </div>
                    <div className="tp-login-bottom" onClick={handleSignin}>
                      <a href="#" className="tp-login-btn w-100">
                        Login
                      </a>
                    </div>
                    {/* <div className="tp-login-mail text-center mt-40 mb-40">
                      <p>or</p>
                    </div> */}
                    {/* <div className="tp-login-social mb-10 d-flex flex-wrap align-items-center justify-content-center">
                      <div className="tp-login-option-item">
                        <a href="#" className="px-4">
                          <img
                            src="assets/img/google.svg"
                            alt
                            className="mr-5"
                          />
                          Google
                        </a>
                      </div>
                      <div className="tp-login-option-item">
                        <a href="#" className="px-4">
                          <i className="fa-brands fa-facebook mr-5 fb_color f_24" />{" "}
                          Facebook
                        </a>
                      </div>
                      <div className="tp-login-option-item">
                        <a href="#" className="px-4">
                          <i className="fa-brands fa-apple mr-5 apple_color f_24" />{" "}
                          Apple
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </section>
        {/* login area end */}
      </main>
    </>
  );
}
function Forgot() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const handleChangeemail = (e) => {
    setEmail(e.target.value);
    console.log(e.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleForgot = (e) => {
    // Validate email field
    if (email.trim() === "") {
      toast.error("Email is required");
      return;
    } else if (!validateEmail(email)) {
      toast.error("Invalid email address");
      return;
    }

    console.log(e.target.files);
    const file_detailsd = new FormData();
    file_detailsd.append("email_address", email);
    axios
      .post(
        process.env.REACT_APP_API_URL + "user/password/forgot",
        file_detailsd
      )
      .then((res) => {
        console.log(res);
        console.log(res.data.message);
        if (res.data.status == "success") {
          toast.success("Mail sent successfully.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <main>
        {/* login area start */}
        <section className="tp-login-area pt-100 pb-140 p-relative fix">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="tp-login-wrapper">
                  <div className="tp-login-top text-center mb-30">
                    <h3 className="tp-login-title">Forgot Password</h3>
                  </div>
                  <div className="tp-login-option">
                    <div className="tp-login-input-wrapper">
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            onChange={handleChangeemail}
                            id="email"
                            type="email"
                            placeholder="example@mail.com"
                          />
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="email">Your Email</label>
                        </div>
                      </div>
                    </div>

                    <div className="tp-login-bottom" onClick={handleForgot}>
                      <a href="#" className="tp-login-btn w-100">
                        Submit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </section>
        {/* login area end */}
      </main>
    </>
  );
}
function PasswordReset() {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [user_id, setUserId] = useState("");

  useEffect(() => {
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Get the value of the 'user_id' parameter
    const userId = urlParams.get("user_id");

    // Now you can use the 'userId' in your component as needed
    console.log("user_id:", userId);
    setUserId(userId);
    // ... rest of your component code
  }, []);
  const handleChangeconfirmpwd = (e) => {
    setConfirmPassword(e.target.value);
    console.log(e.target.value);
  };
  const handleChangepwd = (e) => {
    setPassword(e.target.value);
    console.log(e.target.value);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleReset = (e) => {
    // Validate password field
    if (password.trim() === "") {
      toast.error("Password is required");
      return;
    } else if (password.length < 6) {
      toast.error("Password should be at least 6 characters long");
      return;
    }

    // Validate confirm password field
    if (confirmPassword.trim() === "") {
      toast.error("Confirm Password is required");
      return;
    } else if (confirmPassword !== password) {
      toast.error("Passwords do not match");
      return;
    }
    console.log(e.target.files);
    const file_detailsd = new FormData();
    file_detailsd.append("user_id", user_id);
    file_detailsd.append("password", password);
    axios
      .post(
        process.env.REACT_APP_API_URL + "user/password/reset",
        file_detailsd
      )
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == "success") {
          toast.success(res.data.message);
          // window.localStorage.setItem("email_address", email);
          // window.localStorage.setItem("user_id", res.data.result._id);
          // console.log("/home");
          // toast.success("User has been login successfully.");
          // navigate("/home");
          // const file_detailsd = new FormData();

          // file_detailsd.append("user_id", res.data.result._id);

          // file_detailsd.append(
          //   "random_string",
          //   window.localStorage.getItem("randomString")
          // );
          // axios
          //   .post(
          //     process.env.REACT_APP_API_URL + "products/update/cart",
          //     file_detailsd
          //   )
          //   .then((res) => {
          //     console.log(res);
          //     console.log(res.data.message);
          //     toast.success("Add to cart has been updated successfully.");
          //     if (res.data.status === "success") {
          //       window.localStorage.setItem("randomString", "");
          //     }
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <main>
        {/* login area start */}
        <section className="tp-login-area pt-100 pb-140 p-relative fix">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="tp-login-wrapper">
                  <div className="tp-login-top text-center mb-30">
                    <h3 className="tp-login-title">Reset Password</h3>
                  </div>
                  <div className="tp-login-option">
                    <div className="tp-login-input-wrapper">
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            onChange={handleChangeconfirmpwd}
                            id="tp_password"
                            type="password"
                            placeholder="Min. 6 character"
                          />
                        </div>

                        <div className="tp-login-input-title">
                          <label htmlFor="tp_password">Password</label>
                        </div>
                      </div>
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            onChange={handleChangepwd}
                            id="tp_password"
                            type="password"
                            placeholder="Min. 6 character"
                          />
                        </div>

                        <div className="tp-login-input-title">
                          <label htmlFor="tp_password">Confirm Password</label>
                        </div>
                      </div>
                    </div>

                    <div className="tp-login-bottom" onClick={handleReset}>
                      <a href="#" className="tp-login-btn w-100">
                        Submit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </section>
        {/* login area end */}
      </main>
    </>
  );
}
export { SignIn, Forgot, PasswordReset };

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./layout/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./App.scss";
import Header from "./layout/header";
import Footer from "./layout/footer";
import NavigationBar from "./layout/NavigationBar";
import { Home, UpdateWeb } from "./components/home";
import GeoLoc from "./components/geoloc";
import { Dispenssaries, DispensariesDetail } from "./components/dispensaries";
import React, { useState, useEffect } from "react";
import { ThingsContext } from "./components/thingsContext";
import Maps from "./components/Maps";
// import Autocomplete from "./components/autocomplete";
import { ReactSession } from "react-client-session";
import { Deliveries, DeliveriesDetail } from "./components/deliveries";
import { Brands, BrandsDetail } from "./components/brands";
import { Deals, Claim } from "./components/deals";
// import Gmap from "./components/Gmap";
import GoogleMapComponent from "./components/GoogleMapComponent";
import { Blogs, Claims } from "./components/blog";
import {
  ProductsList,
  ProdClaims,
  ProductsCartList,
  ProductsCheckout,
  ProductsOrderHistory,
  ProductsWishList,
} from "./components/products";
import { SignUp } from "./components/signup";
import { SignIn, Forgot, PasswordReset } from "./components/login";
import { Profile, Invoice } from "./components/profile";
import { PdfGenerator } from "./components/pdf";
import { Contact } from "./components/contact";
import { CouponList } from "./components/coupon";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    // Check if the user is already logged in (e.g., check token or authentication data in local storage)
    const isAuthenticated = checkUserAuthentication();
    setIsLoggedIn(isAuthenticated);
  }, []);
  const checkUserAuthentication = () => {
    // Example: Check if a token exists in local storage
    const token = window.localStorage.getItem("user_id");
    return !!token; // Return true if the token exists
  };
  console.log("isLoggedIn", isLoggedIn);
  // if(window.location.pathname.includes('map')){
  //     window.addEventListener( "pageshow", function ( event ) {
  //         var historyTraversal = event.persisted ||
  //                                 ( typeof window.performance != "undefined" &&
  //                                     window.performance.navigation.type === 2 );
  //         if ( historyTraversal ) {
  //             console.log('asdasd')
  //             // Handle page restore.
  //         window.location.reload(true);
  //         }
  //     });
  // }

  // React.useEffect(() => {
  //     console.log('Strin')
  //     //   window.onpageshow = function(event) {
  //     //     console.log(event)
  //     //   if (event.persisted) {
  //     //     console.log('asdasda');
  //     //     // window.location.reload();
  //     //   }
  //     // };
  //     window.addEventListener( "pageshow", function ( event ) {
  //         var historyTraversal = event.persisted ||
  //                                ( typeof window.performance != "undefined" &&
  //                                     window.performance.navigation.type === 2 );
  //         if ( historyTraversal ) {
  //         console.log('asdasda');

  //           // Handle page restore.
  //         //   window.location.reload();
  //         }
  //       });
  // })
  ReactSession.setStoreType("localStorage");

  if (ReactSession.get("userlocation")) {
    if (ReactSession.get("userlocation") === "Oakland") {
      ReactSession.set("searchLocationBetween", false);
      ReactSession.set("userCurrentLocationFlag", false);
      ReactSession.set("userlocation", "Oakland, CA, USA");
      ReactSession.set("userlocationLat", 37.8043514);
      ReactSession.set("userlocationLng", -122.2711639);
      ReactSession.set("userlocationVP", {
        south: 37.63206722383037,
        west: -122.3558275667913,
        north: 37.88536788927048,
        east: -122.1147882148516,
      });
      ReactSession.set("userlocationDetails", {
        city: "Oakland",
        state: "California",
        country: "United States",
      });
    }
  } else {
    ReactSession.set("searchLocationBetween", false);
    ReactSession.set("userCurrentLocationFlag", false);
    ReactSession.set("userlocation", "San Jose, CA, USA");
    ReactSession.set("userlocationLat", 37.2788996);
    ReactSession.set("userlocationLng", -121.8528456);
    ReactSession.set("userlocationVP", {
      south: 37.12271341474762,
      west: -122.0460552255236,
      north: 37.46921102532504,
      east: -121.5861371630509,
    });
    ReactSession.set("userlocationDetails", {
      city: "San Jose",
      state: "California",
      country: "United States",
    });
  }

  return (
    <ThingsContext.Provider value={ReactSession.get("userlocation")}>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/updateWeb" element={<UpdateWeb />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/map" element={<GoogleMapComponent />} />
          <Route path="/dispensaries" element={<Dispenssaries />} />
          <Route path="/deliveries" element={<Deliveries />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/deals" element={<Deals />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/products" element={<ProductsList />} />
          <Route path="/deals/claim" element={<Claim />} />
          <Route path="/blog/claims" element={<Claims />} />
          <Route path="/products/prodclaims" element={<ProdClaims />} />

          <Route path="/dispensaries/detail" element={<DispensariesDetail />} />
          <Route path="/deliveries/detail" element={<DeliveriesDetail />} />
          <Route path="/brands/detail" element={<BrandsDetail />} />
          {/* {isLoggedIn ? (
            <Route path="/home" element={<Home />} />
          ) : (
            <Route path="/signup" element={<SignUp />} />
          )}
          {isLoggedIn ? (
            <Route path="/home" element={<Home />} />
          ) : (
            <Route path="/login" element={<SignIn />} />
          )} */}

          <Route path="/signup" element={isLoggedIn ? <Home /> : <SignUp />} />
          <Route path="/login" element={isLoggedIn ? <Home /> : <SignIn />} />
          <Route path="/forgot" element={isLoggedIn ? <Home /> : <Forgot />} />
          <Route
            path="/reset-password"
            element={isLoggedIn ? <Home /> : <PasswordReset />}
          />
          {/* <Route
            path="/reset-password/:user_id"
            component={isLoggedIn ? <Home /> : <PasswordReset />}
          /> */}
          <Route path="/cartlist" element={<ProductsCartList />} />
          <Route path="/checkout" element={<ProductsCheckout />} />
          <Route path="/orderHistory" element={<ProductsOrderHistory />} />
          <Route path="/wishlist" element={<ProductsWishList />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/pdf" element={<PdfGenerator />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/coupon" element={<CouponList />} />

          {/* <Route path="/oldmap" element={<Gmap />} /> */}
          {/* <Route path="/auto" element={<Autocomplete />} /> */}
          {/* <Route path="/deliveries" exact component={Deliveries} /> */}
          {/* <Route path="/brands" exact component={Brands} /> */}
        </Routes>
        {window.location.pathname.includes("map") == false && <Footer />}
      </Router>
    </ThingsContext.Provider>
  );
}

export default App;

import React from "react";
import samImg from "../assets/images_/products/default_blog_image.png";

const MediaComponent = ({ htmlData }) => {
  const renderEmbeddedContent = () => {
    const tempElem = document.createElement("div");
    tempElem.innerHTML = htmlData;
    console.log("embeded", tempElem);
    const iframeElement = tempElem.querySelector("iframe");
    console.log("iframeElement", iframeElement);
    if (iframeElement) {
      const iframeSrc = iframeElement.getAttribute("src");
      if (iframeSrc) {
        return <div dangerouslySetInnerHTML={{ __html: htmlData }} />;
      }
    }

    const imgElement = tempElem.querySelector("img");
    if (imgElement) {
      const imgSrc = imgElement.getAttribute("src");
      if (imgSrc) {
        return <img src={imgSrc} className="car-image_blogs" alt="Image" />;
      }
    }

    const videoElement = tempElem.querySelector("video");
    if (videoElement) {
      return (
        <video controls>
          <source src={videoElement.src} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      );
    }
    // if (!videoElement && !imgElement && !iframeElement) {
    //   const renderHTMLString = (htmlString) => {
    //     const div = document.createElement("div");
    //     div.innerHTML = htmlString;

    //     const elements = [];
    //     for (let child of div.childNodes) {
    //       const nodeType = child.nodeType;
    //       if (nodeType === Node.TEXT_NODE) {
    //         elements.push(child.textContent);
    //       } else if (nodeType === Node.ELEMENT_NODE) {
    //         const tagName = child.tagName.toLowerCase();
    //         const props = {};
    //         for (let { name, value } of child.attributes) {
    //           props[name] = value;
    //         }
    //         const children = renderHTMLString(child.innerHTML);
    //         elements.push(React.createElement(tagName, props, ...children));
    //       }
    //     }

    //     return elements;
    //   };

    //   const jsxElements = renderHTMLString(htmlData);

    //   return <div>{jsxElements}</div>;
    // }
    return <img src={samImg} className="car-image_blogs" alt="Image" />;
  };

  return renderEmbeddedContent();
};

export default MediaComponent;

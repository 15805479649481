import React, { Map, useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  Marker,
  InfoWindowF,
  InfoWindow,
  withG,
  useJsApiLoader,
  Polygon,
} from "@react-google-maps/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faShop,
  faMobileScreenButton,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { ReactSession } from "react-client-session";
import axios from "axios";
import { DispensaryAPI } from "./commonComp";
import { useLayoutEffect } from "react";

const containerStyle = {
  width: "100%",
  height: "100%",
};

// const center = {
//   lat: -3.745,
//   lng: -38.523,
// };
let newCenter;

function GoogleMapComponent() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GMAP_APIKEY,
    libraries: ["places"],
  });

  let dispensariesList;

  //   if(window.location.href.includes('localhost')){
  //     dispensariesList = require('./dispensariesList.json').result;
  // }else{
  dispensariesList = []; // require('./dispensariesList.json').result;
  // }

  let center = {
    lat: ReactSession.get("userlocationLat")
      ? ReactSession.get("userlocationLat")
      : 0,
    lng: ReactSession.get("userlocationLng")
      ? ReactSession.get("userlocationLng")
      : 0,
  };
  // if (
  //   !ReactSession.get("userCurrentLocationFlag") ||
  //   ReactSession.get("searchLocationBetween")
  // ) {
  //   center = null;
  // }
  const [clicks, setClicks] = React.useState([]);
  const [zoom, setZoom] = React.useState(10); // initial zoom
  const [staticLocations, setStaticLocations] = useState([]);
  const [infoWindowID, setInfoWindowID] = useState(null);
  const [selectedInfoWindowID, setSelectedInfoWindowID] = useState(null);
  const [autoc, setAutoC] = React.useState();
  const [mapView, setMapView] = React.useState(null);
  const [dynamicList, setDynamicList] = React.useState(dispensariesList);
  const [selectedItem, setSelectedItem] = useState([]);
  const [txt, setTxt] = useState(process.env.REACT_APP_STATUS_LDN_TEXT);

  //changes dec12
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const [path, setPath] = useState([
    { lat: 52.52549080781076, lng: 13.398118538856465 },
    { lat: 52.48578559055679, lng: 13.36653284549709 },
    { lat: 52.48871246221608, lng: 13.44618372440334 },
  ]);

  // Call setPath with new edited path
  const onPolyEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  const onPolyLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onPolyEdit),
        path.addListener("insert_at", onPolyEdit),
        path.addListener("remove_at", onPolyEdit)
      );
    },
    [onPolyEdit]
  );

  // Clean up refs
  const onPolyUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  //changes dec12

  React.useEffect(() => {
    if (dispensariesList.length == 0) {
      axios
        .get(
          DispensaryAPI(
            ReactSession.get("userCurrentLocationFlag"),
            ReactSession.get("userlocationLat"),
            ReactSession.get("userlocationLng"),
            ReactSession.get("userlocationDetails").city,
            ReactSession.get("userlocationDetails").state,
            ReactSession.get("userlocationDetails").country
          )
        )
        .then((response) => {
          if (response.data.status == "success") {
            setDynamicList(response.data.result);
            setTxt(process.env.REACT_APP_STATUS_SCS_TEXT);
          } else {
            setTxt(process.env.REACT_APP_STATUS_ND_TEXT);
          }
        })
        .catch((error) => {
          setTxt(process.env.REACT_APP_STATUS_FLR_TEXT);

          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
  }, []);
  React.useEffect(() => {
    setTimeout(() => {
      if (ReactSession.get("userlocation") === "San Jose, CA, USA") {
        setZoom(11);
      }
    }, 1000);
    if (mapView) {
      newCenter = mapView.getBounds();

      if (newCenter != undefined) {
        let ne = newCenter.getNorthEast();
        let sw = newCenter.getSouthWest();
        newCenter = {
          south: sw.lat(),
          west: sw.lng(),
          north: ne.lat(),
          east: ne.lng(),
        };

        console.log("newCenter.getSouthWest()", newCenter);
        ReactSession.set("userlocationVP", newCenter);
      }
    }
    if (ReactSession.get("userlocation") === "Hayward, CA, USA") {
      setTimeout(() => {
        setZoom(11);
      }, 3000);
    }
  }, []);
  const onLoad = React.useCallback(function callback(map) {
    console.log({ map });

    if (
      !ReactSession.get("userCurrentLocationFlag")
      //  && !ReactSession.get("searchLocationBetween")
    ) {
      map.fitBounds(ReactSession.get("userlocationVP"));
      console.log(ReactSession.get("userlocationVP"));
    }
    console.log("defaultMap zoom level", map.getZoom());
    console.log("defaultMap zoom level132344", map.zoom);
    var setlevel = map.getZoom();

    setZoom(map.zoom);

    map.setZoom(setlevel);
    if (ReactSession.get("searchLocationBetween")) {
      map.fitBounds(ReactSession.get("searchLocationBetweenVP"));
      console.log(ReactSession.get("searchLocationBetweenVP"));
    }

    // // Create the DIV to hold the control.
    const centerControlDiv = document.createElement("div");
    // // Create the control.
    const centerControl = createCenterControl(map);
    // // Append the control to the DIV.
    centerControlDiv.appendChild(centerControl);

    map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(
      centerControlDiv
    );
    setMapView(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMapView(null);
  }, []);

  const positionVar = React.useCallback(function callback(map) {
    return map.ControlPosition.TOP_CENTER; // ControlPosition.TOP_CENTER
  }, []);
  function callback(place, status) {
    // if (status === new window.google.maps.places.PlacesServiceStatus.OK) {
    return place;
    // }
  }
  const handleCenterChanged = () => {
    if (mapView) {
      newCenter = mapView.getBounds();

      if (newCenter != undefined) {
        let ne = newCenter.getNorthEast();
        let sw = newCenter.getSouthWest();
        newCenter = {
          south: sw.lat(),
          west: sw.lng(),
          north: ne.lat(),
          east: ne.lng(),
        };

        console.log("newCenter.getSouthWest()", newCenter);
        ReactSession.set("userlocationVP", newCenter);
      }
    }
  };

  function loadAuto(autocomplete) {
    setAutoC(autocomplete);
  }

  const navigate = useNavigate();

  function createCenterControl(map) {
    const controlButton = document.createElement("button");

    // Set CSS for the control.
    controlButton.style.backgroundColor = "#fff";
    controlButton.style.border = "2px solid #fff";
    controlButton.style.borderRadius = "3px";
    controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlButton.style.color = "rgb(25,25,25)";
    controlButton.style.cursor = "pointer";
    controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
    controlButton.style.fontSize = "16px";
    controlButton.style.lineHeight = "38px";
    controlButton.style.margin = "60px 0 22px";
    controlButton.style.padding = "0 5px";
    controlButton.style.textAlign = "center";

    controlButton.textContent = "SEARCH THIS AREA";
    controlButton.title = "Click to search for dispensaries in this Area";
    controlButton.type = "button";
    controlButton.id = "searchBtn";
    controlButton.zoom = "12";

    controlButton.addEventListener("click", () => {
      controlButton.textContent = "SEARCHING...";
      // controlButton.style.display='none';
      ReactSession.set("searchLocationBetween", true);
      ReactSession.set("searchLocationBetweenVP", newCenter);
      ReactSession.set("userlocationVP", newCenter);
      // window.location.reload(false);
      axios
        .get(
          DispensaryAPI(
            ReactSession.get("userCurrentLocationFlag"),
            ReactSession.get("userlocationLat"),
            ReactSession.get("userlocationLng"),
            ReactSession.get("userlocationDetails").city,
            ReactSession.get("userlocationDetails").state,
            ReactSession.get("userlocationDetails").country
          )
        )
        .then((response) => {
          controlButton.textContent = "SEARCH THIS AREA";
          if (response.data.status == "success") {
            setDynamicList(response.data.result);
            setTxt(process.env.REACT_APP_STATUS_SCS_TEXT);
          }
          if (response.data.status == "no_record") {
            setDynamicList([]);
            setTxt(process.env.REACT_APP_STATUS_ND_TEXT);
          }
        })
        .catch((error) => {
          setTxt(process.env.REACT_APP_STATUS_FLR_TEXT);

          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    });
    return controlButton;
  }

  let NewMapLoader;

  let listresult = [];
  let i = 0;
  dynamicList.map((innerList) => {
    console.log({ innerList });
    innerList.map((row) => {
      listresult[i] = row;
      i++;
    });
  });

  let markersIcon = [
    "icon-Dispensaries",
    "icon-Deliveries",
    "icon-Deliveries",
    "icon-Cart",
    "storefront",
  ];
  function isImageExtension(extension) {
    var imageExtensions = [".png", ".jpg", ".jpeg", ".jfif", ".jpe"];
    return imageExtensions.includes(extension.toLowerCase());
  }
  
  // const imagechanges = (data) => {
  //   // var split_url = data.split("?");
  //   // var object_key = split_url[1].split("=")[1];
  
  //   // return "https://d3o4at03oqlv0t.cloudfront.net/" + object_key;
  //   var split_url = data.split("?");
  //   var object_key = split_url[1].split("=")[1];
  //   var converted_url =
  //     "https://d3o4at03oqlv0t.cloudfront.net/" +
  //     object_key.replace(".jpg", ".webp");
  //   return converted_url;
  // };
  
  const imagechanges = (data) => {
    var split_url = data.split("?");
    var object_key = split_url[1].split("=")[1];
  
    var fileExtension = object_key.substring(object_key.lastIndexOf(".")); // Extract the file extension
    
    var isImage = isImageExtension(fileExtension);
  
    if (isImage) {
      var converted_url =
        "https://d3o4at03oqlv0t.cloudfront.net/" +
        object_key.replace(fileExtension, ".webp");
      return converted_url;
    } else {
      // Handle the case when the file extension is not supported
      return "Invalid file extension";
    }
  };
  return (
    <div className="container pt-0 mw-100 pb-0 map-container">
      <div className="row d-flex mapBox">
        <div className="col-12 col-md-9 col-lg-9 gmap map-dispensaries overflow-hidden">
          {/* {window.google === undefined ?  */}
          {console.log("center12", center)}
          {isLoaded ? (
            <GoogleMap
              defaultOptions={{ disableDefaultUI: true }}
              onLoad={onLoad}
              onCenterChanged={handleCenterChanged}
              onUnmount={onUnmount}
              mapContainerStyle={containerStyle}
              center={center}
              zoom={zoom ? zoom : ""}
            >
              <Polygon
                // Make the Polygon editable / draggable
                editable
                draggable
                path={path}
                // Event used when manipulating and adding points
                onMouseUp={onPolyEdit}
                // Event used when dragging the whole Polygon
                onDragEnd={onPolyEdit}
                onLoad={onPolyLoad}
                onUnmount={onPolyUnmount}
              />
              {listresult.map((row, i) => {
                let iconMarker;
                if (row.dispensaries_result.has_curbside_pickup == true) {
                  iconMarker = markersIcon[1];
                } else {
                  if (
                    row.dispensaries_result.online_ordering.enabled == false
                  ) {
                    iconMarker = markersIcon[4];
                  } else {
                    if (row.dispensaries_result.has_curbside_pickup == false) {
                      if (
                        row.dispensaries_result.online_ordering
                          .enabled_for_delivery == false
                      ) {
                        iconMarker = markersIcon[4];
                      } else {
                        iconMarker = markersIcon[2];
                      }
                    } else {
                      iconMarker = markersIcon[1];
                    }
                  }
                }

                const index = i + 1;

                return (
                  <Marker
                    icon={require("../assets/marker-icons/" +
                      iconMarker +
                      ".png")}
                    position={{
                      lat: parseFloat(row.latitude),
                      lng: parseFloat(row.longitude),
                    }}
                    onClick={() => {
                      setInfoWindowID(index);
                    }}
                    //   onMouseOver={() => {
                    //   setInfoWindowID(index);
                    // }}
                    // onMouseOut={() => {
                    //     setInfoWindowID(null);
                    //   }}
                  >
                    {infoWindowID === index && (
                      <InfoWindowF onCloseClick={() => setInfoWindowID(null)}>
                        <div
                          className="brand-card border-0 mb-0 pb-0"
                          onClick={() =>
                            navigate(
                              "/dispensaries/detail?data=" +
                                btoa(JSON.stringify(row._id))
                            )
                          }
                        >
                          <div className="image-col col-12 col-lg-4 mb-0">
                            <img
                              alt={row.name}
                              src={imagechanges(row.logo)}
                              className="img-fluid"
                            />
                          </div>
                          <div className="col-12 col-lg-7">
                            <h6>{row.name}</h6>
                            <div className="type-of-services">
                              Medicinal - Recreational - CBD
                            </div>
                            <div className="menu-breakdown">
                              <span>
                                {row.city.city_name}, {row.state.state_name},{" "}
                                {row.zip_code}
                              </span>
                              <div className="menu-category justify-category">
                                {row.phone != "" && (
                                  <div className="item">
                                    <FontAwesomeIcon
                                      title={row.phone}
                                      icon={faMobileScreenButton}
                                    ></FontAwesomeIcon>
                                  </div>
                                )}

                                {row.hasDispensary === true && (
                                  <div className="item">
                                    <FontAwesomeIcon
                                      icon={faShop}
                                    ></FontAwesomeIcon>
                                  </div>
                                )}

                                {row.hasDelivery === true && (
                                  <div className="item">
                                    <FontAwesomeIcon
                                      icon={faTruck}
                                    ></FontAwesomeIcon>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </InfoWindowF>
                    )}
                  </Marker>
                );
              })}
              {selectedItem.length != 0 && (
                <Marker
                  zIndex={999}
                  //   icon={require("../assets/images/empty-favicon.png")}
                  position={{
                    lat: selectedItem.latitude,
                    lng: selectedItem.longitude,
                  }}
                  onCloseClick={() => setSelectedItem([])}
                  onClick={() => {
                    setSelectedInfoWindowID(selectedItem._id);
                  }}
                  //   onCloseClick={() => setSelectedItem([])}
                >
                  {selectedInfoWindowID === selectedItem._id && (
                    <InfoWindowF
                      onCloseClick={() => setSelectedInfoWindowID(null)}
                    >
                      <div
                        className="brand-card border-0 mb-0 pb-0"
                        onClick={() =>
                          navigate(
                            "/dispensaries/detail?data=" +
                              btoa(JSON.stringify(selectedItem._id))
                          )
                        }
                      >
                        <div className="image-col col-12 col-lg-4 mb-0">
                          <img
                            alt={selectedItem.name}
                            src={imagechanges(selectedItem.logo)}
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-12 col-lg-7">
                          <h6>{selectedItem.name}</h6>
                          <div className="type-of-services">
                            Medicinal - Recreational - CBD
                          </div>
                          <div className="menu-breakdown">
                            <span>
                              {selectedItem.city.city_name},{" "}
                              {selectedItem.state.state_name},{" "}
                              {selectedItem.zip_code}
                            </span>
                            <div className="menu-category justify-category">
                              {selectedItem.phone != "" && (
                                <div className="item">
                                  <FontAwesomeIcon
                                    title={selectedItem.phone}
                                    icon={faMobileScreenButton}
                                  ></FontAwesomeIcon>
                                </div>
                              )}

                              {selectedItem.hasDispensary === true && (
                                <div className="item">
                                  <FontAwesomeIcon
                                    icon={faShop}
                                  ></FontAwesomeIcon>
                                </div>
                              )}

                              {selectedItem.hasDelivery === true && (
                                <div className="item">
                                  <FontAwesomeIcon
                                    icon={faTruck}
                                  ></FontAwesomeIcon>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </InfoWindowF>
                  )}
                </Marker>
              )}
              ;{/* Child components, such as markers, info windows, etc. */}
              <></>
            </GoogleMap>
          ) : (
            <></>
          )}
        </div>
        <div className="col-12  col-md-3 col-lg-3 map-dispensaries">
          {listresult.length == 0 ? (
            <span className="statusTxt">{txt}</span>
          ) : (
            listresult.map((row) => {
              return (
                <div
                  className="brand-card"
                  onMouseOver={() => setSelectedItem(row)}
                  // onMouseOut={() =>
                  //     setSelectedItem([]);
                  //     setSelectedInfoWindowID(null);
                  // }
                  onClick={() => setSelectedInfoWindowID(row._id)}
                >
                  <div className="image-col col-12 col-lg-4 mb-0">
                    <img
                      alt={row.name}
                      src={imagechanges(row.logo)}
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-12 col-lg-7">
                    <h6>{row.name}</h6>
                    <div className="type-of-services">
                      Medicinal - Recreational - CBD
                    </div>
                    <div className="menu-breakdown">
                      <span>
                        {row.city.city_name}, {row.state.state_name},{" "}
                        {row.zip_code}
                      </span>
                      <div className="menu-category justify-category">
                        {row.phone != "" && (
                          <div className="item">
                            <FontAwesomeIcon
                              title={row.phone}
                              icon={faMobileScreenButton}
                            ></FontAwesomeIcon>
                          </div>
                        )}

                        {row.hasDispensary === true && (
                          <div className="item">
                            <FontAwesomeIcon icon={faShop}></FontAwesomeIcon>
                          </div>
                        )}

                        {row.hasDelivery === true && (
                          <div className="item">
                            <FontAwesomeIcon icon={faTruck}></FontAwesomeIcon>
                          </div>
                        )}
                        <div className="item view-detail">
                          <FontAwesomeIcon
                            icon={faEye}
                            onClick={() =>
                              navigate(
                                "/dispensaries/detail?data=" +
                                  btoa(JSON.stringify(row._id))
                              )
                            }
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(GoogleMapComponent);

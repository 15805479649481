import React from "react";
import logo from "../assets/images/hella-lit-logo.png";

function Footer() {
  return (
    <footer className="footer">
      <div className="tp-footer-area tp-footer-style-2 tp-footer-style-primary tp-footer-style-6" data-bg-color="#ffffff">
        <div className="tp-footer-bottom">
          <div className="container-fluid">
            <div className="tp-footer-bottom-wrapper">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="tp-footer-copyright">
                    <div className="d-flex flex-wrap flex-row justify-content-center">
                      <div className="footer_img w-100 d-flex justify-content-center"><img src={logo} border={0} /></div>
                      <div><p>Copyright © 2023 HellaLit</p></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </footer>
  );
}

export default Footer;

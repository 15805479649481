import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import bannerImg from "../assets/images_/p5.png";
import samImg from "../assets/images_/products/default_blog_image.png";
import Carousel from "react-multi-carousel";
// import Carousel from "better-react-carousel";
import "react-multi-carousel/lib/styles.css";
import "./additionalCSS.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";

function CouponList() {
  console.log("console");
  const navigate = useNavigate();

  const [coupons, setCoupons] = useState([]);

  function getCoupons() {
    axios
      .get(process.env.REACT_APP_API_URL + "coupon/list")
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setCoupons(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCoupons();
  }, []);

  return (
    <>
      <main>
        {/* coupon area start */}
        <div className="tp-coupon-area pt-100 pb-120">
          <div className="container bg-white p-3">
            <div className="row">
              {coupons.map((row) => {
                return (
                  <div className="col-xl-6">
                    <div className="tp-coupon-item mb-30 p-relative d-md-flex justify-content-between align-items-center">
                      <span className="tp-coupon-border" />
                      <div className="tp-coupon-item-left d-sm-flex align-items-center">
                        <div className="tp-coupon-thumb">
                          <a href="#">
                            <img
                              alt="logo"
                              src="assets/img/product_sample1.png"
                            />
                          </a>
                        </div>
                        <div className="tp-coupon-content">
                          <h3 className="tp-coupon-title">SUMMER Vacation</h3>
                          <p className="tp-coupon-offer mb-15">
                            <span>{row.percent}%</span>Off
                          </p>
                          <div
                            className="tp-coupon-countdown"
                            data-countdown
                            data-date="Sep 30 2024 20:20:22"
                          >
                            <CountdownTimer
                              key={row._id}
                              expirationDate={new Date(row.expired_date)}
                            />
                            {/* <div className="tp-coupon-countdown-inner">
                              <ul>
                                <li>
                                  <span data-days>83</span> Day
                                </li>
                                <li>
                                  <span data-hours>16</span> Hrs
                                </li>
                                <li>
                                  <span data-minutes>20</span> Min
                                </li>
                                <li>
                                  <span data-seconds>39</span> Sec
                                </li>
                              </ul>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="tp-coupon-item-right pl-20">
                        <div className="tp-coupon-status mb-10 d-flex align-items-center">
                          <h4>
                            Coupon <span className="active">Active</span>
                          </h4>
                          <div className="tp-coupon-info-details">
                            <span>
                              <i className="fa-regular fa-circle-question" />
                            </span>
                            <div className="tp-coupon-info-tooltip transition-3">
                              <p>
                                *This coupon code will apply on{" "}
                                <span>Grocery type products</span> and when you
                                shopping more than <span>$500</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="tp-coupon-date">
                          <button>
                            <span>{row.promotion_code}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* coupon area end */}
      </main>
    </>
  );
}
function CountdownTimer({ expirationDate }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = new Date();
      const timeRemaining = expirationDate - currentDate;

      const daysRemaining = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hoursRemaining = Math.floor(
        (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutesRemaining = Math.floor(
        (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
      );
      const secondsRemaining = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      setDays(daysRemaining);
      setHours(hoursRemaining);
      setMinutes(minutesRemaining);
      setSeconds(secondsRemaining);
    }, 1000);

    return () => clearInterval(interval);
  }, [expirationDate]);

  return (
    <div className="tp-coupon-countdown-inner">
      <ul>
        <li>
          <span data-days>{days}</span> Day
        </li>
        <li>
          <span data-hours>{hours}</span> Hrs
        </li>
        <li>
          <span data-minutes>{minutes}</span> Min
        </li>
        <li>
          <span data-seconds>{seconds}</span> Sec
        </li>
      </ul>
    </div>
  );
}
export { CouponList };

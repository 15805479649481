import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// import Carousel from "better-react-carousel";
import "react-multi-carousel/lib/styles.css";
import "./additionalCSS.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// function HeadBanner(props) {
//   return (
//     <div className="banner_mrg_top">
//       <img width="100%" src={props.imgUrl} className="img-fluid" />
//       <div className="m-4">
//         <h2 className="text-center pt-4 pb-3">{props.headerContent}</h2>
//       </div>
//     </div>
//   );
// }

function SignUp() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [first_name, setFirstname] = useState("");

  const handleChangeemail = (e) => {
    setEmail(e.target.value);
    console.log(e.target.value);
  };
  const handleChangepwd = (e) => {
    setPassword(e.target.value);
    console.log(e.target.value);
  };
  const handleChangefirstname = (e) => {
    setFirstname(e.target.value);
    console.log(e.target.value);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSignup = (e) => {
    console.log("Ddd");
    if (first_name.trim() === "") {
      toast.error("First Name is required");
      return;
    }

    // Validate email field
    if (email.trim() === "") {
      toast.error("Email is required");
      return;
    } else if (!validateEmail(email)) {
      toast.error("Invalid email address");
      return;
    }

    // Validate password field
    if (password.trim() === "") {
      toast.error("Password is required");
      return;
    } else if (password.length < 6) {
      toast.error("Password should be at least 6 characters long");
      return;
    }

    console.log(e.target.files);
    const file_detailsd = new FormData();
    file_detailsd.append("email_address", email);
    file_detailsd.append("password", password);
    file_detailsd.append("ip_address", "157.51.198.251");
    file_detailsd.append("first_name", first_name);
    axios
      .post(process.env.REACT_APP_API_URL + "user/signup", file_detailsd)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == "success") {
          window.localStorage.setItem("email_address", email);
          window.localStorage.setItem("user_id", res.data.result.user_id);
          console.log("/home");
          toast.success("User has been created successfully.");
          navigate("/home");
          const file_detailsd = new FormData();

          file_detailsd.append("user_id", res.data.result.user_id);

          file_detailsd.append(
            "random_string",
            window.localStorage.getItem("randomString")
          );
          axios
            .post(
              process.env.REACT_APP_API_URL + "products/update/cart",
              file_detailsd
            )
            .then((res) => {
              console.log(res);
              console.log(res.data.message);
              toast.success("Add to cart has been updated successfully.");
              if (res.data.status === "success") {
                window.localStorage.setItem("randomString", "");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <main>
        {/* login area start */}
        <section className="tp-login-area pt-100 pb-140 p-relative fix">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="tp-login-wrapper">
                  <div className="tp-login-top text-center mb-30">
                    <h3 className="tp-login-title">Sign Up</h3>
                    <p>
                      Already have an account?{" "}
                      <span>
                        <a href="/login" className="f_yellow">
                          Sign In
                        </a>
                      </span>
                    </p>
                  </div>
                  <div className="tp-login-option">
                    <div className="tp-login-input-wrapper">
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            id="name"
                            type="text"
                            onChange={handleChangefirstname}
                          />
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="name">Your Name</label>
                        </div>
                      </div>
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            id="email"
                            type="email"
                            placeholder="example@mail.com"
                            onChange={handleChangeemail}
                          />
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="email">Your Email</label>
                        </div>
                      </div>
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            id="tp_password"
                            type="password"
                            placeholder="Min. 6 character"
                            onChange={handleChangepwd}
                          />
                        </div>
                        {/* <div
                          className="tp-login-input-eye"
                          id="password-show-toggle"
                        >
                          <span id="open-eye" className="open-eye">
                            <i className="fa-solid fa-eye" />
                          </span>
                          <span id="close-eye" className="open-close">
                            <i className="fa-solid fa-eye-slash" />
                          </span>
                        </div> */}
                        <div className="tp-login-input-title">
                          <label htmlFor="tp_password">Password</label>
                        </div>
                      </div>
                    </div>
                    <div className="tp-login-suggetions d-sm-flex align-items-center justify-content-between mb-20">
                      <div className="tp-login-remeber">
                        <input id="remeber" type="checkbox" />
                        <label htmlFor="remeber">
                          I accept the{" "}
                          <a href="#" className="f_yellow">
                            Terms of the Service
                          </a>{" "}
                          &amp;{" "}
                          <a href="#" className="f_yellow">
                            Privacy Policy
                          </a>
                          .
                        </label>
                      </div>
                    </div>
                    <div className="tp-login-bottom" onClick={handleSignup}>
                      <a href="#" className="tp-login-btn w-100">
                        Sign Up
                      </a>
                    </div>
                    {/* <div className="tp-login-mail text-center mt-40 mb-40">
                      <p>or </p>
                    </div>
                    <div className="tp-login-social mb-10 d-flex flex-wrap align-items-center justify-content-center">
                      <div className="tp-login-option-item">
                        <a href="#" className="px-4">
                          <img
                            src="assets/img/google.svg"
                            alt
                            className="mr-5"
                          />
                          Google
                        </a>
                      </div>
                      <div className="tp-login-option-item">
                        <a href="#" className="px-4">
                          <i className="fa-brands fa-facebook mr-5 fb_color f_24" />{" "}
                          Facebook
                        </a>
                      </div>
                      <div className="tp-login-option-item">
                        <a href="#" className="px-4">
                          <i className="fa-brands fa-apple mr-5 apple_color f_24" />{" "}
                          Apple
                        </a>
                      </div>
                    </div> */}
                  </div>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* login area end */}
      </main>
    </>
  );
}

export { SignUp };

import React, { useContext } from "react";
import { ThingsContext } from "./thingsContext";
import { ReactSession } from "react-client-session";
import bannerImg from "../assets/images_/hellalit_dispensary_logo.jpg";
import { fontawesome } from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTint, faPenSquare } from "@fortawesome/fontawesome-free-solid";

import {
  faCookie,
  faCannabis,
  faJarWheat,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DispensaryCarsoulList, DispensaryAPI, ShowDetail } from "./commonComp";
import ReactDOM from "react-dom";
import axios, { isCancel, AxiosError } from "axios";
import "./companyDetail.css";
import "./additionalCSS.css";
function isImageExtension(extension) {
  var imageExtensions = [".png", ".jpg", ".jpeg", ".jfif", ".jpe"];
  return imageExtensions.includes(extension.toLowerCase());
}

// const imagechanges = (data) => {
//   // var split_url = data.split("?");
//   // var object_key = split_url[1].split("=")[1];

//   // return "https://d3o4at03oqlv0t.cloudfront.net/" + object_key;
//   var split_url = data.split("?");
//   var object_key = split_url[1].split("=")[1];
//   var converted_url =
//     "https://d3o4at03oqlv0t.cloudfront.net/" +
//     object_key.replace(".jpg", ".webp");
//   return converted_url;
// };

const imagechanges = (data) => {
  var split_url = data.split("?");
  var object_key = split_url[1].split("=")[1];

  var fileExtension = object_key.substring(object_key.lastIndexOf(".")); // Extract the file extension
  
  var isImage = isImageExtension(fileExtension);

  if (isImage) {
    var converted_url =
      "https://d3o4at03oqlv0t.cloudfront.net/" +
      object_key.replace(fileExtension, ".webp");
    return converted_url;
  } else {
    // Handle the case when the file extension is not supported
    return "Invalid file extension";
  }
};
function Dispenssaries() {
  let dispensariesList;
  if (window.location.href.includes("localhost")) {
    dispensariesList = require("./dispensariesList.json").result;
  } else {
    dispensariesList = []; // require('./dispensariesList.json').result;
  }

  const [dynamicCarsoulList, setDynamicCarsoulList] = React.useState(
    <DispensaryCarsoulList
      sts={process.env.REACT_APP_STATUS_LDN}
      list={dispensariesList}
    />
  );
  const [dynamicDetailList, setDynamicDetailList] = React.useState(
    <DetailList list={dispensariesList} />
  );

  React.useEffect(() => {
    if (dispensariesList.length == 0) {
      axios
        .get(
          DispensaryAPI(
            ReactSession.get("userCurrentLocationFlag"),
            ReactSession.get("userlocationLat"),
            ReactSession.get("userlocationLng"),
            ReactSession.get("userlocationDetails").city,
            ReactSession.get("userlocationDetails").state,
            ReactSession.get("userlocationDetails").country
          )
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            setDynamicCarsoulList(
              <DispensaryCarsoulList
                sts={process.env.REACT_APP_STATUS_SCS}
                list={response.data.result}
              />
            );
            setDynamicDetailList(<DetailList list={response.data.result} />);
          } else {
            setDynamicCarsoulList(
              <DispensaryCarsoulList
                sts={process.env.REACT_APP_STATUS_ND}
                list={[]}
              />
            );
          }
        })
        .catch((error) => {
          setDynamicCarsoulList(
            <DispensaryCarsoulList
              sts={process.env.REACT_APP_STATUS_FLR}
              list={[]}
            />
          );
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
  }, []);

  return (
    <>
      <HeadBanner />
      <div className="containe r">
        {dynamicCarsoulList}
        {dynamicDetailList}
      </div>
    </>
  );
}

class HeadBanner extends React.Component {
  render() {
    return (
      <div className="banner_mrg_top">
        <img className="img-fluid" width="100%" src={bannerImg} />
        <h2 className="text-center pt-4 pb-3">Featured</h2>
      </div>
    );
  }
}

function DetailList({ list }) {
  const things = ReactSession.get("userlocation");
  const navigate = useNavigate();
  let listresult = [];
  let i = 0;
  list.map((innerList) => {
    innerList.map((row) => {
      listresult[i] = row;
      i++;
    });
  });
  list = listresult;
  // const contextType = userLocationContext;
  if (list.length > 0) {
    return (
      <div className="container pt-0">
        <div className="list-header">
          <div>
            <span>
              Showing results for <span className="black-text">{things}</span>
            </span>
          </div>
        </div>
        <div className="row dispensaries-list">
          <div className="col-12">
            {list.map((row) => {
              return (
                <div
                  className="brand-card px-2"
                  onClick={() =>
                    navigate(
                      "/dispensaries/detail?data=" +
                        btoa(JSON.stringify(row._id))
                    )
                  }
                >
                  <div className="row col-lg-5">
                    <div className="image-col col-lg-5 col-md-6 col-12">
                      <img
                        alt={row.name}
                        src={imagechanges(row.logo)}
                        className="img-fluid mt-2"
                      />
                    </div>
                    <div className="company-name col-lg-7 col-md-6 col-12">
                      <h5>{row.name}</h5>
                      <div className="type-of-services">
                        Medicinal - Recreational - CBD
                      </div>
                      <div className="pt-3">
                        {row.city.city_name + ", " + row.state.state_name}
                      </div>
                    </div>
                  </div>
                  <div className="menu-breakdown">
                    <h5>
                      Menu Breakdown{" "}
                      <span className="items-qty d-none">(0 items)</span>
                    </h5>
                    <div className="menu-category">
                      <div className="item">
                        <FontAwesomeIcon icon={faTint}></FontAwesomeIcon>
                        <h6>Concentrates</h6>
                        <p>(0 items)</p>
                      </div>
                      <div className="item">
                        <FontAwesomeIcon icon={faCookie}></FontAwesomeIcon>
                        <h6>Edibles</h6>
                        <p>(0 items)</p>
                      </div>
                      <div className="item">
                        <FontAwesomeIcon icon={faCannabis}></FontAwesomeIcon>
                        <h6>Flower</h6>
                        <p>(0 items)</p>
                      </div>
                      <div className="item">
                        <FontAwesomeIcon icon={faPenSquare}></FontAwesomeIcon>
                        <h6>Vape pens</h6>
                        <p>(0 items)</p>
                      </div>
                      <div className="item">
                        <FontAwesomeIcon icon={faJarWheat}></FontAwesomeIcon>
                        <h6>Other</h6>
                        <p>(0 items)</p>
                      </div>
                    </div>
                  </div>
                  <div className="menu-genetics">
                    <h5>Menu genetics</h5>
                    <ul>
                      <li>
                        Hybrid <span className="item-qty">(0 items)</span>
                      </li>
                      <li>
                        Indica <span className="item-qty">(0 items)</span>
                      </li>
                      <li>
                        Sativa <span className="item-qty">(0 items)</span>
                      </li>
                    </ul>
                  </div>
                </div>
                //             <div className="brand-card" onClick={()=> navigate('/dispensaries/detail?data='+btoa(JSON.stringify(row)))}>
                //     <div className="image-col">
                //       <img alt={row.name} src={row.logo} className="img-fluid" />
                //     </div>
                //     <div className="company-name">
                //       <h5>{row.name}</h5>
                //       <div className="type-of-services">Medicinal - Recreational - CBD</div>
                //     </div>
                //     <div className="menu-breakdown">
                //       <h5>Menu Breakdown <span className="items-qty">(0 items)</span></h5>
                //       <div className="menu-category">
                //         <div className="item">
                //           <FontAwesomeIcon icon={faTint}></FontAwesomeIcon>
                //           <h6>Concentrates</h6>
                //           <p>(0 items)</p>
                //         </div>
                //         <div className="item">
                //           <FontAwesomeIcon icon={faCookie}></FontAwesomeIcon>
                //           <h6>Edibles</h6>
                //           <p>(0 items)</p>
                //         </div>
                //         <div className="item">
                //           <FontAwesomeIcon icon={faCannabis}></FontAwesomeIcon>
                //           <h6>Flower</h6>
                //           <p>(0 items)</p>
                //         </div>
                //         <div className="item">
                //           <FontAwesomeIcon icon={faPenSquare}></FontAwesomeIcon>
                //           <h6>Vape pens</h6>
                //           <p>(0 items)</p>
                //         </div>
                //         <div className="item">
                //           <FontAwesomeIcon icon={faJarWheat}></FontAwesomeIcon>
                //           <h6>Other</h6>
                //           <p>(0 items)</p>
                //         </div>
                //       </div>
                //     </div>
                //     <div className="menu-genetics">
                //       <h5>Menu genetics</h5>
                //       <ul>
                //         <li>Hybrid <span className="item-qty">(0 items)</span></li>
                //         <li>Indica <span className="item-qty">(0 items)</span></li>
                //         <li>Sativa <span className="item-qty">(0 items)</span></li>
                //       </ul>
                //     </div>
                // </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

function DispensariesDetail(props) {
  let id = JSON.parse(
    atob(new URLSearchParams(window.location.search).get("data"))
  );
  const [detail, setDetail] = React.useState([]);
  const [iframestatus, setIframestatus] = React.useState(2);
  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "dispensaries/info?id=" + id)
      .then((response) => {
        if (response.data.status == "success") {
          if (response.data.result.original_web_url != "") {
            axios
              .post(
                // process.env.REACT_APP_IFRAMECHECK_URL +
                "https://development.hellalit.org/iframecheck.php" +
                  "?url=" +
                  response.data.result.original_web_url,
                {
                  headers: {
                    "Content-Length": 0,
                    "Content-Type": "text/plain",
                  },
                  responseType: "text",
                }
              )
              .then((response1) => {
                console.log("response1", response1);
                setIframestatus(response1.data);
              });
          }
          setDetail(response.data.result);
        }
      });
  }, []);

  return (
    <ShowDetail
      data={detail}
      iframe_status={iframestatus}
      type="dispensaries"
    />
  );
}

export { Dispenssaries, DispensariesDetail };

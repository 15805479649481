import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import bannerImg from "../assets/images_/p5.png";
import samImg from "../assets/images_/products/default_blog_image.png";
import Carousel from "react-multi-carousel";
// import Carousel from "better-react-carousel";
import "react-multi-carousel/lib/styles.css";
import "./additionalCSS.css";
import { ReactSession } from "react-client-session";
import { ShowDetail } from "./commonComp";
import MediaComponent from "./mediafile";
function Blogs() {
  return (
    <>
      <HeadBanner
        headerContent="Blogs in your area"
        imgUrl={bannerImg}
      ></HeadBanner>
      <div className="container p-0 ptrem slider-container">
        <SpotLightBlogs></SpotLightBlogs>
      </div>
    </>
  );
}

function HeadBanner(props) {
  return (
    <div className="banner_mrg_top">
      <img width="100%" src={props.imgUrl} className="img-fluid" />
      <div className="m-4">
        <h2 className="text-center pt-4 pb-3">{props.headerContent}</h2>
      </div>
    </div>
  );
}

function SpotLightBlogs() {
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState([]);

  function getBlogs() {
    axios
      .get(process.env.REACT_APP_API_URL + "blog/list")
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          console.log("suceess");
          setBlogs(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getBlogs();
  }, []);

  useEffect(() => {
    console.log({ blogs });
  }, [blogs]);
  const ReactHtml = (data) => {
    const htmlContent = data;

    const divElement = document.createElement("div");
    divElement.innerHTML = htmlContent;
    const textContent = divElement.innerText;

    return (
      <div>
        {/* {textContent} */}
        {textContent.length > 180
          ? textContent.substring(0, 180) + "... Read More"
          : textContent}
      </div>
    );
  };
  const imagerender = (data) => {
    const elem = document.createElement("div");
    elem.style.display = "none";
    document.body.appendChild(elem);
    elem.innerHTML = data;

    const imgElement = elem.querySelector("img");
    const datares = imgElement ? imgElement.src : samImg;

    document.body.removeChild(elem);

    return datares;
  };
  function isImageExtension(extension) {
    var imageExtensions = [".png", ".jpg", ".jpeg", ".jfif", ".jpe"];
    return imageExtensions.includes(extension.toLowerCase());
  }

  // const imagechanges = (data) => {
  //   // var split_url = data.split("?");
  //   // var object_key = split_url[1].split("=")[1];

  //   // return "https://d3o4at03oqlv0t.cloudfront.net/" + object_key;
  //   var split_url = data.split("?");
  //   var object_key = split_url[1].split("=")[1];
  //   var converted_url =
  //     "https://d3o4at03oqlv0t.cloudfront.net/" +
  //     object_key.replace(".jpg", ".webp");
  //   return converted_url;
  // };

  const imagechanges = (data) => {
    var split_url = data.split("?");
    var object_key = split_url[1].split("=")[1];

    var fileExtension = object_key.substring(object_key.lastIndexOf(".")); // Extract the file extension

    var isImage = isImageExtension(fileExtension);

    if (isImage) {
      var converted_url =
        "https://d3o4at03oqlv0t.cloudfront.net/" +
        object_key.replace(fileExtension, ".webp");
      return converted_url;
    } else {
      // Handle the case when the file extension is not supported
      return "Invalid file extension";
    }
  };
  return (
    <>
      <div class="row ms-2 me-2 mt-3 blogs_row">
        {/* <div class="col-12 col-sm-4 col-md-4 col-lg-3 mb-4"> */}
        {console.log("blogs:", blogs)}
        {blogs.map((item) => {
          return (
            <div
              className="col-12 col-sm-4 col-md-4 col-lg-3 mb-4"
              onClick={() =>
                navigate("/blog/claims?data=" + btoa(JSON.stringify(item._id)))
              }
            >
              <div className="card">
                <div
                  className="hme_blog_img mt-4

"
                >
                  <MediaComponent htmlData={item.description} />
                  {/* <img src="/static/media/mock-product-1.afc8ceb1ae1e260cd36e.png" class="car-image_blogs" alt="/static/media/mock-product-1.afc8ceb1ae1e260cd36e.png"> */}
                </div>
                <div className="card-body hme_card_body">
                  <div className="src__Box-sc-1sbtrzs-0 src__Flex-sc-1sbtrzs-1 ggxWNr hzdsvF">
                    {/* <div className="styles__IconWrap-sc-708jqu-1 hNUGUg pb-1">
                        <span
                          data-testid="card-listing"
                          className="text__Text-sc-fif1uk-0 base-card__Helper-sc-1fhygl1-7 hcsRvV iZFKNF hme_blog_date"
                        >
                          Published on 17 May 2023
                        </span>
                      </div> */}
                  </div>
                  <h5 className="card-title hme_blog_title">{item.title}</h5>

                  <div className="hme_blog_description">
                    {ReactHtml(item.description)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

function Claims(props) {
  let id = JSON.parse(
    atob(new URLSearchParams(window.location.search).get("data"))
  );
  const [detail, setDetail] = React.useState([]);
  const [iframestatus, setIframestatus] = React.useState(2);
  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "blog/list?blog_id=" + id)
      .then((response) => {
        if (response.data.status == "success") {
          setDetail(response.data.result);
        }
      });
  }, []);

  return <ShowDetail data={detail} iframe_status={iframestatus} type="blogs" />;
}
export { Blogs, Claims, SpotLightBlogs };

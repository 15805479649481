import React, { useEffect, useState } from 'react';

function TimeAgo() {
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const targetDate = new Date('2023-09-06T10:24:23.272Z');
    const currentDate = new Date();

    const timeDifference = currentDate - targetDate;

    if (timeDifference < 60000) {
      // Less than 1 minute ago
      setTimeAgo('Just Now');
    } else if (timeDifference < 3600000) {
      // Less than 1 hour ago
      const minutesAgo = Math.floor(timeDifference / 60000);
      setTimeAgo(`${minutesAgo} min Ago`);
    } else if (timeDifference < 86400000) {
      // Less than 1 day ago
      const hoursAgo = Math.floor(timeDifference / 3600000);
      setTimeAgo(`${hoursAgo} hours Ago`);
    } else {
      // More than 1 day ago
      const daysAgo = Math.floor(timeDifference / 86400000);
      setTimeAgo(`${daysAgo} days Ago`);
    }
  }, []);

  return (
    
      <span>{timeAgo}</span>
    
  );
}

export default TimeAgo;
